import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

import Navbar from "../Component/LandingV2/Navbar";
import Footer from "../Component/LandingV2/Footer";

import "./LoginPage.scss";

const ForgotPasswordPage = () => {
  // Form state
  const [email, setEmail] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [messageState, setMessageState] = useState<string | null>(null);
  const [errorState, setErrorState] = useState<string | null>(null);

  const navigate = useNavigate();

  // Validate email format
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Basic email validation
    if (!validateEmail(email)) {
      setErrorState("Please enter a valid email address.");
      return;
    }

    setLoadingState(true);
    setErrorState(null); // Clear previous error
    setMessageState(null); // Clear previous message

    try {
      // Sending POST request to the backend '/forgot-password' endpoint
      const res = await fetch(
        `${
          process.env.REACT_APP_AUTH_URL || "http://localhost:3000"
        }/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }), // Sending email as JSON
        }
      );

      // Parse the response JSON
      const resData = await res.json();

      // Check if the response was successful
      if (res.ok) {
        // Show success message
        setMessageState(
          "Password reset email sent successfully🎉🎉! Check your inbox,spam or junk folder."
        );
        // Optionally, you can navigate the user to another page after success
        // setTimeout(() => {
        //   navigate("/"); // Redirect to home or login page after success
        // }, 2000);
      } else {
        // If backend responds with an error, throw an error
        throw new Error(resData?.message || "Could not send reset email");
      }
    } catch (err: any) {
      // Handle any errors that occurred during the request
      setErrorState(
        err?.message || "Could not send Reset Password Email. Try Again Later."
      );
    } finally {
      // Reset loading state after the request completes
      setLoadingState(false);
    }
  };

  return (
    <>
      <Navbar />

      <div className="login-page">
        <div className="modal-backdrop"></div>
        <div className="login-background-image">
          <img
            src="/assets/images/login/background_1108.png"
            alt="Background"
          />
        </div>

        <div className="login-form-card">
          <h2 className="login-title">Forgot Password</h2>

          <form onSubmit={onSubmit} className="login-form">
            <input
              className="input-field"
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button
              type="submit"
              className="login-button"
              disabled={loadingState}
            >
              {loadingState && (
                <Icon icon="akar-icons:loading" className="animate-spin" />
              )}
              Submit
            </button>
          </form>

          {errorState && <p className="error-message">{errorState}</p>}
          {messageState && <p className="success-message">{messageState}</p>}

          <div className="redirect-register">
            Remembered your password? <Link to="/login">Login</Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ForgotPasswordPage;
