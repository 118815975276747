/**
 * Reducer for managing the index of a single tweet.
 * @param {number} state - The current state representing the index of the tweet.
 * @param {object} action - The action object that contains the type and payload for updating the state.
 * @returns {number} - The updated state representing the index of the tweet.
 */
const TweetsListData = (state = 0, action: any) => {
  switch (action.type) {
    case "GET_SINGLE_TWEETS_INDEX":
      return (state = action.payload);

    default:
      return state;
  }
};

export default TweetsListData;
