/**
 * Action creator for getting a single news item.
 *
 * This function creates an action object for fetching a single news item by index and sends it to the reducer.
 * This function is called in \src\Views\KnowledgeGraph\KnowledgeGraph.tsx
 * This function is called in \src\Views\NewsCount\NewsCount.tsx
 * This function is called in \src\Views\Overview\Overview.tsx
 * This function is called in \src\Views\Prediction\Prediction.tsx
 * This function is called in \src\Views\TechnicalAnalysis\TechnicalAnalysis.tsx
 * This function is called in \src\Views\TodayNews\TodayNews.tsx
 * This function ic called in \src\Views\Tweets\Tweets.tsx
 * 
 * @param {any} data - The data payload associated with the action.
 * @returns {Object} - An action object with type "GET_SINGLE_NEWS_INDEX" and payload.
 */
const NewsList = (data: any) => {
  return {
    type: "GET_SINGLE_NEWS_INDEX",
    payload: data,
  };
};
/**
 * Action creator for identifying news by word cloud.
 *
 * This function creates an action object for identifying news by using a word cloud and sends it to the reducer.
 *
 * This function is called in \src\Views\SearchList\NewsTopics.tsx
 * 
 * @param {any} data - The data payload associated with the action.
 * @returns {Object} - An action object with type "IDENTIFY_NEWS_BY_WORD_CLOUD" and payload.
 */
export const identifyNews = (data: any) => {
  return {
    type: "IDENTIFY_NEWS_BY_WORD_CLOUD",
    payload: data,
  };
};
/**
 * Action creator for getting content.
 *
 * This function creates an action object for fetching content and sends it to the reducer.
 *
 * @param {any} data - The data payload associated with the action.
 * @returns {Object} - An action object with type "GET_CONTENT" and payload.
 */
export const identifyContent = (data: any) => {
  return {
    type: "GET_CONTENT",
    payload: data,
  };
};
/**
 * Action creator for getting all news items.
 *
 * This function creates an action object for fetching all news items and sends it to the reducer.
 *
 * This function is called in \src\Views\TodayNews\TodayNews.tsx
 * 
 * @param {any} data - The data payload associated with the action.
 * @returns {Object} - An action object with type "GET_ALL_NEWS_ITEM" and payload.
 */
export const getAllNewsItem = (data: any) => {
  return {
    type: "GET_ALL_NEWS_ITEM",
    payload: data,
  };
};

export default NewsList;
