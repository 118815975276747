// const FilterSummary = (state = "", action: any) => {
//   switch (action.type) {
//     case "GET_VALUE_OF_FILTER":
//       return (state = action.payload);


//     default:
//       return state;
//   }
// };

// export default FilterSummary;


/**
 * Redux reducer responsible for managing the state related to search filters and search queries.
 *
 * @param {Object} state - The current state of the filter and search query.
 * @param {Object} action - The action dispatched to update the state.
 * @returns {Object} The updated state based on the action.
 */
const FilterSummary = (state = { search: null, filter: "All" }, action: any) => {

  switch (action.type) {

    case "GET_VALUE_OF_FILTER":
      return (state = { search: null, filter: action.payload });

    case "GET_VALUE_OF_SEARCH":
      return (state = { search: action.payload, filter: "" });

    case "RESET_INITIAL_STATE":
      return (state = { search: null, filter: "All" });

    default:
      return state;
  }
};

export default FilterSummary;
