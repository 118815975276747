import React, { useState, useEffect } from "react";
import "./newsTopics.scss";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { newsTopicsProps } from "./newsTopics.types";
import { identifyNews } from "../../Actions/NewsList";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
/**
 * NewsTopics Component.
 *
 * This component displays a list of news topics along with buttons for each topic.
 * Users can click on topics to activate or deactivate them, triggering an action to identify news related to the selected topic.
 * 
 * This function in called in \src\Views\News\News.tsx
 * 
 * @param {Object} props - The props containing the title, wordCloudData, and bar information.
 * @returns {JSX.Element} - A React JSX element representing the NewsTopics component.
 */
const NewsTopics = ({ title, wordCloudData, bar }: newsTopicsProps) => {
  const [wordData, setWordData] = useState<any>([]);
  const dispatch = useDispatch();

  //@ts-ignore
  useEffect(() => {
    if (wordCloudData[bar.toString()] !== undefined) {
      wordCloudData[bar.toString()].forEach((element: any) => {
        element.active = false;
      });
      setWordData(() => {
        return wordCloudData[bar.toString()];
      });
    }
  }, [wordCloudData, bar]);

  const { width }:any = useWindowDimemsions();

  const xsWidth = 576;
  const heightStyle = width && width < xsWidth && wordData?.length === 0 ? { minHeight: '100px', maxHeight: '100px' } :
    width && width < xsWidth && wordData?.length > 0 ? { minHeight: '100px', maxHeight: '75vh' } :
      { minHeight: '75vh', maxHeight: '75vh' };

  //console.log('----------wordData', wordData);

  return (
    <div className={`news-topics col-md-3 ${width && width > 768 ? "mb-2" : "mb-4"}`}>
      <div className=" background-grey p-4 pb-4 green-overflow-scroll" style={heightStyle}>
        <h6>{title}</h6>
        {(wordData as [])?.length > 0 && !wordData[0]?.text?.includes(`DATA NOT AVAILABLE`) &&
          <p>{`These topics were the subject of news articles until yesterday.`}</p>
        }
        {(wordData as [])?.length > 0 && (wordData as []).map((word: any, index: any) => (
          <Button
            className="light-green-btn mb-2"
            style={{
              backgroundColor:
                word.active === true ? "#84fed2" : "rgba(25, 118, 210, 0.04)",
            }}
            key={index}
            onClick={() => {
              for (let i = 0; i < wordData.length; i++) {
                if (i !== index) {
                  wordData[i].active = false;
                }
              }
              wordData[index].active = !wordData[index].active;
              setWordData(() => {
                return [...wordData];
              });

              if (word.ids.length > 0) {
                if (word.active == true) {
                  console.log(word.ids);
                  dispatch(identifyNews(word.ids));
                } else if (word.active == false) {
                  dispatch(identifyNews(["no found ids"]));
                }
              }
            }}
          >
            {word.text.split('.')[0]}
          </Button>
        ))}


      </div>
    </div>
  );
};

export default NewsTopics;
