import "./FeatureSection.scss";
import FeatureGridCard from "./FeatureSection/FeatureGridCard";

const FeatureSection = () => {
  return (
    <section className="landingv2-feature-section">
      <div className="heading">
        <div className="cube-img">
          <img src="assets/images/landingv2/Round Cube3.png" alt="cube-img" />
        </div>
        <div>
          <h2 className="title">Dive into AlgoFabric's Feature Oasis.</h2>
          <div className="subtitle">
            Discover a new dimension in stock market analysis with
            <span className="highlight"> AlgoFabric </span> – which combines the
            <span className="highlight"> power of AI </span> and state-of
            the-art
            <span className="highlight"> NLP models </span> with numerical data
            analysis to provide insights and recommendations.
          </div>
        </div>
      </div>
      <div className="feature-grid">
        <FeatureGridCard
          svg={
            <svg
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3335 10.6666H25.3335M13.3335 16H17.3335M25.3335 16H21.3335M13.3335 21.3333H17.3335M25.3335 21.3333H21.3335"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M2.6665 9.99996V8.99996H1.6665V9.99996H2.6665ZM9.33311 4.66663V3.66663C8.78083 3.66663 8.33311 4.11435 8.33311 4.66663H9.33311ZM29.3332 4.66663H30.3332C30.3332 4.11435 29.8854 3.66663 29.3332 3.66663V4.66663ZM8.33311 9.99996L8.33316 24H10.3332L10.3331 9.99996H8.33311ZM3.6665 24V9.99996H1.6665V24H3.6665ZM2.6665 11H9.33311V8.99996H2.6665V11ZM8.33316 24C8.33316 25.2886 7.28849 26.3333 5.99984 26.3333V28.3333C8.39307 28.3333 10.3332 26.3932 10.3332 24H8.33316ZM1.6665 24C1.6665 26.3932 3.6066 28.3333 5.99984 28.3333V26.3333C4.71117 26.3333 3.6665 25.2886 3.6665 24H1.6665ZM10.3331 9.99996V4.66663H8.33311V9.99996H10.3331ZM9.33311 5.66663H29.3332V3.66663H9.33311V5.66663ZM28.3332 4.66663V24H30.3332V4.66663H28.3332ZM25.9998 26.3333H5.99984V28.3333H25.9998V26.3333ZM28.3332 24C28.3332 25.2886 27.2885 26.3333 25.9998 26.3333V28.3333C28.393 28.3333 30.3332 26.3932 30.3332 24H28.3332Z"
                fill="#2D4890"
              />
            </svg>
          }
          title="Real-time Stock Specific News Analysis"
          subtitle="Stay ahead of the curve with real-time stock specific news analysis"
        />

        <FeatureGridCard
          svg={
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#F3F4F6" />
              <path
                d="M20.6665 34L21.3736 34.707L21.6665 34.4142V34H20.6665ZM17.3332 37.3333L16.6261 38.0404C17.0166 38.4309 17.6498 38.4309 18.0403 38.0404L17.3332 37.3333ZM13.9998 34H12.9998V34.4142L13.2927 34.707L13.9998 34ZM20.6665 32H21.6665C21.6665 31.7836 21.5964 31.573 21.4665 31.4L20.6665 32ZM18.6665 29.3333L17.9594 28.6262C17.6075 28.9781 17.5679 29.5352 17.8665 29.9333L18.6665 29.3333ZM20.6665 27.3333L21.3736 28.0404C21.5612 27.8529 21.6665 27.5985 21.6665 27.3333H20.6665ZM20.6665 23.1081L20.1658 22.2425L19.6665 22.5313V23.1081H20.6665ZM13.9998 23.1081H14.9998V22.5313L14.5006 22.2425L13.9998 23.1081ZM11.6665 17.3333C11.6665 14.2037 14.2036 11.6666 17.3332 11.6666V9.66663C13.099 9.66663 9.6665 13.0991 9.6665 17.3333H11.6665ZM17.3332 11.6666C20.4628 11.6666 22.9998 14.2037 22.9998 17.3333H24.9998C24.9998 13.0991 21.5673 9.66663 17.3332 9.66663V11.6666ZM19.9594 33.2929L16.6261 36.6262L18.0403 38.0404L21.3736 34.707L19.9594 33.2929ZM18.0403 36.6262L14.7069 33.2929L13.2927 34.707L16.6261 38.0404L18.0403 36.6262ZM21.4665 31.4L19.4665 28.7333L17.8665 29.9333L19.8665 32.6L21.4665 31.4ZM19.3736 30.0404L21.3736 28.0404L19.9594 26.6262L17.9594 28.6262L19.3736 30.0404ZM19.6665 23.1081V27.3333H21.6665V23.1081H19.6665ZM22.9998 17.3333C22.9998 19.4295 21.8621 21.2612 20.1658 22.2425L21.1672 23.9737C23.4562 22.6496 24.9998 20.1723 24.9998 17.3333H22.9998ZM14.9998 34V23.1081H12.9998V34H14.9998ZM14.5006 22.2425C12.8042 21.2612 11.6665 19.4295 11.6665 17.3333H9.6665C9.6665 20.1723 11.2101 22.6496 13.4991 23.9737L14.5006 22.2425ZM19.6665 32V34H21.6665V32H19.6665Z"
                fill="#2D4890"
              />
              <path
                d="M17.3335 17.3334H17.3479"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24 26.6666H37.3333V34.6666H24"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M28 14.6666H37.3333V22.6666H28"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          }
          title="Real Time Access to Diverse Datatypes"
          subtitle="Access to a wide range of data types for comprehensive analysis"
        />
        <FeatureGridCard
          svg={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3335 16C12.4381 16 13.3335 15.1046 13.3335 14C13.3335 12.8954 12.4381 12 11.3335 12C10.2289 12 9.3335 12.8954 9.3335 14C9.3335 15.1046 10.2289 16 11.3335 16Z"
                stroke="#2D4890"
                strokeLinejoin="round"
              />
              <path
                d="M19.3335 22.6666C20.4381 22.6666 21.3335 21.7712 21.3335 20.6666C21.3335 19.5621 20.4381 18.6666 19.3335 18.6666C18.2289 18.6666 17.3335 19.5621 17.3335 20.6666C17.3335 21.7712 18.2289 22.6666 19.3335 22.6666Z"
                stroke="#2D4890"
                strokeLinejoin="round"
              />
              <path
                d="M24.6665 12C25.7711 12 26.6665 11.1046 26.6665 10C26.6665 8.89543 25.7711 8 24.6665 8C23.5619 8 22.6665 8.89543 22.6665 10C22.6665 11.1046 23.5619 12 24.6665 12Z"
                stroke="#2D4890"
                strokeLinejoin="round"
              />
              <path
                d="M20.579 19.0617L24.0002 12M12.7768 15.4245L17.6052 19.0617M3.3335 27.3333L10.1196 15.8389"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M26.6665 28H2.6665V4"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          }
          title="Corelation and Insights"
          subtitle="Get insights and correlations between different data types"
        />
        <FeatureGridCard
          svg={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28 4V28H4V4H28Z"
                stroke="#2D4890"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M8.6665 21.6667L12.6665 11H13.9998L17.9998 21.6667M10.6665 17H15.9998M21.9998 21.6667V13.6667M21.9998 12.3334V10.3334"
                stroke="#2D4890"
                strokeWidth="2"
              />
            </svg>
          }
          title="AI based Guidance and Recomendations"
          subtitle="Harnessing AI for Smart Recommendations"
        />
      </div>
    </section>
  );
};

export default FeatureSection;
