import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type HeatMapData = {
  [key: string]: {
    "Outperforming Nifty": number;
    Strategy_perf: number;
    "Display String": string;
  };
};

const HeatMap = () => {
  const [heatmapData, setHeatmapData] = useState<{ name: string; data: any }[]>(
    []
  );
  const [tooltipData, setTooltipData] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [data, setData] = useState<HeatMapData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [colorRanges, setColorRanges] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const cleanDisplayString = (displayString: string) => {
    // Match everything up to "NIFTY ETF--" and removes the remainingf
    const match = displayString.match(/^(.*?)<br><br>NIFTY ETF--/);
    return match ? match[1] : displayString; // Return the matched part or the original string
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/analysis-fabric-files/Momentum_Value_Strategy/Momentum_Value_Strategy-grid.json`
      );
      const data = await response.json();
      processData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const processData = (data: HeatMapData) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const heatmap: { [key: string]: (number | null)[] } = {};
    const tooltips: { [key: string]: string[] } = {}; // Always strings, no null
    let minValue = Infinity;
    let maxValue = -Infinity;

    Object.keys(data).forEach((key) => {
      const [month, year] = key.split(" ");
      const {
        Strategy_perf: returnStratperf,
        "Display String": displayString,
      } = data[key];
      //removing unnecassary info from displaystring
      const cleanedDisplayString = cleanDisplayString(displayString);
      // Update min/max values
      minValue = Math.min(minValue, returnStratperf);
      maxValue = Math.max(maxValue, returnStratperf);

      if (!heatmap[year]) heatmap[year] = Array(12).fill(null);
      const monthIndex = months.indexOf(month);
      heatmap[year][monthIndex] = returnStratperf;

      if (!tooltips[year]) tooltips[year] = Array(12).fill("");
      tooltips[year][monthIndex] = cleanedDisplayString || ""; // Ensure it is a non-null string and cleaned display String.
    });

    setHeatmapData(
      Object.keys(heatmap).map((year) => ({
        name: year,
        data: heatmap[year].map((value, index) => ({
          x: months[index],
          y: value,
        })),
      }))
    );

    setTooltipData(tooltips);
    setColorRanges(generateColorRanges(minValue, maxValue));
    setIsLoading(false);
  };

  const generateColorRanges = (minValue: number, maxValue: number) => {
    // console.log(" News2 Min Value:", minValue, "Max Value:", maxValue);

    return [
      {
        from: minValue,
        to: 0,
        name: "Negative",
        color: "#ff5e5e", // Dark red
      },
      {
        from: 0.01,
        to: maxValue,
        name: "Positive",
        color: "#059212", // Dark green
      },
      {
        from: null,
        to: null,
        name: "No Data",
        color: "#FFFFFF", // White for no data
        show: false,
      },
    ];
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.66,
        radius: 0,
        useFillColorAsStroke: false,
        reverseNegativeShade: true, // Keep shading consistent
        colorScale: {
          inverse: true, // Ensure the gradient direction is correct
          ranges: colorRanges, // Use explicitly defined ranges
        },
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ], // Months
    },
    yaxis: [
      {
        labels: {
          formatter: (value, index) => {
            const year = Object.keys(tooltipData)[index];
            // Ensure the returned value is always a string
            return year || String(value);
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const year = Object.keys(tooltipData)[seriesIndex];
          if (tooltipData[year]) {
            const displayString = tooltipData[year][dataPointIndex];
            if (displayString !== "") {
              return displayString;
            }
          }
          return value !== null && value !== undefined
            ? value.toString()
            : "N/A";
        },
      },
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ApexCharts
        options={options}
        series={heatmapData}
        type="heatmap"
        height={350}
      />
    </div>
  );
};

export default HeatMap;
