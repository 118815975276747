import { Empty } from 'antd';
import useWindowDimemsions from '../../../hooks/useWindowDimesnions';

/**
 * NoResultsBox Component
 * 
 * This component represents a box that displays a message when there are no results to show.
 * It can optionally display an empty image and adjusts its style based on window dimensions.
 * 
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * This function is called in \src\Component\Search\Search.tsx
 * This function is called in \src\Views\CompanyDocSummary\CompanyDocSummary.jsx
 * This function is called in \src\Views\PromotersAndPublic\SubComponents\Table.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.displayText - The text message to display when no results are available.
 * @param {boolean} props.showEmptyImage - A boolean indicating whether to display an empty image.
 * @returns {JSX.Element} - The rendered NoResultsBox component.
 */
function NoResultsBox({ displayText, showEmptyImage }) {
    const lgWidth = 991;
    const { width } = useWindowDimemsions();
    const styleObj =
      width && width > lgWidth ? { fontSize: "large"} : { fontSize: "medium" };

    const inputProps = {};

    if (showEmptyImage) {
      //show default empty image
      inputProps.image = Empty.PRESENTED_IMAGE_SIMPLE;
    }

    return (
      <Empty
        description={
          <span style={{ ...styleObj, color: "gray", opacity: "2" ,  marginTop :"12%"}}>
            {displayText}
          </span>
        }
        {...inputProps}
      />
    );
}


export default NoResultsBox;


NoResultsBox.defaultProps = {
    displayText: "results will be displayed here...",
    showEmptyImage: false
};
