import React from "react";
import { AutoComplete } from "antd";
import { SearchOutlined } from "@ant-design/icons"; //search icon
import { useEffect, useState } from "react";

import "./TickerFilter.scss";

type TickerFilterProps = {
  allTickers: string[];
  onChange: (value: string) => void;
  selectedTicker?: string;  // Optional prop for pre-selected ticker
};

const TickerFilter = (props: TickerFilterProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(props.selectedTicker);

  const options = props.allTickers.map((ticker) => {
    return { value: ticker };
  });

  // Update the selectedValue if selectedTicker prop changes
  useEffect(() => {
    setSelectedValue(props.selectedTicker);
  }, [props.selectedTicker]);

  return (
    <div className="ticker-filter">
      <AutoComplete
        style={{ width: "100%",opacity: "1" }}
        placeholder="Search Ticker Name"
        allowClear={true}
        defaultActiveFirstOption={false}
        options={options}
        value={selectedValue} // Controlled value for AutoComplete
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value) => {
          setSelectedValue(value); // Update local state
          props.onChange(value); // Notify parent component
        }}
      />
      <SearchOutlined className="search-icon" />  {/* Search icon */}
    </div>
  );
};

export default TickerFilter;
