import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import sortIcon from "../../../assets/images/icons/sortBy.svg";
import "./sort.scss";
import useWindowDimemsions from "../../../hooks/useWindowDimesnions";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export type sortProps = {
  sortSentiment: string[];
  sortByScore: (event: string) => void;
  title: string;
};
/**
 * Sort Component.
 *
 * This component provides a sorting menu for sorting and filtering options. It displays a button
 * with a sorting icon and a dropdown menu with sorting options.
 *
 * This function is called in \src\Views\NewsAggregator\AggregatorNewsTab\AggregatorNewsTab.jsx
 * This function is called in \src\Views\NewsContent\NewsContent.tsx
 * This function is called in \src\Views\NewsCount\NewsCount.tsx
 * This function is called in \src\Views\TodayNews\TodayNews.tsx
 * This function is called in \src\Views\TodayTweets\TodayTweets.tsx
 * This function is called in \src\Views\TweetsContent\TweetsContent.tsx
 * 
 * @param {Object} props - The props containing sortSentiment and sortByScore functions.
 * @returns {JSX.Element} - A React JSX element representing the Sort component.
 */
export default function Sort(props: sortProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { width }:any = useWindowDimemsions();
  const lgWidth = 991;
  const styleObj=  width && width > lgWidth ? {} : {  fontSize:"small",  };
  const buttonStyle=  width && width > lgWidth ? {fontSize:'15px'} : {  fontSize:"12px",  };

  return (
    <div className="sort-menu">
      <Button
        sx={buttonStyle}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        disableElevation
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<img src={sortIcon} alt="sort by" />}
      >
        {props.title}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        className="customized-menu-sort"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {(props.sortSentiment as []).map((sentiment, index) => (
          <div key={index}>
            <MenuItem
            sx={styleObj}
              onClick={() => {
                handleClose();
                props.sortByScore(sentiment);
              }}
              disableRipple
            >
              {/* <span
                className="statusDots"
                style={{
                  background:
                    sentiment === "Negative Sentiment"
                      ? "rgb(253, 24, 126)"
                      : sentiment === "Positive Sentiment"
                      ? "rgb(57, 224, 164)"
                      : "rgb(254, 175, 56)",
                }}
              >
                &nbsp;
              </span>{" "} */}
              &nbsp;
              {sentiment}
            </MenuItem>
            <Divider sx={{ my: 0.5 }} key={index} />
          </div>
        ))}
      </StyledMenu>
    </div>
  );
}
Sort.defaultProps = {
  title: "Sort/Filter\u00A0By",
};
