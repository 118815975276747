


//sorts the array of objects
/**
 * Sorts an array of objects based on the specified mode.
 *
 * This function is called in \src\utils\Common\index.js
 * 
 * @param {Array} data - The array of objects to be sorted.
 * @param {string} mode - The sorting mode, e.g., "Date: Newest to Oldest".
 * @param {string} dateProperty - The property containing the date for sorting.
 * @param {string} sentimentProperty - The property containing sentiment information.
 * @returns {Array} - The sorted array of objects.
 */
export const customSortArr = (data, mode, dateProperty ,sentimentProperty) => {
    let sortedList = [];
    
    if(data?.length === 0) { sortedList = []; }    // check if empty data array 
    else if  (mode === "Date: Newest to Oldest") {
      sortedList = [...data].sort(function (a, b) {
        let date1 = new Date(a[dateProperty]);
        let date2 = new Date(b[dateProperty]);
        return date2 - date1;
      });
    } else if (mode === "Date: Oldest to Newest") {
      sortedList = [...data].sort(function (a, b) {
        let date1 = new Date(a[dateProperty]);
        let date2 = new Date(b[dateProperty]);
        return date1 - date2;
      });
    } else if (mode === "Sentiment: Positive to Negative") {
      let positive = data.filter((news) => news[sentimentProperty] === "positive");
      let neutral = data.filter((news) => news[sentimentProperty] === "neutral");
      let negative = data.filter((news) => news[sentimentProperty] === "negative");
      sortedList = [...positive, ...neutral, ...negative];
    } else if (mode === "Sentiment: Negative to Positive") {
      let positive = data.filter((news) => news[sentimentProperty] === "positive");
      let neutral = data.filter((news) => news[sentimentProperty] === "neutral");
      let negative = data.filter((news) => news[sentimentProperty] === "negative");
      sortedList = [...negative, ...neutral, ...positive];
    }
    return sortedList;
  }