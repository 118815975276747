import React from 'react';
import "./styles/Marquee.scss";
import { FaVolumeUp } from 'react-icons/fa';

interface MarqueeProps {
  text1: string;
  text2: string;
}

const Marquee: React.FC<MarqueeProps> = ({ text1, text2 }) => (
  <div className="marquee-container">
    <div className="marquee-text">
    <FaVolumeUp style={{paddingBottom:"3px"}} /> {text1} &nbsp;<FaVolumeUp style={{paddingBottom:"3px"}} /> {text2} &nbsp;
    {/* <FaVolumeUp style={{paddingBottom:"3px"}} />{text1} <FaVolumeUp style={{paddingBottom:"3px"}} /> {text2} */}
    </div>
  </div>
);

export default Marquee;