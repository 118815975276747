/**
 * Redux reducer responsible for managing the state related to identifying tweets by word cloud.
 *
 * @param {Array} state - The current identified tweets by word cloud state.
 * @param {Object} action - The action dispatched to update the state.
 * @returns {Array} The updated identified tweets by word cloud state based on the action.
 */
const IdentifyTweets = (state = [], action: any) => {
  switch (action.type) {
    case "IDENTIFY_TWEET_BY_WORD_CLOUD":
      return (state = action.payload);
    default:
      return state;
  }
};

export default IdentifyTweets;
