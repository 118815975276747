import React, { useState, useRef, useEffect } from "react";
// import { ChevronDownIcon } from 'lucide-react'

import "./StockSelectionDropdown.scss";

type StockSelectionDropdownProps = {
  stocks: { value: string; label: string }[];
};

function StockSelectionDropdown({ stocks }: StockSelectionDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState<(typeof stocks)[0] | null>(
    null
  );
  const [searchValue, setSearchValue] = useState(""); // New state for search input
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setIsOpen(true); // Open dropdown when typing
  };

  const handleOptionClick = (stock: { value: string; label: string }) => {
    setSelectedStock(stock);
    setSearchValue(stock.label); // Set input to selected option
    setIsOpen(false);
    console.log("Selected stock:", stock.value);

    const ticker = stock.value;
    if (ticker) {
      window.localStorage.setItem("selectedTab", "0");
      window.localStorage.setItem("tickerId", ticker);
      window.location.href = `/detail`;
    }
  };

  const filteredStocks = stocks.filter((stock) =>
    stock.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="stock-select" ref={dropdownRef}>
      <input
        className = "stock-input-home"
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        placeholder="Search Ticker Name"
        
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <div className="stock-options">
          <ul className="py-1">
            {filteredStocks.length > 0 ? (
              filteredStocks.map((stock) => (
                <li
                  key={stock.value}
                  onClick={() => handleOptionClick(stock)}
                  className="stock-option-item"
                >
                  {stock.label}
                </li>
              ))
            ) : (
              <li className="no-results">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default StockSelectionDropdown;
