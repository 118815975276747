import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"; //for redirecting to Login..
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "./ResetPasswordPage.scss";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for redirection

  // Get the token and email from the query params (from the reset link)
  const token = searchParams.get("token");
  const decodedToken = token ? decodeURIComponent(token) : "";
  const email = searchParams.get("email")
    ? decodeURIComponent(searchParams.get("email") as string)
    : "";

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form input
    if (!token || !email) {
      setMessage("Invalid or expired reset link.");
      setIsSuccess(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      setIsSuccess(false);
      return;
    }

    // Regex to validate password strength (at least 8 characters, one uppercase, one number, one special character)
    const passwordStrengthRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordStrengthRegex.test(newPassword)) {
      setMessage(
        "Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character."
      );
      setIsSuccess(false);
      return;
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_AUTH_URL || "http://localhost:3000"
        }/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: decodedToken,
            email,
            newPassword,
            confirmPassword,
            verified: true,
          }),
        }
      );

      // const data = await response.json();
      // Get raw response as text for testing..data
      const textResponse = await response.text();

      if (response.ok) {
        const data = JSON.parse(textResponse);
        setMessage("Password successfully reset! Redirecting to login page.");
        setIsSuccess(true);
        setTimeout(() => {
          navigate("/login"); // use navigate to redirect
        }, 5000);
      } else {
        // setMessage(data.message || "Error resetting password.");
        setMessage("Error resetting password.");
        setIsSuccess(false);
      }
    } catch (error) {
      // console.error("Reset password error:", error);
      setMessage("An error occurred while resetting the password.");
      setIsSuccess(false);
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-box">
        <img
          className="logo-img"
          src="https://algofabric.algoanalytics.com/assets/images/landingv2/main-logo.png"
          alt="AlgoFabric"
        />

        <h2 style={{ backgroundColor: "#80808091", padding: "10px 2px" }}>
          Reset Password
        </h2>
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>New Password</label>
            <div className="password-wrapper">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="toggle-password-btn"
              >
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>Confirm New Password</label>
            <div className="password-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="toggle-password-btn"
              >
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                />
              </button>
            </div>
          </div>
          <button type="submit" className="reset-password-btn">
            Reset Password
          </button>
        </form>
        {message && (
          <p className={isSuccess ? "success-message" : "error-message"}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
