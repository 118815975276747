import React, { useEffect, useState } from "react";
import "./news.scss";
import NewsTopics from "../NewsTopics/NewsTopics";
import NewsContent from "../NewsContent/NewsContent";
import NewsArticle from "../NewsArticle/NewsArticle";
import Nouislider from "nouislider-react";
import axios from "axios";
import { newsTypes, url_params } from "./news.types";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import TweetsList from "../../Actions/TweetsList";
import { useParams, useLocation } from "react-router-dom";

const News = ({ tickerid, news_id }: url_params) => {
  const [singleNews, setSingleNews] = useState<newsTypes | {}>({} as newsTypes);
  const [allNews, setAllNews] = useState<newsTypes[] | []>([]);
  // const [newsIndex, setNewsIndex] = useState<newsTypes | {}>({} as newsTypes);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [wordCloudData, setWordCloudData] = useState<any>([]);
  const [filterNews, setFilterNews] = useState<newsTypes[] | []>([]);
  const listIndex = useSelector((state: any) => state.NewsListData);
  const newsByWord = useSelector((state: any) => state.IdentifyNews);
  const dispatch = useDispatch();
  const initial = 5;

  let { ticker } = useParams();
  let location = useLocation();

  const [bar, setBar] = useState(initial);

  useEffect(() => {
    dispatch(TweetsList(null));
  }, []);

  const getAllNews = () => {
    setIsLoaderVisible(true);
    let ticker_id = tickerid
      ? tickerid
      : window.localStorage.getItem("tickerId");
    var url = `${process.env.REACT_APP_API_URL}/` + ticker_id + `/news.json`;
    // console.log(ticker_id);
    //  console.log("NEWS", url);
    axios
      .get(url)
      .then((response) => {
        setWordCloudData(response.data.Word_Cloud);
        //console.log("List", response.data);
        response.data.News_List.forEach((element: any) => {
          element.active = false;
          element.filterDate = moment(element.News_date).format("YYYY-MM-DD");

          // if (news_id && news_id == element["News_ID"]) {
          //   element.active = true;
          //   setNewsIndex(element);
          // }
        });

        const newsList = response.data.News_List;
        let sortedList: Array<any> = response.data.News_List.sort(function (
          a: any,
          b: any
        ) {
          let date1: any = new Date(a.News_date);
          let date2: any = new Date(b.News_date);
          return date2 - date1;
        });

        // let singleNewsIndex = Id ? Id : listIndex

        // if (listIndex !== null) {
        //   let findNewsIndex = sortedList.findIndex(
        //     (news: any) => news.News_ID === listIndex
        //   );
        //   sortedList[findNewsIndex].active = true;
        //   setSingleNews(sortedList[findNewsIndex]);
        // } else
        console.log("news_id", news_id);
        if (news_id) {
          var findNewsIndex =
            sortedList &&
            sortedList.findIndex((news: any) => news.News_ID === news_id);
          sortedList[findNewsIndex].active = true;
          setSingleNews(sortedList[findNewsIndex]);
        } else {
          sortedList[0].active = true;
          setSingleNews(sortedList[0]);
        }

        setFilterNews(sortedList);

        // var result: any = [];
        // for (var i = 0; i < 5; i++) {
        //   var d = new Date();
        //   d.setDate(d.getDate() - i);
        //   result.push(moment(d).format("YYYY-MM-DD"));
        // }

        // const newsFilter = sortedList.filter((news: any) => {
        //   return result.includes(news.filterDate);
        // });

        // setAllNews(newsFilter);
        setAllNews(sortedList);

        setIsLoaderVisible(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaderVisible(false);
      });
  };
  useEffect(() => {
    getAllNews();
  }, []);

  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(moment(d).format("YYYY-MM-DD"));
    }

    if (bar > 0) {
      const newsFilter = filterNews.filter((news) => {
        return result.includes(news.filterDate);
      });

      if (newsFilter.length > 0) {
        for (let i = 0; i < newsFilter.length; i++) {
          newsFilter[i].active = false;
        }
        newsFilter[0].active = true;
        setSingleNews(newsFilter[0]);
      } else {
        setSingleNews({});
      }
      setAllNews(newsFilter);
    }
  }, [bar]);

  const update = (values: any) => {
    setBar(parseFloat(values[0]));

    // setFilterDates(result);
  };

  useEffect(() => {
    if (newsByWord.length > 0) {
      const filterNewsByWords = filterNews.filter((news) =>
        newsByWord.includes(news.ID)
      );
      if (filterNewsByWords.length > 0) {
        for (let i = 0; i < filterNews.length; i++) {
          filterNews[i].active = false;
        }

        // let findNewsIndex = filterNews.findIndex(
        //   (news: any) => news.News_ID === filterNewsByWords[0].News_ID
        // );
        filterNewsByWords[0].active = true;
        setAllNews(() => {
          return [...filterNewsByWords];
        });
        setSingleNews(filterNewsByWords[0]);
      } else {
        var result: any = [];
        for (var i = 0; i < Math.floor(bar); i++) {
          var d = new Date();
          d.setDate(d.getDate() - i);
          result.push(moment(d).format("YYYY-MM-DD"));
        }

        if (bar > 0) {
          const newsFilter = filterNews.filter((news) => {
            return result.includes(news.filterDate);
          });

          if (newsFilter.length > 0) {
            for (let i = 0; i < newsFilter.length; i++) {
              newsFilter[i].active = false;
            }
            newsFilter[0].active = true;
            setSingleNews(newsFilter[0]);
          } else {
            setSingleNews({});
          }
          setAllNews(newsFilter);
        }
      }
    }
  }, [newsByWord]);

  return (
    <div className="news">
      <div className="text-center bar-title">
        <span>Days</span>
        <Nouislider
          style={{ width: "100%", margin: "0rem 2rem 0 1rem" }}
          behaviour="tap-snap"
          connect={[true, false]}
          start={initial}
          onUpdate={update}
          range={{ min: [1], max: [30] }}
        />
        <div className="mint-text text-center">{`${Math.floor(bar)}`}</div>
      </div>

      <br />
      <div className="row">
        <NewsTopics
          title={"News Topics"}
          wordCloudData={wordCloudData}
          bar={Math.floor(bar)}
        />
        <NewsContent setSingleNews={setSingleNews} allNews={allNews} />
        <NewsArticle singleNews={singleNews} />
      </div>

      {isLoaderVisible && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default React.memo(News);
