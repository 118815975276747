import './NerAanalysis.scss';
import { useMemo } from 'react';

//display top 5 ner companies


const allowedColors = ['#FD8A8A', '#F1F7B5', '#A8D1D1', '#9EA1D4'];

const colorsArr = [...allowedColors, ...allowedColors]; //array of length 8


const removeSpace = (item: any) => {
    let str: any = item;

    if (str.indexOf(" ") >= 0) {
        str = str.split(' ').join('\u00A0')
    }

    return str;
};


const sortAndFilterData = (data: any) => {

    let result: any = [];

    if (data?.length === 0) {
        //ignore
    } else {
        result = [...data].sort((a, b) => (a.frequency > b.frequency ? -1 : 1));
    }

    return result.length > 5 ? result.slice(0, 5) : result;

};

const NerAanalysis = ({ data }: any) => {

    const nerData = useMemo(
        () => sortAndFilterData(data),
        [data]
    );

    return (
        <>
            <div className="insider-child mb-3">
                <div className="header mt-3 mb-3">
                    <h6>Organizations</h6>
                </div>

                <div className="background-white p-3">

                    {nerData.length > 0 ?
                        <div className="new-classes-grid-container">
                            {nerData.map((item: any, index: any) =>
                                <div className="flex-item" key={index}>
                                    <span style={{ background: colorsArr[index] }} className="highlight-text mb-3">
                                        <span className="unhighlight">{removeSpace(item.entity)}</span>
                                    </span>
                                </div>
                            )}
                        </div>

                        :
                        <p className="new-classes-no-news" >No Data</p>
                    }

                </div>
            </div>
        </>
    );
}

export default NerAanalysis;