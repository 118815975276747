import { configureStore, createStore  } from "@reduxjs/toolkit";
import RootReducer from "./Reducers/index";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const persistConfig = {
  key: 'root',
  storage,
}
/**
 * Redux store configuration and setup.
 *
 * This code configures and sets up the Redux store for managing application state.
 * It also includes the configuration for Redux Persist, which allows state persistence.
 */
const persistedReducer = persistReducer(persistConfig, RootReducer)

export let store = createStore(persistedReducer)
export let persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

