import { ReactNode, useRef, useState } from "react";
import { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./ProtectedHOC.scss";
import { UserInfo } from "../../context/AuthContext";

type ProtectedPageProps = {
  userInfo: UserInfo | null;
  children?: ReactNode;
  requiredScope?: string[];
};

const ProtectedPageHOC = ({
  children,
  userInfo,
  requiredScope,
}: ProtectedPageProps) => {
  const [loadingState, setLoadingState] = useState(false);
  const [messageState, setMessageState] = useState<string | null>(null);
  const [errorState, setErrorState] = useState<string | null>(null);
  const [requestSent, setRequestSent] = useState(false); // State to track if request was sent
  const navigate = useNavigate();
  const scopeRef = useRef<any>();

  useEffect(() => {
    if (scopeRef.current) {
      scopeRef.current.show();
    }
  }, []);

  useEffect(() => {
    if (!userInfo) {
      // Set a timeout to redirect to the login page after 5 seconds (5000 milliseconds)
      const timer = setTimeout(() => {
        navigate("/login"); // Navigate to the login page
      }, 5000);
      // setLoadingState(true);

      // Cleanup function to clear the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [userInfo, navigate]); // Depend on userInfo and history

  const handleRequestAccess = async () => {
    if (!requiredScope || requestSent) return;
    // Reset states
    setLoadingState(true);
    setErrorState(null);
    setMessageState(null);

    try {
      // Send post request to backend
      const res = await fetch(
        `${
          process.env.REACT_APP_AUTH_URL || "http://localhost:3000"
        }/requestAccess`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            scopes: [...requiredScope],
          }),
        }
      );

      const resData = await res.json();
      console.log(resData);
      // If successful, redirect to login page
      if (res.ok) {
        setMessageState(
          "Requested Access successfully 🎉 Redirecting to Previous Page."
        );
        // Redirect to login page
        setTimeout(() => {
          console.log("Redirecting now");
          // window.location.href = "/";
          navigate("/");
        }, 2000);
      } else {
        // If unsuccessful, display error message
        throw new Error(
          resData?.message ||
            "Could not send request. Try again after some time."
        );
      }
    } catch (err: any) {
      setErrorState(
        err?.message || "Could not send request. Try again after some time."
      );
    } finally {
      setLoadingState(false);
    }
  };

  const handleCloseModal = () => {
    navigate("/"); // Redirects to the home page
  };
  const hasAccess = requiredScope?.every((scope) =>
    userInfo?.scopes.includes(scope)
  );

  return (
    <>
      {!hasAccess && (
        <>
          <div className="modal-overlay">
            <div className="modal-content1">
              {/* Check if request was sent */}
              {/* {!requestSent ? ( */}
              <>
                {/* Close (X) button in top-right corner */}
                <button
                  className="close-button"
                  onClick={handleCloseModal}
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "5px",
                    background: "none",
                    paddingBottom: "20px",
                    border: "none",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </button>
                <h2>You don't have access to visit this page.</h2>
                <div className="animate-spin">
                  {loadingState && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      height={40}
                      width={40}
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )}
                </div>
                {!requestSent ? (
                  <>
                    <button
                      className="button btn1"
                      onClick={handleRequestAccess}
                      disabled={loadingState}
                    >
                      Request access
                    </button>
                    {errorState && (
                      <p className="error-message">{errorState}</p>
                    )}
                  </>
                ) : (
                  <p className="success-message">
                    Your request is already sent! Request is being processed.
                    Wait for some time.
                  </p>
                )}
                {messageState && (
                  <p className="success-message">{messageState}</p>
                )}
              </>
              {/* // ) : (
              //   <h2>Request Sent! Redirecting to previous page...</h2>
              // )} */}
            </div>
          </div>
        </>
      )}
      <div className={!hasAccess ? "blurred-content" : ""}>
        {children}
        <Outlet />
      </div>
    </>
  );
};
export default ProtectedPageHOC;
