
//same table for public and promoter

import React from 'react';
import NoResultsBox from '../../Common/NoResultsBox/NoResultsBox';
import './table.scss';

//sort down --> descending --> <i className="fas fa-sort-down"
/**
 * The `CustomTable` component renders a table with sortable columns.
 *
 * This function is called in \src\Views\PromotersAndPublic\Promoters.jsx
 * 
 * @param {Object} props - The component's properties.
 * @param {Array} props.tableData - The data to populate the table rows.
 * @param {Array} props.tableHeaderData - The table column headers.
 * @param {Function} props.onSort - The function to handle column sorting.
 * @param {string} props.mode - The sorting mode (ascending or descending).
 * @param {string} props.sortFilterVal - The current sorting filter value.
 * @param {string} props.filterVal - The current filter value.
 * @returns {JSX.Element} The rendered `CustomTable` component.
 */
const CustomTable = ({ tableData, tableHeaderData, onSort, mode, sortFilterVal, filterVal }) => {

  //const tableHeaderData=[`MAR 2022`];
 // console.log('tableData', tableHeaderData, tableData);

  return (
    <div id="fixTableHead">
      <table>
        <thead >
          <tr >
            {tableHeaderData.length > 0 ? tableHeaderData.map((name, ind) =>
              <th
                key={ind}
              >
                <div className={`th-icon-text ${ind=== 0 ? 'name-col-th':''}`}>
                  <div>{name}</div>
                  <div>
                    {sortFilterVal === 'asc' && filterVal === name ?
                      <i className="fas fa-sort-up p-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => { onSort(name, mode, 'asc') }} ></i>
                      :
                      <i className="fas fa-sort-down p-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => { onSort(name, mode, 'desc') }}></i>
                    }
                  </div>
                </div>

              </th>)
              : null}
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData?.length > 0 &&
            tableHeaderData?.length > 0 ?
            tableData.map((object, index) =>
              <tr key={index}>
                  {Object.keys(object || {})?.length !== 0 &&
                  tableHeaderData.map((month, index) => <td key={index}>{(object[month])}</td>)}
              </tr>
            )
            :
            <tr >
              <td colSpan={6} style={{ paddingTop: 30, paddingBottom: 30 }}>
                <NoResultsBox
                  displayText={`No Data`}
                  showEmptyImage={true}
                />
              </td>
            </tr>
          }

        </tbody>
      </table>
    </div>
  );
}

export default CustomTable;