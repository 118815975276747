import { format } from "date-fns";
import { customSortArr } from "../Sort";



//used in NewsContent, NewsAggregator
/**
 * This function extracts unique options from a dataset based on a specified property.
 * It's used in NewsContent and NewsAggregator components to get unique news sources.
 *
 * @param {Array} data - The dataset from which unique options are to be extracted.
 * @param {string} newsSourceProperty - The property to be used for extracting unique options (default: 'News_Source').
 * @returns {Array} - An array of unique options with 'value' and 'label' properties.
 */
export const getUniqueOptions = (data, newsSourceProperty = `News_Source`) => {

  let result = [];

  if (data?.length === 0) {
    // setOptionsData([]);
    result = [];
  } else {
    const newSourceArr = data.map((news) => ({
      value: news?.[newsSourceProperty],
      label: news?.[newsSourceProperty],
    }));

    const uniqueNewSources = newSourceArr?.length > 0 ?
      newSourceArr.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.value === current.value)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []) : [];

    //  console.log(`populateOptions`, uniqueNewSources);

    //setOptionsData(uniqueNewSources);
    result = uniqueNewSources;
  }


  return result;

};


export const getSortOrFilteredArr = (originalData = [], searchValue, sortValue,
  newsSourceProperty = 'News_Source', dateProperty = 'News_date', sentimentProperty = "News_Sentiment") => {

  let resultArr = [];
  if (originalData?.length === 0) { resultArr = []; }  //if originalData array empty do nothing
  else if (searchValue && sortValue) {
    const filteredData = originalData.filter((news) => news?.[newsSourceProperty] === searchValue);
    resultArr = customSortArr(filteredData, sortValue, dateProperty, sentimentProperty);
  } else if (searchValue && !sortValue) {
    resultArr = originalData.filter((news) => news?.[newsSourceProperty] === searchValue);
  } else if (!searchValue && sortValue) {
    //console.log(`condition 3`, sortValue)
    resultArr = customSortArr(originalData, sortValue, dateProperty, sentimentProperty);
  } else {
    resultArr = originalData;
  }

  return resultArr;
};



//used by Techincal anaylsis graph  ,  bar --> dates result, NewsSentimentVsVolume graph , etc
export const getDateYYYYMMDD = (value) => {
  return (format(new Date(value), 'yyyy-MM-dd'));
}


