import React from 'react';
import { ThunderboltFilled, CheckCircleFilled } from '@ant-design/icons';
/**
 * ChatBotSuggestions Component
 * 
 * This component renders a set of static chatbot suggestions or actions that users can click on.
 * Currently, the suggestions are static and not dynamically generated.
 * 
 * This function is called in \src\Views\ChatBot\CustomPopover\CustomPopover.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Function} props.onResetMessages - A callback function to reset chat messages.
 * @returns {JSX.Element} - The rendered chatbot suggestions component.
 */
const ChatBotSuggestions = ({ onResetMessages }) => {

//suggestions pills, currently static not dynamic

    return (
        <div className='all-pills'>

            <div className='togther-with-pill' onClick={onResetMessages}>
                <CheckCircleFilled className='start-chat-icon' />
                <span className="custom-badge-pill">Start&nbsp;Chat&nbsp;Again</span>
            </div>

            {/* <div className='togther-with-pill'>
                <ThunderboltFilled className='Knowledge-icon' />
                <span >Knowledge&nbsp;Base</span>
            </div> */}

        </div>
    );
}

export default ChatBotSuggestions;