import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./card.scss";

type cartsProps = {
  image: string | undefined;
  heading: string;
  status: string;
};
/**
 * React component for rendering a card with an image, heading, and status.
 *
 * This function is called in \src\Component\Menu\Menu.tsx
 * 
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.image - The image source URL.
 * @param {string} props.heading - The card heading.
 * @param {string} props.status - The card status.
 * @returns {React.Component} A card component.
 */
export default function Cards(props: cartsProps) {





  return (
    <Card
      //sx={{ maxWidth: 345 }}
      className="horizontal-card "
    >
      {/* <img src={props.image} alt="messageIcon" className="card-icons" /> */}
      {/* <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          className="activity-numbers text-nowrap"
          component="div"
          style={{ fontSize: "17px", textTransform: "uppercase" }}
        >
          {props.heading}
        </Typography>
        <Typography
          variant="body2"
          className="activity-status text-nowrap"
          color="text.secondary"
        >
          {props.status}
        </Typography>
      </CardContent> */}

      <CardContent style={{ margin: "auto", padding:'10px 0' }}>
        <Typography
          variant="body2"
          className="activity-status text-nowrap"
          color="text.secondary"
        >
          {props.status}
        </Typography>

        <Typography
          gutterBottom
          variant="h6"
          className="activity-numbers text-nowrap"
          component="div"
          style={{
            textTransform: "uppercase",
            marginBottom: 0,
          }}
        >
          {props.heading}
        </Typography>
        
      </CardContent>
    </Card>
  );
}
