import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import ChatHeader from '../ChatHeader/ChatHeader';
import ChatBody from '../ChatBody/ChatBody';
import ChatInput from '../ChatInput/ChatInput';
import ChatFooter from '../ChatFooter/ChatFooter';
import './CustomPopover.scss';
import ChatBotSuggestions from '../ChatBotSuggestions/ChatBotSuggestions';
/**
 * CustomPopover Component
 * 
 * This function is called in \src\Component\ChatBot\Chatbot.jsx
 * 
 * This component represents a custom chat popover, which includes a header, body,
 * input field for typing messages, and footer. It also handles the display of chat suggestions.
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Function} props.togglePopover - A callback function to toggle the popover's visibility.
 * @param {boolean} props.showPopover - A boolean indicating whether the popover should be displayed.
 * @param {Array} props.messages - An array of chat messages.
 * @param {string} props.textMessage - The text message being typed by the user.
 * @param {Function} props.onMessageChange - A callback function to handle message text changes.
 * @param {Function} props.onMessageSubmit - A callback function to handle message submission.
 * @param {string} props.scrollState - The scroll state to trigger scrolling behavior.
 * @param {Function} props.onResetMessages - A callback function to reset chat messages.
 * @returns {JSX.Element} - The rendered custom chat popover component.
 */
function CustomPopover({ togglePopover, showPopover, messages, textMessage, onMessageChange,
    onMessageSubmit, scrollState, onResetMessages }) {

    const popovercss = (showPopover === true) ? "popover open" : "popover";  //toggle css of popover

    return (
        <>
            <div className={popovercss}>

                <ChatHeader
                    togglePopover={togglePopover}
                />

                <ChatBody
                    messages={messages}
                    scrollState={scrollState}
                    onResetMessages={onResetMessages}

                />

                <ChatBotSuggestions
                    onResetMessages={onResetMessages}
                />


                <ChatInput
                    textMessage={textMessage}
                    onMessageChange={onMessageChange}
                    onMessageSubmit={onMessageSubmit}
                />



                <ChatFooter />

            </div>


            {showPopover === false &&  //hide button when popover is open
                <button
                    onClick={() => togglePopover()}
                    className="popover-trigger-open">
                    <MessageOutlined className='show-button' />
                </button>
            }
        </>
    );
}

export default CustomPopover;