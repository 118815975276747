import "./TrendingEventCard.scss";

type TrendingEventCardProps = {
  title: React.ReactNode;
  ticker: string;
  events: {
    title: React.ReactNode;
    subtitle: string;
  }[];
  children?: React.ReactNode; // Allow children prop
};

const TrendingEventCard = (props: TrendingEventCardProps) => {
  return (
    <div className="trending-event-card">
      <div className="card-title">
        <div className="name">{props.title}</div>
        {/* <div className="ticker">{props.ticker}</div> */}
      </div>
      <div className="events">
        {props.events.map((event, i) => (
          <div className="event" key={i}>
            <div className="event-title">{event.title}</div>
            <div className="event-subtitle">{event.subtitle}</div>
          </div>
        ))}
      </div>
      {/* Render children if provided */}
      {props.children && <div className="extra-content">{props.children}</div>}
    </div>
  );
};

export default TrendingEventCard;
