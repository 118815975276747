import { Bar, Pie } from "react-chartjs-2";

const QuarterlyResults = ({ graphData }) => {
  let quarterlyResultsGraphData = null;
  let allValuesPositive = false;
  if (graphData) {
    const quarterlyResultsBarData = graphData.quarterly_bar_coordinates;
    const quarterlyResultsPieData = graphData.profit_pie_coordinates;

    const barLabels = Object.keys(
      quarterlyResultsBarData[Object.keys(quarterlyResultsBarData)[0]]
    );
    const pieLabels = Object.keys(
      quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
    );

    const barDataset = {
      label: Object.keys(quarterlyResultsBarData)[0],
      data: Object.values(
        quarterlyResultsBarData[Object.keys(quarterlyResultsBarData)[0]]
      ),
      backgroundColor: `rgba(255, 28, 28, 0.8)`,
      borderColor: `rgba(255, 28, 28, 1)`,
      borderWidth: 1,
    };

    allValuesPositive = Object.values(
      quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
    ).every((value) => value > 0);

    const pieDataset = {
      label: Object.keys(quarterlyResultsPieData)[0],
      data: Object.values(
        quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
      ),
      backgroundColor: [
        "rgb(255,118,74,1)",
        "rgb(0,63,92, 1)",
        "rgb(122,81,149,1)",
        "rgb(255,166,0,1)",
      ],
      borderColor: [
        "rgb(255,118,74,1)",
        "rgb(0,63,92, 1)",
        "rgb(122,81,149,1)",
        "rgb(255,166,0, 1)",
      ],
      borderWidth: 1,
    };

    const DivergingBarDataset = {
      label: Object.keys(quarterlyResultsPieData)[0],
      data: Object.values(
        quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
      ),
      backgroundColor: Object.values(
        quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
      ).map((value) =>
        value > 0 ? "rgba(75, 192, 192, 0.8)" : "rgba(75, 192, 192, 0.8)"
      ),
      borderColor: Object.values(
        quarterlyResultsPieData[Object.keys(quarterlyResultsPieData)[0]]
      ).map((value) =>
        value > 0 ? "rgba(75, 192, 192, 1)" : "rgba(75, 192, 192, 1)"
      ),
      borderWidth: 1,
    };

    const Options = {
      indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right",
        },
        title: {
          display: true,
          text: "Quarterly Results",
        },
      },
    };

    const BarGraphData = {
      labels: barLabels,
      datasets: [barDataset],
    };

    const PieGraphData = {
      labels: pieLabels,
      datasets: [pieDataset],
    };

    const DivergingBarData = {
      labels: pieLabels,
      datasets: [DivergingBarDataset],
    };

    quarterlyResultsGraphData = {
      BarGraphData,
      PieGraphData,
      DivergingBarData,
      Options,
    };
  }

  return quarterlyResultsGraphData ? (
    <>
      <div className="graph">
        <Bar
          data={quarterlyResultsGraphData.BarGraphData}
          options={quarterlyResultsGraphData.Options}
        />
      </div>
      {allValuesPositive ? (
        <div className="graph">
          <Pie
            data={quarterlyResultsGraphData.PieGraphData}
            options={quarterlyResultsGraphData.Options}
          />
        </div>
      ) : (
        <div className="graph">
          <Bar
            data={quarterlyResultsGraphData.DivergingBarData}
            options={quarterlyResultsGraphData.Options}
          />
        </div>
      )}
    </>
  ) : null;
};

export default QuarterlyResults;
