import React from "react";
import { newsArticlProps } from "./newsArticle.types";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";

import NerAanalysis from "../NerAanalysis/NerAanalysis";
import NewsClasses from "../NewsClasses/NewsClasses";

import "./newsArticle.scss";

/**
 * The `NewsArticleSearch` component displays details of a news article including its summary,
 * detected events, and the actual news content. It adapts its layout based on screen width.
 *
 * This function is called in \src\Component\Search\Search.tsx
 *
 * @param {newsArticlProps} props - The component's props, including the `singleNews` object.
 * @returns {JSX.Element} The rendered `NewsArticleSearch` component.
 */
const NewsArticleSearch = ({ singleNews }: newsArticlProps) => {

  const { width }: any = useWindowDimemsions();

  const xsWidth = 576;
  const objLength = Object.keys(singleNews || {}).length;
  const heightStyle =
    width && width < xsWidth && objLength === 0
      ? { minHeight: "100px", maxHeight: "100px" }
      : width && width < xsWidth && objLength > 0
      ? { minHeight: "25vh", maxHeight: "75vh" }
      : { minHeight: "75vh", maxHeight: "75vh" };

  return (
    <div
      className={`news-article mb-2 col-md-6 ${
        width && width > 768 ? "mb-2" : "mb-4"
      }`}
    >
      <div
        className="inner-content p-4 pb-0 green-overflow-scroll"
        style={heightStyle}
      >
        <div className="insider-child">
          <div className="row m-0">
            <div className="col-md-12 p-0">
              <div className="header">
                <h6>Summary of News Article</h6>
              </div>
              <div className="background-white p-3 max-height-80">
                <p className="mint-text mb-0">{singleNews.News_Summary}</p>
              </div>
            </div>
          </div>
        </div>

        {singleNews.News_ner_org_freq && (
          <NerAanalysis data={singleNews.News_ner_org_freq} />
        )}

        {singleNews.News_Classes && (
          <NewsClasses data={singleNews.News_Classes} />
        )}

        <div className="insider-child">
          <div className="header mt-3 mb-3">
            <h6>Events Detected in News Article</h6>
          </div>
          <div className="background-white p-3 ">
            {singleNews.Events_Detected &&
            singleNews.Events_Detected !== "Null" ? (
              <p className="mint-text mb-0">
                <ul className="mb-0">
                {/* {(singleNews.Events_Detected as []).map(
                    (event: string, index: number) => (
                      <li key={index}>{event}</li>
                    )
                  )} */}

                  {/* {singleNews.Events_Detected &&
                    (singleNews.Events_Detected as []).map((event, index) => (
                      <li key={index}>
                        {event} <b> {singleNews.Labels[index]}</b>
                      </li>
                    ))} */}
                </ul>
              </p>
            ) : (
              <p className="no-news text-center">No News</p>
            )}
          </div>
        </div>

        <div className="insider-child mb-3">
          <div className="header mt-3 mb-3">
            <h6>Actual News Article</h6>
          </div>
          <div className="background-white p-3 ">
            {singleNews.Sentiment_Analyzed_news ? (
              <p
                className="mint-text mb-0"
                // singleNews.Sentiment_Analyzed_news
                dangerouslySetInnerHTML={{ __html: singleNews.Sentiment_Analyzed_news }}
              ></p>
            ) : (
              <p className="no-news text-center">No News</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsArticleSearch;