
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import SearchBar from "../SearchBar/SearchBar";

/**
 * CardSearch Component
 * 
 * This component represents a search feature that opens a popover when the search icon is clicked.
 * It includes a search input field provided by the SearchBar component.
 * 
 * This function is called in \src\Views\NewsAggregator\AggregatorNewsTab\AggregatorNewsTab.jsx
 * This function is called in \src\Views\NewsContent\NewsContent.tsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {boolean} props.open - A boolean indicating whether the search popover is open.
 * @param {Function} props.onOpenChange - A callback function to handle changes in popover visibility.
 * @param {Array} props.data - An array of data for searching.
 * @param {Function} props.onSearchChange - A callback function to handle search query changes.
 * @param {string} props.value - The current search query value.
 * @param {string} props.placeholder - The placeholder text for the search input field.
 * @param {Function} props.onShow - A callback function to show the search popover.
 * @returns {JSX.Element} - The rendered CardSearch component.
 */
const CardSearch = ({ open, onOpenChange, data, onSearchChange, value, placeholder, onShow }) => {

    return (
        <Popover
           placement={"top" }
            content={
                <SearchBar
                    data={data}
                    onChange={onSearchChange}
                    value={value}
                    allowClearSearch={true}
                    placeholder={placeholder}
                    styled={{ background: '#FFFCFC', padding: '1px 0', borderRadius: '4px', border: `1px solid #d3d3d3` }}
                //showBorder={true}
                />
            }
            trigger="click"
            open={open}
            onOpenChange={onOpenChange}
        >
            <SearchOutlined onClick={onShow} />
        </Popover>
    );
}

export default CardSearch;