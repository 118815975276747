import "./NewsHubSection.scss";

const NewsHubSection = () => {
  return (
    <div className="newsHub-section">
      <div className="selection-section">
        <div className="selection-title">
          <h1>Your Personalized News Hub</h1>
        </div>
        <div className="selection-content">
          <div className="selection-content-item">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20M18 20C17.4696 20 16.9609 19.7893 16.5858 19.4142C16.2107 19.0391 16 18.5304 16 18V5C16 4.73478 15.8946 4.48043 15.7071 4.29289C15.5196 4.10536 15.2652 4 15 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V17C4 17.7956 4.31607 18.5587 4.87868 19.1213C5.44129 19.6839 6.20435 20 7 20H18ZM8 8H12M8 12H12M8 16H12"
                  stroke="#FE7F77"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a className="links" href="/aggregator">
              <div>
                <h3>News Aggregator</h3>
                <p>
                  Get comprehensive summaries and visualizations of stock
                  performance.
                </p>
              </div>
            </a>
          </div>
          <div className="selection-content-item">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19M14 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H12M14 3L19 8M19 8V12.5M18.5 19.5L21 22M14 17.5C14 18.163 14.2634 18.7989 14.7322 19.2678C15.2011 19.7366 15.837 20 16.5 20C17.163 20 17.7989 19.7366 18.2678 19.2678C18.7366 18.7989 19 18.163 19 17.5C19 16.837 18.7366 16.2011 18.2678 15.7322C17.7989 15.2634 17.163 15 16.5 15C15.837 15 15.2011 15.2634 14.7322 15.7322C14.2634 16.2011 14 16.837 14 17.5Z"
                  stroke="#FE7F77"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a className="links" href="/search">
              <div>
                <h3>Semantic Search</h3>
                <p>
                  Intelligent Query Understanding, Effective Research, Advanced
                  Filtering, Comprehensive Insights.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="image-section">
        <div className="selection-image">
          <div className="img1" style={{ marginBottom: "8rem" }}>
            <img src="assets/images/landingv2/CARD4.png" alt="card4" />
          </div>
        </div>
        <div className="selection-bg-img">
          <img src="assets/images/landingv2/Rock-2.png" alt="rock-img" />
        </div>
      </div>
    </div>
  );
};

export default NewsHubSection;
