import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type HeatMapData = {
  [key: string]: {
    "Outperforming Nifty": number;
    "Return Difference": number;
    "Display String": string;
  };
};

const HeatMap = () => {
  const [heatmapData, setHeatmapData] = useState<{ name: string; data: any }[]>(
    []
  );
  const [tooltipData, setTooltipData] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [colorRanges, setColorRanges] = useState<any[]>([]); // State for color ranges
  const [data, setData] = useState<HeatMapData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/analysis-fabric-files/Value Based Strategy/Value Based Strategy-grid.json`
      );
      const data = await response.json();
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      const { seriesData, tooltipInfo, minValue, maxValue } =
        transformData(data);
      setHeatmapData(seriesData);
      setTooltipData(tooltipInfo);
      updateColorRange(minValue, maxValue); // Update the color scale
    }
  }, [data, isLoading]);

  const transformData = (data: any) => {
    const heatmapMap: { [key: string]: any[] } = {};
    const tooltipInfo: { [key: string]: any[] } = {};

    let minValue = Infinity;
    let maxValue = -Infinity;

    Object.keys(data).forEach((key) => {
      const [month, year] = key.split(" ");
      const {
        "Return Difference": returnDifference,
        "Display String": displayString,
      } = data[key];

      minValue = Math.min(minValue, returnDifference);
      maxValue = Math.max(maxValue, returnDifference);

      if (!heatmapMap[year]) {
        heatmapMap[year] = Array(12).fill(null);
      }

      const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
      heatmapMap[year][monthIndex] = returnDifference;

      if (!tooltipInfo[year]) {
        tooltipInfo[year] = Array(12).fill(null);
      }
      tooltipInfo[year][monthIndex] = displayString;
    });

    const seriesData = Object.keys(heatmapMap).map((year) => ({
      name: year,
      data: heatmapMap[year].map((value, index) => ({
        x: new Date(2020, index, 1).toLocaleString("default", {
          month: "short",
        }),
        y: value,
      })),
    }));

    return { seriesData, tooltipInfo, minValue, maxValue };
  };

  const updateColorRange = (minValue: number, maxValue: number) => {
    console.log(" View Min Value:", minValue, "Max Value:", maxValue);
    const newColorRanges = [
      {
        from: minValue,
        to: 0,
        name: "Underperformed",
        color: "#ff5e5e", // Dark red
      },
      {
        from: 0.01,
        to: maxValue,
        name: "Outperformed",
        color: "#059212", // Dark green
      },
      {
        from: null,
        to: null,
        name: "No Data",
        color: "#FFFFFF", // White for no data
      },
    ];
    setColorRanges(newColorRanges); // Update the color ranges in state
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#278E03"], // Default color, but will be overridden by colorScale
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.6,
        radius: 0,
        reverseNegativeShade: true,
        useFillColorAsStroke: false,
        colorScale: {
          inverse: true,
          ranges: colorRanges, // Dynamically set the color scale ranges
        },
      },
    },
    xaxis: {
      type: "category",
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const year = Object.keys(tooltipData)[seriesIndex];
          if (tooltipData[year]) {
            const displayString = tooltipData[year][dataPointIndex];
            if (displayString) {
              return displayString;
            }
          }
          return value !== null && value !== undefined
            ? value.toString()
            : "N/A";
        },
      },
    },
  };

  return (
    <div>
      <ApexCharts
        options={options}
        series={heatmapData}
        type="heatmap"
        height={350}
      />
    </div>
  );
};

export default HeatMap;
