import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import AggregatorNewsTab from "../NewsAggregator/AggregatorNewsTab/AggregatorNewsTab";
import NoResultsBox from "../Common/NoResultsBox/NoResultsBox";
import NewSourceDistChart from "../Graphs/NewsAggregator/NewSourceDistChart";
import AggTreeMapChart from "../Graphs/NewsAggregator/AggTreeMapChart";
import CandleStick from "../../Views/Graphs/NewsAggregator/CandleStick";
import HeatMapChart from "../Graphs/NewsAggregator/HeatMapChart";
import AggStackedBarLine from "../Graphs/NewsAggregator/AggStackedBarLine/AggStackedBarLine";

import { getSortOrFilteredArr, getUniqueOptions } from "../../utils/Common";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
import "react-toastify/dist/ReactToastify.css";
import "../../Component/NewsAggregator/NewsAggregator.scss";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { subDays } from "date-fns";
import {
  filterAndCombineNewsSourceDist,
  filterFrequentEntities,
  filterHeatMapData,
  filterNewsTabData,
  filterStackBarLine,
  filterCandleStickData ,
  getDatesBetweenDates
} from "../../utils/NewAgrregator";

const toastSettings = {
  position: "top-center",
  autoClose: 200,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
};

const NewsAggregatorV2 = ({ comparisonData }) => {
  const { ticker } = useParams();

  const [selectedCompany, setSelectedCompany] = useState(ticker); //default value

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const [allData, setAllData] = useState({});

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [sortValue, setSortValue] = useState("");
  const [optionsData, setOptionsData] = useState([]);

  const [newsTabData, setNewsTabData] = useState([]);
  const [newsTabOriginalData, setNewsTabOriginalData] = useState([]);

  const [candleStickData, setCandleStickData] = useState([]);
  const [newsSentimentVsVolume, setNewsSentimentVsVolume] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [newsSourceDist, setNewsSourceDist] = useState({});
  const [mostFrequentEntities, setMostFrequentEntities] = useState([]);

  useEffect(() => {
    setSelectedCompany(ticker);
  }, [ticker]); // Re-run effect when company changes

  useEffect(() => {
    populateOptions(newsTabOriginalData); //reset
    setSortValue(""); //reset
    setSearchValue(null); //reset
  }, [newsTabOriginalData]);

  useEffect(() => {
    const result = getSortOrFilteredArr(
      newsTabOriginalData,
      searchValue,
      sortValue,
      "news_source",
      "news_date",
      "News_Sentiment"
    ); //"News_Sentiment" currently not present in data
    setNewsTabData(result);
  }, [searchValue, sortValue, newsTabOriginalData]);

  const populateOptions = (data) => {
    const result = getUniqueOptions(data, "news_source");
    setOptionsData(result);
  };

  const handleSortByScore = (event) => {
    setSortValue(event);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleSearchChange = (value) => {
    if (value) {
      setSearchValue(value);
    } else {
      setSearchValue(null);
    }

    setOpen(false);
  };

  const handleShow = () => {
    setOpen(true);
  };
 
  // const startDate = new Date('2024-09-13');
  // const endDate = new Date('2024-09-23');
  // const dateRange = getDatesBetweenDates(startDate, endDate);
  // console.log("dateRange", dateRange);

  const fetchData = async () => {
    handleResetState();

    try {
      setIsLoaderVisible(true);
      const allPossibleData = comparisonData["create_visualizations"];

      setAllData(allPossibleData);
      console.log("all possible data -",allPossibleData);

      const aggNewTabData = allPossibleData.news_articles || {};
      // console.log(aggNewTabData);
      const filteredNewTabData = filterNewsTabData(aggNewTabData);
      setNewsTabData(() => filteredNewTabData);
      setNewsTabOriginalData(() => [...filteredNewTabData]);

      const barGraphData = allPossibleData.sentiment_stacked_barchart || {};
      const lineGraphData = allPossibleData.news_distrib_across_weeks || {};
      // Merge objects by key
      const stackedBarLine = filterStackBarLine(barGraphData, lineGraphData);
      setNewsSentimentVsVolume(() => stackedBarLine);

      const heatMap = allPossibleData.event_label_heatmap_weekly || {};
      setHeatMapData(() => filterHeatMapData(heatMap)); //filterHeatMapData returns array
      
      const candleStick = allPossibleData.Stock_data || {};
      console.log("------candlestick data-", candleStick);
      setCandleStickData(() => filterCandleStickData(candleStick));
      const newsSourceDistribution =
        allPossibleData.news_source_distrib_weekly || {};
      setNewsSourceDist(() =>
        filterAndCombineNewsSourceDist(newsSourceDistribution)
      );

      const mostFrequentEntities = allPossibleData.ner_org_freq_weekly || {};
      const entityData = filterFrequentEntities(mostFrequentEntities);
      setMostFrequentEntities(entityData);

      toast.success("Success", toastSettings);
    } catch (err) {
      toast.error("No news articles found", toastSettings);
    } finally {
      setIsLoaderVisible(false);
    }
  };

  const handleResetState = () => {
    setNewsTabData([]);
    setNewsTabOriginalData([]);
    setCandleStickData([]);
    setNewsSentimentVsVolume([]);
    setHeatMapData([]);
    setNewsSourceDist({});
    setMostFrequentEntities([]);
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount and when selectedCompany changes
  }, []);

  // useEffect(() => {
  //   if (comparisonData) {
  //     setAllData(comparisonData["create_visualizations"]);
  //   }}, [comparisonData]);

  const { width: screenWidth } = useWindowDimemsions();

  return (
    <>
      <Spin spinning={isLoaderVisible}>
        <section style={{ margin: "1rem 0" }}>
          <div className="container-fluid">
            <div>
              {Object.keys(allData || {})?.length > 0 ? (
                <>
                  <div className="row mx-auto">
                    <div className="col-xl-8">
                      <div className="row mb-2">
                        <AggStackedBarLine data={newsSentimentVsVolume} />

                        <NewSourceDistChart
                          //  key={"one"}
                          data={newsSourceDist}
                        />
                      </div>

                      <div className="row  mb-2">
                        <AggTreeMapChart
                          // key={"two"}
                          data={mostFrequentEntities}
                        />
                      <CandleStick data={candleStickData} />
                      </div>

                      <div className="row  mb-2">
                        <HeatMapChart
                          data={heatMapData}
                          screenWidth={screenWidth}
                        />
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <AggregatorNewsTab
                        data={newsTabData}
                        open={open}
                        onOpenChange={handleOpenChange}
                        onShow={handleShow}
                        optionsData={optionsData}
                        onSearchChange={handleSearchChange}
                        searchValue={searchValue}
                        onsortByScore={handleSortByScore}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className=" mb-4 row "
                  style={{
                    background: "#fff",
                    borderRadius: "5px",
                    padding: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                  }}
                >
                  <div className="col">
                    <div
                      style={{
                        minHeight: "65svh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NoResultsBox
                        displayText={
                          isLoaderVisible ? `searching...` : `Select Company`
                        }
                        showEmptyImage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            :
          </div>
        </section>
      </Spin>

      <ToastContainer />
    </>
  );
};

export default NewsAggregatorV2;
