

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerr.scss';
import { subDays  } from 'date-fns'



const DateRangePickerr = ({ value, onChange, placeholder, format, allowClear, minDate, maxDate }) => {

  // const isWeekday = (date) => {
  //   const day = getDay(date);
  //   return day !== 0 && day !== 6;
  // };

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat={format}
      minDate={minDate}
      maxDate={maxDate}
      isClearable={allowClear}
      placeholderText={placeholder}
    // filterDate={isWeekday} //disable sat-sun
    />
  );
}

export default DateRangePickerr;





DateRangePickerr.defaultProps = {
  placeholder: "Select Date",
  format: "yyyy/MM/dd",
  allowClear: true,
  minDate: subDays(new Date(), 365), //default used by news aggregator
  maxDate:new Date(), //default used by news aggregator
};



//Range Month Picker for one month picker
//dateFormat
//isClearable
//Multiple months inline
//Specific date range
//Exclude dates
//Include dates


// React Datepicker
// Crafted by HackerOne
