import { ReactNode, useEffect, useState } from "react";
import { AuthContext, UserInfo } from "../../context/AuthContext";

type PersistAuthType = {
  // userInfo: UserInfo | null;
  children?: ReactNode;
};

const PersistAuth = (props: PersistAuthType) => {
  // Auth state
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const setUserInfoWithLocalStorage = (userInfo: UserInfo | null) => {
    // TODO: Check expiry
    if (userInfo === null) {
      localStorage.removeItem("userInfo");
      setUserInfo(null);
      return;
    }
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...userInfo,
        // FIXME: Env variable for expiry
        expireAt:
          Date.now() + parseInt(process.env.REACT_JWT_EXPIRY || "43200000"),
      })
    );
    setUserInfo(userInfo);
  };

  // console.log(userInfo);

  // Persist auth state with local storage
  useEffect(() => {
    if (!userInfo) {
      const localUserInfowithExpiry = localStorage.getItem("userInfo");
      if (localUserInfowithExpiry) {
        const { expireAt, ...localUserInfo } = JSON.parse(
          localUserInfowithExpiry
        );
        if (Date.now() > expireAt) {
          localStorage.removeItem("userInfo");
          setUserInfo(null);
          return;
        }
        setUserInfoWithLocalStorage(localUserInfo);
      }
    }
  }, [userInfo]);

  return (
    <>
      <AuthContext.Provider
        value={{
          userInfo,
          setUserInfo: setUserInfoWithLocalStorage,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </>
  );
};

export default PersistAuth;
