/**
 * Action creator for navigating the root menu.
 *
 * This function creates an action object for navigating the root menu and sends it to the reducer.
 *
 * This function is called in \src\Views\Dashboard\DashBoardTable\DashBoardTable.jsx
 * 
 * @param {any} data - The data payload associated with the action.
 * @returns {Object} - An action object with type "NAVIGATE_ROOT_MENU" and payload.
 */
const NavigateMenu = (data: any) => {
  return {
    type: "NAVIGATE_ROOT_MENU",
    payload: data,
  };
};

export default NavigateMenu;
