const tickerNamesConstant = [
  "Asian Paints Ltd",
  "Tata Motors Limited",
  "Britannia Industries Ltd",
  "Apollo Hospitals Enterprise Ltd",
  "Coal India Ltd",
  "ICICI Bank Ltd",
  "HDFC Bank Ltd",
  "Maruti Suzuki Ltd",
  "Sun Pharmaceutical Industries Ltd",
  "Bajaj Auto Ltd",
  "Reliance Industries Ltd",
  "Schaeffler India Ltd",
  "Hindustan Unilever Ltd",
  "Eicher Motors Ltd",
  "Adani Ports and Special Economic Zone Ltd",
  "Hindustan Aeronautics Ltd",
  "Dr. Reddy's Laboratories Ltd",
  "HCL Ltd",
  "Adani Enterprises Ltd",
  "Persistent Systems Ltd",
  "Power Grid Corporation of India Ltd",
  "Grasim Industries Ltd",
  "Tata Consumer Ltd",
  "Oil & Natural Gas Corporation Ltd",
  "Larsen & Toubro Ltd",
  "ITC Ltd",
  "Axis Bank Ltd",
  "Infosys Ltd",
  "KPIT Technologies Ltd",
  "JSW Steel Ltd",
  "Titan Company Ltd",
  "Hindalco Industries Ltd",
  "Nestle India Ltd",
  "Tata Consultancy Services Ltd",
  "Bharti Airtel Ltd",
  "Bajaj Finance Ltd",
  "Cipla Ltd",
  "Solar Industries India Ltd",
  "UPL Ltd",
  "Divi's Laboratories Ltd",
  "Tube Investments of India Ltd",
  "Mahindra & Mahindra Limited",
  "UltraTech Cement Ltd",
  "Max India Ltd",
  "Kotak Mahindra Bank Ltd",
  "Bajaj Finserv Ltd",
  "Bharat Petroleum Corporation Ltd",
  "Wipro Ltd",
  "HDFC Life Insurance Company Ltd",
  "Tata Steel Ltd",
  "Tech Mahindra Ltd",
  "IndusInd Bank Ltd",
  "State Bank of India",
  "NTPC Ltd",
  "Hero Motocorp Ltd",
  "SBI Life Insurance Company Ltd",
];
export default tickerNamesConstant;
