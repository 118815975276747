

//only month quarter labels , reading months from first object in array for summary, promoters, public
//all obejcts in array of objects have same propertys always so keys will always be same for all objects
/**
 * This function takes a date in the format 'MMM YYYY' (e.g., 'DEC 2021') and returns a JavaScript Date object.
 *
 * @param {string} dated - The date in 'MMM YYYY' format.
 * @returns {Date} - A JavaScript Date object.
 */
const getFormattedDate = (dated) => { //e.g dated = 'DEC 2021'
  //const str = 'DEC 2021';
  // let  words = str.split(' ');
  let words = dated.split(' ');
  const year = words[1];
  let month = words[0];

  month = month === "JAN" ? "0" :
    month === "FEB" ? "1" :
      month === "MAR" ? "2" :
        month === "APR" ? "3" :
          month === "MAY" ? "4" :
            month === "JUN" ? "5" :
              month === "JUL" ? "6" :
                month === "AUG" ? "7" :
                  month === "SEP" ? "8" :
                    month === "OCT" ? "9" :
                      month === "NOV" ? "10" :
                        "11";  //"DEC" 

  return new Date(year, month);
}


export const getKeys = (data, mode) => {

  let labelsArr = [];

  if (data?.length > 0) {

    if (Object.keys(data[0] || {})?.length === 0) { //only first index taken
      //ignore
    } else {
      for (const [key] of Object.entries(data[0])) {
        if (key.length < 10) { labelsArr.push(key); } //remove Category of shareholder and get labels from first object in array
      }
    }


    //sort based on date
    // console.log('before  --  sorted', labelsArr);
    // labelsArr = [...labelsArr].sort((a, b) => (new Date(a) > new Date(b)) ? 1 :  ((new Date(b) > new Date(a)) ? -1 : 0)); //not working on safari/iphone/apple products

    labelsArr = [...labelsArr].sort((a, b) => { //works on safari/iphone
      let DateA = getFormattedDate(a);
      let DateB = getFormattedDate(b);
      if (DateA > DateB) {
        return 1;
      } else if (DateB > DateA) {
        return -1;
      } else {
        return 0;
      }
    })
    //console.log('sorted', labelsArr);

    //neel changed backend --> now backend sends only last 5 months
    //last 5 quarters/months labels
    if (mode === 'promoterTable') {
      return labelsArr?.length > 5 ? labelsArr.slice(-5) : labelsArr; // >4 since starting from 0
    }

    //neel changed backend --> now backend sends only last 5 months
    //last 5 quarters/months labels
    if (mode === 'publicTable') {
      return labelsArr?.length > 5 ? labelsArr.slice(-5) : labelsArr; // >4 since starting from 0
    }


    //last 4 quarters/months labels
    if (mode === 'StackedBarChart') {
      return labelsArr?.length > 4 ? labelsArr.slice(-4) : labelsArr; // >4 since starting from 0
    }

    //last 1 quarter/month labels
    if (mode === 'DoughnutComp') {
      return labelsArr?.length > 1 ? labelsArr.slice(-1) : labelsArr; // >1 since starting from 0, last 1 quater  

    }

    //last 1 quarter/month labels
    if (mode === 'PieChart') {
      return labelsArr?.length > 1 ? labelsArr.slice(-1) : labelsArr; // >1 since starting from 0, last 1 quater  
    }

  }

  else { //empty 'data' arr
    return labelsArr;
  }

}

//respective graph name labels array 
export const getValues = (data) => {
  const labelsArr = [];

  if (data?.length > 0) {
    data.forEach((object, index) => {
      if (Object.keys(object || {})?.length === 0) {
        //ignore
      } else {
        for (const [key, value] of Object.entries(object)) {
          if (String(key)?.includes('Category') || String(key)?.includes('Name of Shareholder')) { labelsArr.push(value); } //get Category of shareholder for summary, Name of Shareholder for public, promoter
        }
      }


    });
  }
  //console.log('getValues', labelsArr, Object.keys({}));
  return labelsArr;
}

//sum of remaing elements, all values less than max,
export const getRestSum = (data, label) => {

  let arrayOfValues = [];
  let sum = 0;

  if (label?.length > 0) {
    arrayOfValues = data.map(object => Number(object[label]));  // object[label] should always be a number or number in string format 
  }

  if (arrayOfValues?.length > 0) {
    for (let i in arrayOfValues) {
      sum += arrayOfValues[i]; //sum of values
    }
  }

  return sum;

}

//[`Name of Shareholder`, 'DEC 2022', 'MAR 2021, ...]
export const getTableHeaderData = (data) => {
  const arr = data?.length > 0 ? [`Name of Shareholder`, ...data] : [];
  return arr;
}

//[`Name of Shareholder`, 'DEC 2022', 'MAR 2021, ...,'Nationality']
export const getTableHeaderDataPromoter = (data) => {
  const arr = data?.length > 0 ? [`Name of Shareholder`, ...data, 'Nationality'] : [];
  return arr;
}

//sorts the array of objects by property name in ascending or descending
export const sortTable = (data, property, sortFilter) => {

  if (data?.length > 0 && property && sortFilter) {
    const order = sortFilter === 'desc' ? -1 : 1;
    //  console.log(property, sortFilter, order);
    return ([...data].sort((a, b) => (a[property] > b[property]) ? order : ((b[property] > a[property]) ? -order : 0)));
    //sort array in same `property` order;
  } else {
    return [];
  }

}

//get types like 'public', 'Promoter & Promoter Group'
export const getGraphLabels = (data) => {

  return data?.length > 0 ?
    getValues(data).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)) : []; //sort array in same `Category of shareholder` order

}

export const getGraphDataArr = (data, property, month) => {
  return data?.length > 0 ?
    [...data]  //spread needed else state directly modified
      .sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0))   //sort array in same `Category of shareholder` order
      .map((object, index) => object[month]) : [];
}


//check all array elemnt have value 0
export const checkAllValuesAreZero = (arr) => {

  return [...arr].every((currentValue) => Number(currentValue) === 0) ? true : false;

}
