import { strategyGraphData, FilterData } from "./NewStatergy";
import { valueGraphData } from "../ValueStatergy/ValueStatergy";

interface BacktestingTableProps {
  statergyGraphData?: strategyGraphData | null;
  valueGraphData?: valueGraphData | null;
}

const BacktestingTable = ({
  statergyGraphData,
  valueGraphData,
}: BacktestingTableProps) => {
  const getFilteredData = () => {
    // Attempt to retrieve filterData from both datasets, prioritizing strategyGraphData if available
    const filterData: FilterData | undefined =
      statergyGraphData?.["Filter 3 6STOCKS MaxSharpe"] ||
      valueGraphData?.["Filter 3"];

    if (
      !filterData ||
      !filterData["Normalized Price Over Time STOCKS"] ||
      !filterData["Normalized Price Over Time INDICES"]
    ) {
      return null;
    }

    const ourStocksParameters: Record<string, unknown> =
      filterData?.["Our Stocks Parameters"] || {};
    const niftybeesParameters: Record<string, unknown> =
      filterData?.["NIFTYBEES Parameters"] || {};

    const mergedParameters = { ...ourStocksParameters, ...niftybeesParameters };

    return Object.keys(mergedParameters).map((key) => {
      return {
        parameter: key,
        ourStocksValue: ourStocksParameters[key],
        niftybeesValue: niftybeesParameters[key],
      };
    });
  };
  const tableData = getFilteredData();
  // console.log(tableData);
  return (
    <div className="backtesting">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Momentum</th>
            <th>Nifty</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((data) => (
            <tr key={data.parameter}>
              <td>{data.parameter}</td>
              <td>{data.ourStocksValue}</td>
              <td>{data.niftybeesValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BacktestingTable;
