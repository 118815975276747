import React from 'react';
import { SendOutlined } from '@ant-design/icons';

/**
 * ChatInput Component
 * 
 * This component represents the input field for typing and sending chat messages.
 * It includes an input field for typing messages and a send button.
 * 
 * This function is called in \src\Views\ChatBot\CustomPopover\CustomPopover.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.textMessage - The text message being typed by the user.
 * @param {Function} props.onMessageChange - A callback function to handle message text changes.
 * @param {Function} props.onMessageSubmit - A callback function to handle message submission.
 * @returns {JSX.Element} - The rendered chat input component.
 */
const ChatInput = ({ textMessage, onMessageChange, onMessageSubmit }) => {
    return (
        <form onSubmit={onMessageSubmit}>
            <div className="chat-input">

                <input
                    value={textMessage}
                    onChange={(event) => onMessageChange(event.target.value)}
                    type="text"
                    placeholder="Type your message here"
                />
                <button
                    type='submit'
                    disabled={textMessage && textMessage?.length > 0 ? false : true} //disable input submit if no message typed by user
                >
                    <SendOutlined className='send-Msg-Button' />
                </button>
            </div>

        </form>

    );
}

export default ChatInput;