import { combineReducers } from "@reduxjs/toolkit";
import NewsListData from "./NewsListData";
import TweetsListData from "./TweetsListData";
import IdentifyNews from "./IdentifyNews";
import IdentifyTweets from "./IdentifyTweets";
import { NewsItem, TweetItem } from "./NewsAndTweetItem";
import FilterSummary from "./FilterSummary";
import NavigateMenu from "./NavigateMenu";
import ShowPdf from "./ShowPdf";
import user_authenication from "./user_authenication";
import IdentifyContent from "./IdentifyContent";
/**
 * Combine multiple reducers using Redux's combineReducers function.
 * This creates a single root reducer that manages different parts of the application's state.
 * 
 * This function is called in \src\Store.ts
 * 
 */
const RootReducer = combineReducers({
  NewsListData: NewsListData,
  TweetsListData: TweetsListData,
  IdentifyNews: IdentifyNews,
  IdentifyTweets: IdentifyTweets,
  IdentifyNewsContent: IdentifyContent,
  NewsItem: NewsItem,
  TweetItem: TweetItem,
  FilterSummary: FilterSummary,
  NavigateMenu: NavigateMenu,
  ShowPdf: ShowPdf,
  user_authentication: user_authenication
});
export default RootReducer;
