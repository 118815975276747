import React from "react";
import "./newsArticle.scss";
import { newsArticlProps } from "./newsArticle.types";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
import NewsClasses from "../NewsClasses/NewsClasses";
import NerAanalysis from "../NerAanalysis/NerAanalysis";

// const NewsArticle = ({ singleNews }: newsArticlProps) => {
//   return (
//     <div className="news-article mb-2 col-md-6">
//       <div className="inner-content p-4 pb-0 green-overflow-scroll">

//         {(singleNews.News_Summary.length != 0) &&
//           <div className="insider-child">
//               <div className="row m-0">
//                 <div className="col-md-12 p-0">
//                   <div className="header">
//                     <h6>Summary of News Article</h6>
//                   </div>
//                   <div className="background-white p-3 max-height-80">
//                     <p className="mint-text mb-0">{singleNews.News_Summary}</p>
//                   </div>
//                 </div>
//               </div>
//           </div>
//         }

//         {(singleNews.Events_Detected.length != 0) &&
//           <div className="insider-child">
//             <div className="header mt-3 mb-3">
//               <h6>Events Detected in News Article</h6>
//             </div>
//             <div className="background-white p-3 ">
//               <p className="mint-text mb-0">
//                 <ul className="mb-0">
//                   {singleNews.Events_Detected &&
//                     (singleNews.Events_Detected as []).map((event, index) => (
//                       <li key={index}>{event}</li>
//                     ))}
//                 </ul>
//               </p>
//             </div>
//           </div>
//         }

//         <div className="insider-child mb-3">
//           <div className="header mt-3 mb-3">
//             <h6>Actual News Article</h6>
//           </div>

//           <div className="background-white p-3 ">
//             {singleNews.Actual_News && (
//               <p
//                 className="mint-text mb-0"
//                 // singleNews.Actual_News
//                 dangerouslySetInnerHTML={{ __html: singleNews.Actual_News }}
//               ></p>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
/**
 * NewsArticle Component.
 *
 * This component displays detailed information about a news article, including its summary,
 * detected events, actual content, and a link to the full article.
 *
 * This function in called in \src\Views\News\News.tsx
 * 
 * @param {Object} props - The props containing singleNews information.
 * @returns {JSX.Element} - A React JSX element representing the NewsArticle component.
 */
const NewsArticle = ({ singleNews }: newsArticlProps) => {
  const newsURL = singleNews["ID"] && singleNews["ID"].split("+")[2].trim();

  const { width }: any = useWindowDimemsions();


  const xsWidth = 576;
  const objLength = Object.keys(singleNews || {}).length;
  const heightStyle = width && width < xsWidth && objLength === 0 ? { minHeight: '100px', maxHeight: '100px' } :
    width && width < xsWidth && objLength > 0 ? { minHeight: '25vh', maxHeight: '75vh' } :
      { minHeight: '75vh', maxHeight: '75vh' };

  //console.log("--summary-singleNews-", objLength);
  console.log("--summary-singleNews-", singleNews);

  if (singleNews.News_Summary == "".length && singleNews.Events_Detected == [].length) {
    return (
      <div className={`news-article  col-md-6 ${width && width > 768 ? "mb-2" : "mb-4"}`}>
        <div className="inner-content p-4 pb-0 green-overflow-scroll" style={heightStyle}>
          <div className="insider-child">
            <div className="row m-0"></div>
          </div>
          <div className="insider-child mb-3">
            <div className="header mt-3 mb-3">
              <h6>
                Actual News Article
              </h6>
            </div>

            <div className="background-white p-3 ">
              {singleNews.Actual_News ? (
                <p
                  className="mint-text mb-0"
                  // singleNews.Actual_News
                  dangerouslySetInnerHTML={{ __html: singleNews.Actual_News }}
                ></p>
              )
                :
                <p className="no-news text-center" >No News</p>

              }
            </div>



            <div className="background-white p-3 ">
              <p className="mint-text mb-0">
                <h6 >News Link:<a
                  target="_blank"
                  rel="noreferrer"
                  href={newsURL}>{singleNews["News_Title"]}</a></h6>
              </p>
            </div>
          </div>

          {singleNews.News_ner_org_freq && <NerAanalysis data={singleNews.News_ner_org_freq} />}

          {singleNews.News_Classes && <NewsClasses data={singleNews.News_Classes} />}

        </div>
      </div>
    );
  } else if (singleNews.Events_Detected == "".length) {
    return (
      <div className={`news-article col-md-6  ${width && width > 768 ? "mb-2" : "mb-4"}`}>
        <div className="inner-content p-4 pb-0 green-overflow-scroll " style={heightStyle}>
          <div className="insider-child">
            <div className="row m-0">
              <div className="col-md-12 p-0">
                <div className="header">
                  <h6>
                    Summary of News Article
                  </h6>
                </div>
                <div className="background-white p-3 max-height-80">
                  {singleNews.News_Summary ?
                    <p className="mint-text mb-0">{singleNews.News_Summary}</p> :
                    <p className="no-news text-center" >No News</p>
                  }
                </div>
              </div>
            </div>
          </div>

          {singleNews.News_ner_org_freq &&  <NerAanalysis data={singleNews.News_ner_org_freq} />}

          {singleNews.News_Classes && <NewsClasses data={singleNews.News_Classes} />}


          <div className="insider-child mb-3">
            <div className="header mt-3 mb-3">
              <h6>
                Actual News Article
              </h6>
            </div>
            <div className="background-white p-3 ">
              {singleNews.Actual_News ? (
                <p
                  className="mint-text mb-0"
                  // singleNews.Actual_News
                  dangerouslySetInnerHTML={{ __html: singleNews.Actual_News }}
                ></p>
              ) :
                <p className="no-news text-center" >No News</p>
              }
            </div>
            <div className="background-white p-3 ">
              <p className="mint-text mb-0">
                <h6>News Link: <a
                  target="_blank"
                  rel="noreferrer"
                  href={newsURL}>{singleNews["News_Title"]}</a>
                </h6>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (singleNews.News_Summary == "".length) {
    return (
      <div className={`news-article  col-md-6 ${width && width > 768 ? "mb-2" : "mb-4"}`}>
        <div className="inner-content p-5 pb-0 green-overflow-scroll" style={heightStyle}>
          <div className="insider-child">
            <div className="row m-0"></div>
          </div>

          {singleNews.News_ner_org_freq && <NerAanalysis data={singleNews.News_ner_org_freq} />}

          {singleNews.News_Classes && <NewsClasses data={singleNews.News_Classes} />}


          <div className="insider-child">
            <div className="header mt-3 mb-3">
              <h6>
                Events Detected in News Article
              </h6>
            </div>
            <div className="background-white p-3 ">
              {singleNews.Events_Detected && singleNews.Events_Detected?.length > 0 ?
                <p className="mint-text mb-0">
                  <ul className="mb-0" >
                    {/* {singleNews.Events_Detected &&
                    (singleNews.Events_Detected as []).map((event, index) => (
                      <li key={index}>{event}</li>
                    ))} */}

                    {singleNews.Events_Detected &&
                      (singleNews.Events_Detected as []).map((event, index) => (
                        <li key={index} >
                          {event} <b> {singleNews.Labels[index]}</b>
                        </li>
                      ))}
                  </ul>
                </p> :
                <p className="no-news text-center" >No News</p>
              }
            </div>
          </div>

          <div className="insider-child mb-3">
            <div className="header mt-3 mb-3">
              <h6>
                Actual News Article
              </h6>
            </div>
            <div className="background-white p-3 ">
              {singleNews.Actual_News ? (
                <p
                  className="mint-text mb-0"
                  // singleNews.Actual_News
                  dangerouslySetInnerHTML={{ __html: singleNews.Actual_News }}
                ></p>
              ) : <p className="no-news text-center" >No News</p>}
            </div>
            <div className="background-white p-3 ">
              <p className="mint-text mb-0">
                <h6 >News Link:<a
                  target="_blank"
                  rel="noreferrer"
                  href={newsURL}>{singleNews["News_Title"]}</a>
                </h6>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`news-article col-md-6 ${width && width > 768 ? "mb-2" : "mb-4"}`}>
        <div className="inner-content p-5 pb-0 green-overflow-scroll" style={heightStyle}>
          <div className="insider-child">
            <div className="row m-0">
              <div className="col-md-12 p-0">
                <div className="header">
                  <h6 >
                    Summary of News Article
                  </h6>
                </div>
                <div className="background-white p-3 max-height-80">
                  {singleNews.News_Summary ?
                    <p className="mint-text mb-0">{singleNews.News_Summary}</p> :
                    <p className="no-news text-center">No News</p>}
                </div>
              </div>
            </div>
          </div>

          {singleNews.News_ner_org_freq && <NerAanalysis data={singleNews.News_ner_org_freq} />}

          {singleNews.News_Classes && <NewsClasses data={singleNews.News_Classes} />}


          <div className="insider-child">
            <div className="header mt-3 mb-3">
              <h6>
                Events Detected in News Article
              </h6>
            </div>
            <div className="background-white p-3 ">
              {singleNews.Events_Detected && singleNews.Events_Detected?.length > 0 ?
                <p className="mint-text mb-0">
                  <ul className="mb-0" >
                    {/* {singleNews.Events_Detected &&
                    (singleNews.Events_Detected as []).map((event, index) => (
                      <li key={index}>{event}</li>
                    ))} */}

                    {singleNews.Events_Detected &&
                      (singleNews.Events_Detected as []).map((event, index) => (
                        <li key={index}>
                          {event} <b> {singleNews.Labels[index]}</b>
                        </li>
                      ))}

                    {/* {singleNews.Events_Detected &&
                    (singleNews.Events_Detected as []).map((event, index) =>
                      singleNews.Labels ? (
                        <li key={index}>
                          {singleNews.Labels[index] &&
                          singleNews.Labels[index].includes("unknown") ? (
                            <li key={index}>
                              {event}
                              <b>
                                {singleNews.Labels[index].replace(
                                  /unknown/,
                                  "Other"
                                )}
                              </b>
                            </li>
                          ) : (
                            <li key={index}>
                              {event} <b> {singleNews.Labels[index]}</b>
                            </li>
                          )}
                        </li>
                      ) : (
                        <li key={index}>{event}</li>
                      )
                    )} */}
                  </ul>
                </p> :
                <p className="no-news text-center">No News</p>
              }
            </div>
          </div>

          <div className="insider-child mb-3">
            <div className="header mt-3 mb-3">
              <h6>
                Actual News Article
              </h6>
            </div>
            <div className="background-white p-3 ">
              {singleNews.Actual_News ? (
                <p
                  className="mint-text mb-0"
                  // singleNews.Actual_News
                  dangerouslySetInnerHTML={{ __html: singleNews.Actual_News }}
                ></p>
              ) : <p className="no-news text-center">No News</p>}
            </div>


            {newsURL &&
              <div className="background-white p-3 ">
                <p className="mint-text mb-0">
                  <h6 >News Link:<a
                    target="_blank"
                    rel="noreferrer"
                    href={newsURL}>{singleNews["News_Title"]}</a></h6>
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
};

export default NewsArticle;
