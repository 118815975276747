import React from "react";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
import "./analyzedTweet.scss";
import { anlyzedTweetProps } from "./analyzedTweet.types";
/**
 * The `AnalyzedTweetSearch` component displays details of an analyzed tweet, including
 * detected events and the actual tweet content. It adapts its layout based on screen width.
 *
 * This function is called in \src\Component\Search\Search.tsx
 *
 * @param {anlyzedTweetProps} props - The component's props, including the `singleTweet` object.
 * @returns {JSX.Element} The rendered `AnalyzedTweetSearch` component.
 */
const AnalyzedTweetSearch = ({ singleTweet }: anlyzedTweetProps) => {

  const { width }:any = useWindowDimemsions();
  
  const xsWidth = 576;
  const objLength = Object.keys(singleTweet || {}).length;
  const heightStyle = width && width < xsWidth && objLength > 0 ? { maxHeight: '75vh' } :
    { minHeight: '75vh', maxHeight: '75vh' };

  return (
    <div className={`analyzed-tweet mb-2 col-md-6   ${width && width > 768 ? "mb-2": "mb-4" }` }>
      {(Object.keys(singleTweet).length != 0) && (
        <div className="inner-content p-4 pb-0 green-overflow-scroll" style={heightStyle}>
          {(Object.keys(singleTweet.Events_Detected).length != 0) && (
            <div className="insider-child">
              <div className="header mt-3 mb-3">
                <h6 >Events Detected in Tweet</h6>
              </div>
              <div className="background-white p-3">
                {singleTweet.Events_Detected && singleTweet.Events_Detected !== "Null" ?
                  <p className="mint-text mb-0">
                    <ul className="mb-0">
                      {singleTweet.Events_Detected && singleTweet.Events_Detected !== "Null" &&
                        (singleTweet.Events_Detected as []).map((event: any, index: any) => (

                          <li key={index}>{event.text}</li>
                        ))}

                      {/* {singleTweet.Events_Detected &&
                    (singleTweet.Events_Detected as []).map((event, index) => (
                      <li key={index}>
                        {event} <b> {singleTweet.Labels[index]}</b>
                      </li>
                    ))} */}
                    </ul>
                  </p>
                  : <p className="no-tweets text-center">No Data</p>
                }
              </div>
            </div>
          )
          }

          <div className="insider-child">
            <div className="header mt-3 mb-3">
              <h6 >Actual Tweet</h6>
            </div>

            <div className="background-white p-3 ">

              {/* {singleTweet.Actual_Tweet ? */}
              {singleTweet.Actual_Tweets ?

                <p
                  className="mint-text mb-0"
                  dangerouslySetInnerHTML={{
                    // __html: singleTweet.Actual_Tweet,
                    __html: singleTweet.Actual_Tweets,

                  }}
                />
                :
                <p className="no-tweets text-center">No Data</p>
              }
            </div>
          </div>
        </div>
      )
      }
    </div>
  );
};

export default AnalyzedTweetSearch;
