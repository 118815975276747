//show last 4 quarters 

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getKeys, getValues } from '../../../../utils/CompanyDocs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Summary Current Quarter ',
    // },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
};


/**
 * A React component for displaying a stacked bar chart.
 *
 * This component takes data and generates a stacked bar chart with
 * categories on the X-axis and values on the Y-axis.
 *
 * This function is called in \src\Views\CompanyDocSummary\CompanyDocSummary.jsx
 * 
 * @param {Object} data - The data used to generate the chart.
 * @returns {JSX.Element} - A React component representing the stacked bar chart.
 */
const StackedBarChart = ({ data }) => {

  //Step 1 ---> get 4 last quarters month names
  const labels = data?.length > 0 ? getKeys(data, 'StackedBarChart') : [];

  //Step 2 ---> get graph names like 'public', 'Promoter & Promoter Group'
  const namedLabels = data?.length > 0 ?
    getValues(data).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)) : [];  //sort array in same `Category of shareholder` order

  const property = `Category of shareholder`;

  //Step 3 ---> get 4 last quarters month values and sort it
  const datasets = data?.length > 0 ?
    [...data]  //spread needed else state directly modified
      .sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0))   //sort array in same `Category of shareholder` order
      .map((object, index) => {
        return ({
          label: namedLabels[index],
          data: labels.length > 0 && labels.map(label => Number(object[label])),//values inside objects fetch according to labelname 
          backgroundColor: index === 0 ?
            //'rgb(255, 99, 132)'  
            `rgb(255, 118, 74)`
            :
            index === 1 ? 'rgb(122, 81, 149)'
              :
              'rgb(255, 166, 0)',
        });

      }
      ) : [];



  const graphData = {
    //maintainAspectRatio: false ,
    labels,
    datasets,
  };

  // console.log('bar chart', data, labels, namedLabels, datasets);

  return (
    <Bar
      options={options}
      data={graphData}
      // width={480}
      // height={230}
    />
  );
}

export default StackedBarChart;

