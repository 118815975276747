import React from "react";
import Plot from "react-plotly.js";
import { strategyGraphData, FilterData } from "./NewStatergy";
import { valueGraphData } from "../ValueStatergy/ValueStatergy";

interface BacktestingTableProps {
  statergyGraphData?: strategyGraphData | null;
  valueGraphData?: valueGraphData | null;
}

const BacktestingGraphV2 = ({
  statergyGraphData,
  valueGraphData,
}: BacktestingTableProps) => {
  const getFilteredData = (): any => {
    // Attempt to retrieve filterData from both datasets, prioritizing strategyGraphData if available
    const filterData: FilterData | undefined =
      statergyGraphData?.["Filter 3 6STOCKS MaxSharpe"] ||
      valueGraphData?.["Filter 3"];

    if (
      !filterData ||
      !filterData["Normalized Price Over Time STOCKS"] ||
      !filterData["Normalized Price Over Time INDICES"]
    ) {
      return null;
    }
    const dates = filterData?.["Backtesting Performance Our Stock"].Date || [];
    const ourStockInvestment =
      filterData?.["Backtesting Performance Our Stock"].Investment || [];
    const niftybeesInvestment =
      filterData?.["Backtesting Performance NIFTYBEES"].Investment || [];

    const uniqueMonths = Array.from(
      new Set(
        dates.map((date) => {
          const d = new Date(date);
          return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}`;
        })
      )
    );

    return {
      dates,
      datasets: [
        {
          x: dates,
          y: ourStockInvestment,
          type: "scatter",
          mode: "lines",
          name: "Strategy Stocks",
          line: {
            color: "rgba(14,112,204,1)",
          },
        },
        {
          x: dates,
          y: niftybeesInvestment,
          type: "scatter",
          mode: "lines",
          name: "NIFTYBEES",
          line: {
            color: "rgba(148,208,255,1)",
          },
        },
      ],
      uniqueMonths,
    };
  };

  const plotData = getFilteredData();

  return (
    <>
      {plotData ? (
        <Plot
          data={plotData.datasets}
          layout={{
            title: "Forward Testing Performance",
            xaxis: {
              title: "Month",
              tickformat: "%b %Y",
              tickvals: plotData.uniqueMonths,
              ticktext: plotData.uniqueMonths.map((label: string) => {
                const [year, month] = label.split("-");
                const date = new Date(parseInt(year), parseInt(month) - 1);
                const monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
              }),
            },
            yaxis: {
              title: "Value in Rs.",
            },
            legend: {
              orientation: "h",
              y: -0.2, // Position the legend further below the x-axis
            },
            margin: { l: 80, r: 20, b: 80, t: 40 }, // Adjust the bottom margin
          }}
          useResizeHandler
          style={{ width: "100%", height: "100%" }}
          config={{ displayModeBar: false }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default BacktestingGraphV2;
