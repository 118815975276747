import "./FeatureGridCard.scss";

type FeatureGridCardProps = {
  svg: React.SVGProps<SVGSVGElement>;
  title: string;
  subtitle: string;
};

const FeatureGridCard = (props: FeatureGridCardProps) => {
  return (
    <div className="feature-grid-card">
      {/* Card icon */}
      <div className="card-icon">{props.svg}</div>
      {/* Card title */}
      <h5 className="card-title">{props.title}</h5>
      {/* Card subtitle */}
      <div className="card-subtitle">{props.subtitle}</div>
    </div>
  );
};

export default FeatureGridCard;
