/**
 * Entry Point for the React Application.
 *
 * This script is the entry point for the React application. It renders the main `App` component into the HTML root element.
 * It also sets up routing using `BrowserRouter`, provides Redux store with `Provider`, and handles state persistence with `PersistGate`.
 * Performance measurement is also enabled through `reportWebVitals`.
 */
import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from "./Store";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
