import React, { useState, useEffect } from "react";
import "./newOverview.scss";
import { overviewProps } from "./overview.types";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import CircularProgress from "@mui/material/CircularProgress";

interface OverviewData {
  Query_1?: string;
  Query_2?: string;
  Query_3?: string;
  Query_4?: string;
}

type conversationMessage = {
  message: string;
  type: "user" | "bot";
};

const NewOverview = (props: overviewProps) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [data, setData] = useState<OverviewData[]>([]);
  const { stock_name } = props;
  const [inputValue, setInputValue] = useState("");
  const [conversation, setConversation] = useState<conversationMessage[]>([]); // State to store conversation
  const ticker = localStorage.getItem("tickerId");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/RAG-Query-Results/${ticker}/response.json`
      );
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const formatQuery1 = (text: string | undefined) => {
    return text ? text.replace("Summary:", "") : "No summary available";
  };

  const formatQuery2 = (text: string | undefined) => {
    if (!text) return "No sentiment analysis available";

    const lines = text.split(/[\r\n]+/).filter((line) => line.trim() !== "");
    const formattedLines = lines
      .slice(1)
      .map((line, index) => (index === 0 ? line : `• ${line.trim()}`))
      .join("\n");

    return formattedLines || "No sentiment analysis available";
  };

  const formatQuery3 = (text: string | undefined) => {
    return text
      ? text
          .replace("Key events for the company are as follows:\n\n", "")
          .replace(/[•*]/g, "")
      : "No key events available";
  };

  const formatQuery4 = (text: string | undefined) => {
    return text
      ? text
          .split("\n")
          .map(line => line.replace("Share market events: ", "").trim())
          .filter(line => line) // Remove empty lines
          .join("\n")
      : "No share market events available";
  };

  const getSentimentColor = (query2: string | undefined) => {
    if (
      query2?.split("\n")[0].includes("Bearish") ||
      (query2?.split("\n\n")[1] && query2.split("\n\n")[1].includes("Bearish"))
    ) {
      return "#FFC3C3"; // Red
      // return "#D5F3C8"; // Red
    } else if (
      query2?.split("\n")[0].includes("Bullish") ||
      (query2?.split("\n\n")[1] && query2.split("\n\n")[1].includes("Bullish"))
    ) {
      return "#b4debb"; // red
    } else if (
      query2?.split("\n")[0].includes("Neutral") ||
      (query2?.split("\n\n")[1] && query2.split("\n\n")[1].includes("Neutral"))
    ) {
      return "#FFF5B2"; // Yellow
    } else {
      return "#FFFFFF"; // Default White if no sentiment is found
    }
  };

  // // Determine if sentiment is bullish
  // const isBullish = data[1]?.Query_2?.includes("Bullish");
  // console.log(stock_name);

  // Handler for chatbot button clicks
  const handleButtonClick = (text: string) => {
    setInputValue(text);
  };

  // Fetch JSON data from API
  const fetchJsonData = async (prompt: string, ticker_name: string) => {
    const url = `${process.env.REACT_APP_OVERVIEW_CHATBOT_URL}`;
    try {
      setIsLoaderVisible(true);
      const response_from_api = await axios.post(url, {
        prompt,
        ticker_name,
      });
      // Set the API response to the state
      // setApiResponse(response_from_api.data.response);
      // Add bot message to conversation
      setConversation((prev) => [
        ...prev,
        { message: response_from_api.data.response, type: "bot" },
      ]);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsLoaderVisible(false);
    }
  };

  // Handler for key press
  // const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     e.preventDefault(); // Prevent default behavior
  //     fetchJsonData();
  //   }
  // };

  // Handler for search button click
  const handleSearchClick: React.FormEventHandler = (e) => {
    // fetchJsonData();
    e.preventDefault();

    // Add user message to conversation
    setConversation((prev) => [...prev, { message: inputValue, type: "user" }]);
    fetchJsonData(inputValue, stock_name);

    // Clear input field
    setInputValue("");
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="overview-container">
      <div className="top-content-container">
        <div className="left-content-container">
          {data.length > 0 && (
            <div className="summary-container">
              <div className="summary-title">Summary</div>
              <div
                className={`content ${isExpanded ? "show-full-content" : ""}`}
              >
                <p>{formatQuery1(data[0]?.Query_1)}</p>
              </div>
              {formatQuery1(data[0]?.Query_1).length > 100 && ( // Add "Read More" only if text exceeds a certain length
                <button onClick={handleToggle} className="read-more-button">
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              )}
            </div>
          )}
          <div
            className="sentiment-container"
            style={{ backgroundColor: getSentimentColor(data[1]?.Query_2) }}
          >
            <div className="sentiment-title">
              <div className="heading">Sentiment</div>
              <span
                className="sentiment-title"
                style={{
                  color: data[1]?.Query_2?.includes("Bearish")
                    ? "#FF0000"
                    : data[1]?.Query_2?.includes("Bullish")
                    ? "#008000"
                    : data[1]?.Query_2?.includes("Neutral")
                    ? "#FFA500"
                    : "#000000",
                  backgroundColor: data[1]?.Query_2?.includes("Bearish")
                    ? "rgba(255, 0, 0, 0.2)"
                    : data[1]?.Query_2?.includes("Bullish")
                    ? "rgba(0, 128, 0, 0.2)"
                    : data[1]?.Query_2?.includes("Neutral")
                    ? "rgba(255, 165, 0, 0.2)"
                    : "#FFFFFF",
                }}
              >
                {data[1]?.Query_2?.includes("Bearish")
                  ? "Bearish"
                  : data[1]?.Query_2?.includes("Bullish")
                  ? "Bullish"
                  : data[1]?.Query_2?.includes("Neutral")
                  ? "Neutral"
                  : "No sentiment "}
              </span>
            </div>
            <div className="content">
              <ul>
                {(formatQuery2(data[1]?.Query_2)?.split("\n") || []).map(
                  (statement, index) => (
                    <p key={index}>{statement}</p>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="right-content-container">
          <div className="chatbot-container">
            <div className="chatbot-header">
              <span>CHATBOT</span>
            </div>
            <div className="chatbot-body">
              {conversation.map((item, i) => {
                if (item.type === "bot") {
                  return (
                    <div className="message bot-response" key={i}>
                      <ReactMarkdown>{item.message}</ReactMarkdown>
                    </div>
                  );
                }

                if (item.type === "user") {
                  return (
                    <div className="message user-message" key={i}>
                      <p>{item.message}</p>
                    </div>
                  );
                }
              })}

              {/* Conditionally render buttons when no response */}
              {!conversation.length && (
                <div style={{display:"flex",margin:"5rem 0 0",flexDirection:"column"}}>
                  <div style={{ textAlign: "center" }}>
                    Browse Trending Prompts
                  </div>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "How is the Company's financial performance?"
                      )
                    }
                  >
                    How is the Company's financial performance?
                  </div>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "What recent news has the company released?"
                      )
                    }
                  >
                    What recent news has the company released?
                  </div>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "What are the key events for the company?"
                      )
                    }
                  >
                    What are the key events for the company?
                  </div>
                </div>
              )}

              {isLoaderVisible && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}
            </div>

            <form className="chatbot-footer" onSubmit={handleSearchClick}>
              <div className="input-container">
                <input
                  className="chatbot-textarea"
                  placeholder="Type your Query here..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  // onKeyDown={handleKeyDown}
                />
                <button
                  className="chatbot-submit"
                  // onClick={handleSearchClick}
                  type="submit"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.73018 3.24438L8.88746 4.66488C9.84722 5.09668 10.6026 5.43651 11.115 5.75832C11.6211 6.07618 12.0166 6.45305 12.0166 6.99989C12.0166 7.54672 11.6211 7.92359 11.115 8.24145C10.6026 8.56326 9.84722 8.90309 8.88747 9.33489L5.73018 10.7554C5.14356 11.0193 4.68721 11.2246 4.33088 11.3579C3.98219 11.4883 3.67056 11.5739 3.39346 11.5438C2.72136 11.4707 2.14302 11.0098 2.00304 10.3591C1.94372 10.0834 2.02667 9.77486 2.14586 9.45438C2.26926 9.12255 2.46539 8.6965 2.71525 8.15372L2.72199 8.13909C2.91727 7.71487 2.99243 7.54748 3.03077 7.38048C3.08846 7.12928 3.08846 6.87049 3.03077 6.61929C2.99243 6.4523 2.91727 6.2849 2.72199 5.86069L2.71525 5.84605C2.46539 5.30327 2.26926 4.87722 2.14586 4.5454C2.02667 4.22491 1.94372 3.91641 2.00305 3.64064C2.14302 2.98997 2.72135 2.52909 3.39346 2.456C3.67056 2.42587 3.98219 2.5115 4.33088 2.64189C4.68721 2.77514 5.14356 2.98046 5.73018 3.24438ZM4.0857 3.29755C3.74308 3.16943 3.56806 3.14114 3.46913 3.1519C3.0547 3.19696 2.75541 3.47167 2.68739 3.78786C2.67473 3.84669 2.68357 3.98307 2.80196 4.3014C2.91501 4.60541 3.09981 5.00742 3.35785 5.56798C3.36351 5.58026 3.36909 5.59239 3.37462 5.60438C3.54775 5.98037 3.65651 6.21654 3.71302 6.46263C3.79438 6.81693 3.79438 7.18284 3.71302 7.53715C3.65651 7.78324 3.54775 8.01941 3.37461 8.3954C3.36909 8.40739 3.36351 8.41952 3.35785 8.4318C3.09981 8.99235 2.91501 9.39437 2.80196 9.69837C2.68357 10.0167 2.67473 10.1531 2.68739 10.2119C2.75541 10.5281 3.0547 10.8028 3.46913 10.8479C3.56806 10.8586 3.74308 10.8303 4.0857 10.7022C4.41811 10.5779 4.85372 10.3821 5.45561 10.1113L8.57195 8.70926C9.56608 8.26199 10.2764 7.94148 10.7427 7.64866C11.2202 7.34874 11.3166 7.1539 11.3166 6.99989C11.3166 6.84588 11.2202 6.65104 10.7427 6.35111C10.2764 6.0583 9.56609 5.73779 8.57195 5.29052L5.45561 3.88844C4.85372 3.61764 4.41812 3.42185 4.0857 3.29755Z"
                      fill="#02040F"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bottom-content-container">
        <div className="grid-item">
          <h2>Key Events</h2>
          <div className="content">
            <ul>
              {(formatQuery3(data[2]?.Query_3)?.split("\n") || []).map(
                (event, index) => <li key={index}>{event}</li>
              ) || <li>No key events available</li>}
            </ul>
          </div>
        </div>

        <div className="grid-item">
          <h2>Share Market Events</h2>
          <div className="content">
            <ul>
              {(formatQuery4(data[3]?.Query_4)?.split("\n") || []).map(
                (event, index) => (
                  <li key={index}>{event}</li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOverview;
