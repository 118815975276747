import CustomTable from "./SubComponents/Table";
import PieChart from "../Graphs/CompanyDocs/PieChart";
import { getKeys } from "../../utils/CompanyDocs";


/**
 * The `Promoters` component displays a table and a pie chart related to public or promoter data.
 *
 * @param {Object} props - The component's properties.
 * @param {Array} props.data - The data for the pie chart.
 * @param {Array} props.tableHeaderData - The table column headers.
 * @param {Array} props.tableData - The data to populate the table rows.
 * @param {string} props.mode - The current mode (public or promoter).
 * @param {Function} props.onSort - The function to handle column sorting.
 * @param {string} props.sortFilterVal - The current sorting filter value.
 * @param {string} props.filterVal - The current filter value.
 * @returns {JSX.Element} The rendered `Promoters` component.
 */
const Promoters = ({data, tableHeaderData, tableData,  mode, onSort, sortFilterVal, filterVal}) => {

    //data --- can be public or promoter ---> used by graph
    //sortFilterVal --- can be public or promoter 
    // filterVal --- can be public or promoter 
    // mode --- can be public or promoter 
    const pieChartLastQuarter = data?.length > 0 ? getKeys([...data], 'PieChart')[0] : '';

    return (

        <div className="row ">
            <div className="col-lg-8 mb-5 ">
                <CustomTable
                    onSort={onSort}
                    mode={mode}
                    tableData={tableData}
                    tableHeaderData={tableHeaderData}
                    sortFilterVal={sortFilterVal}
                    filterVal={filterVal}
                />
            </div>

            <div className="col-lg-4 mt-3 mb-3">
                
                {/* <div
                    className='d-flex flex-column justify-content-center align-items-center '
                    style={{ maxHeight: 300 }}
                >
                    <h6 className='pb-3'>
                        {`Shareholding for ${pieChartLastQuarter}`}
                    </h6>
              
                </div> */}
                <PieChart
                    data={data}
                    pieChartLastQuarter={pieChartLastQuarter}
                />
            </div>


        </div>
    );
}

export default Promoters;