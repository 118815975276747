import * as types from "./types";

const initialState = {
  password: "AlgoAnalytics123#",
  username: "Fabric_Demo",
  isLoggedin: false
};
/**
 * Reducer for managing authentication state.
 * @param {object} state - The current authentication state.
 * @param {object} action - The action object that contains the type and payload for updating the state.
 * @returns {object} - The updated authentication state.
 */
const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case types.SET_USERNAME:
        return { ...state, username: action.username };
      case types.SET_PASSWORD:
        return { ...state, password: action.password };
      case types.SET_SESSSION:
        return { ...state, isLoggedin: action.loggedin };
      default:
        return state;
    }
  };
  
  export default authReducer;