
import { format } from "date-fns";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import useWindowDimemsions from "../../../hooks/useWindowDimesnions";


const getMonthDayFormat = (value:any) => {
  const str:any = (format(new Date(value), 'dd LLL'));
  //console.log(value, str);
  return str ? str.toUpperCase() : str;
}
/**
 * The `StockSentiment` component displays a responsive area chart
 * comparing company news vs. sector news over a period of time.
 *
 * This function is called in \src\Views\TechnicalAnalysis\TechnicalAnalysis.tsx
 * 
 * @param {Object} newsTimeVsTotalTime - The data for plotting the area chart.
 * @returns {JSX.Element} The rendered `StockSentiment` component.
 */
export default function StockSentiment({ newsTimeVsTotalTime }:any) {

  const { width }:any = useWindowDimemsions();

  const renderLegend = (props:any) => {
    const { payload }:any = props;
    //console.log('renderLegend', props)
    return (

      <div className="d-flex justify-content-center">
        {
          payload.map((entry:any, index:any) => (
            <div key={`item-${index}`} className="me-2">
              <p
                style={{ fontFamily: "Open Sans", fontSize: width && width > 768 ? '12px' : '10px', paddingRight: 5 }}
              ><span style={{
                height: 10,
                width: 10,
                background: entry?.color,
                borderRadius: '50%',
                display: 'inline-block',
              }}></span>&nbsp;{entry.value}</p>
            </div>
          ))
        }
      </div>
    );
  }


  const data = newsTimeVsTotalTime?.news_ticker &&
    newsTimeVsTotalTime?.total_news &&
    newsTimeVsTotalTime?.news_ticker?.length > 0 &&
    newsTimeVsTotalTime?.total_news?.length > 0 &&
    newsTimeVsTotalTime?.news_ticker.length === newsTimeVsTotalTime?.total_news.length ?
    newsTimeVsTotalTime.news_ticker
      .map((news:any, index:any) =>
      ({
        [`Company News`]: news.y,
        [`Total News`]: newsTimeVsTotalTime.total_news[index].y,
        name: getMonthDayFormat(news.x),
      }))
    : [];

  //console.log(`newsTimeVsTotalTime`, data, newsTimeVsTotalTime)

  // const data = new Array(100).fill({
  //   name: '03 JUN',
  //   [`Company News`]: 3490,
  //   [`Total News`]: 4300,
  // });

  return (
    <div className={`stock-sentiment  col-lg-6   ${width && width > 768 ? "mb-3 " : "mb-4"} `}>
      <div className="p-3 bg-white pb-4" style={{ borderRadius: 6 }}>
        <h6
          className="text-center"
          style={{ fontWeight: 500, fontFamily: "Open Sans", fontSize: width && width > 768 ? '14px' : '13px' }}
        >Company&nbsp;News vs Sector&nbsp;News</h6>
        <ResponsiveContainer width="100%" height={250} minHeight={250}>

          <AreaChart
            // width={900}
            //height={300}
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: -10,
              bottom: 10
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"
              style={{ fontFamily: "Open Sans", fontSize: width && width > 768 ? '13px' : '8px' }}
              // minTickGap={5}
              // angle={-90}
              //type={"number"}
              //interval={'preserveStartEnd'}
              interval={data?.length > 0 ? Math.floor(data.length / 5) : 0}
            />
            <YAxis
              allowDecimals={false}
              style={{ fontFamily: "Open Sans", fontSize: width && width > 768 ? '13px' : '8px' }}
            />
            <Tooltip
              contentStyle={{ fontFamily: "Open Sans", fontSize: width && width > 768 ? '14px' : '13px' }}
              labelStyle={{ fontFamily: "Open Sans", fontSize: width && width > 768 ? '14px' : '13px' }}
            />
            <Legend verticalAlign="bottom" height={10} content={renderLegend} />

            <Area
              type="monotone"
              dataKey="Total News"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
              isAnimationActive={false}
              dot={{ stroke: '#7571d2', strokeWidth: 2 }} 
            />
            <Area
              type="monotone"
              dataKey="Company News"
              stackId="2"
              stroke="#82ca9d"
              fill="#82ca9d"
              isAnimationActive={false}
              dot={{ stroke: '#3d8f5c', strokeWidth: 2 }} 
            />

          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}


