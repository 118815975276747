import jsonp from "jsonp";

const jsonpPromise = (
  url: string,
  options:
    | {
        param?: string | undefined;
        prefix?: string | undefined;
        name?: string | undefined;
        timeout?: number | undefined;
      }
    | undefined
) => {
  return new Promise((resolve, reject) => {
    jsonp(url, options, (err, data) => {
      if (err) {
        reject(err || "jsonp error: Empty error");
      } else if (data.result !== "success") {
        reject(data.msg);
      } else {
        resolve(data);
      }
    });
  });
};

export default jsonpPromise;
