import { useState, useEffect } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Sort from "../Common/Sort/Sort";
import { newsTypes, newsCountProps } from "./newsContent.types";
import rightArrow from "../../assets/images/icons/rightArrow.png";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
import CardSearch from "../Common/CardSearch";
import { getSortOrFilteredArr, getUniqueOptions } from "../../utils/Common";

import "./newsContent.scss";

/**
 * NewsContent Component.
 *
 * This component displays a list of news articles with options for sorting and filtering.
 * Users can sort news articles by date and sentiment, and filter them by news source.
 *
 * This function in called in \src\Views\News\News.tsx
 *
 * @template T - The type parameter for news data.
 * @param {Object} props - The props containing setSingleNews and allNews information.
 * @returns {JSX.Element} - A React JSX element representing the NewsContent component.
 */
const NewsContent = <T extends {}>({
  setSingleNews,
  allNews,
}: newsCountProps<T>) => {
  // Initialize search parameters from URL
  const [searchParams, setSearchParams] = useSearchParams();
  // State for the list of news
  const [newsList, setNewsList] = useState<newsTypes[] | []>([]);
  // State for the dropdown open status
  const [open, setOpen] = useState(false);
  // State for the search value
  const [searchValue, setSearchValue] = useState<any>(null);
  // State for the sort value
  const [sortValue, setSortValue] = useState("");
  // State for the original data
  const [originalData, setOriginalData] = useState<newsTypes[] | []>([]);
  // State for the options data
  const [optionsData, setOptionsData] = useState<any[]>([]);

  // Array of sorting options
  const sortSentiment = [
    "Date: Newest to Oldest",
    "Date: Oldest to Newest",
    "Sentiment: Positive to Negative",
    "Sentiment: Negative to Positive",
  ];

  // Function to update sort value and set it in search parameters
  const updatesetSortValue = (value: string) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sort", value);
      return params;
    });
    setSortValue(value);
  };

  // Effect to reset states and set sort value from search parameters when allNews or searchParams change
  useEffect(() => {
    setNewsList(allNews);
    setOriginalData(allNews);
    setOptionsData(getUniqueOptions(allNews));
    setSearchValue(null);
    const params = new URLSearchParams(searchParams);
    const sort = params.get("sort");
    if (sort) {
      setSortValue(sort);
    }
  }, [allNews, searchParams]);

  // Effect to set newsList based on search and sort values when they change
  useEffect(() => {
    const result = getSortOrFilteredArr(originalData, searchValue, sortValue);
    setNewsList(result);
  }, [searchValue, sortValue, originalData]);

  const { width }: any = useWindowDimemsions();

  const xsWidth = 576;
  const heightStyle =
    width && width < xsWidth && newsList?.length === 0
      ? { minHeight: "100px", maxHeight: "100px" }
      : width && width < xsWidth && newsList?.length > 0
      ? { maxHeight: "75vh" }
      : { minHeight: "75vh", maxHeight: "75vh" };

  // console.log('state', newsList.map((d:any)=>d.News_date));

  console.log({allNews})

  return (
    <div
      className={`news-content  col-md-3  ${
        width && width > 768 ? "mb-2" : "mb-4"
      }`}
    >
      <div className="inner-content p-4 pb-0" style={heightStyle}>
        <div className="header">
          <h6 style={{ margin: 0, paddingRight: "2px" }}>News&nbsp;</h6>

          <Sort
            sortSentiment={sortSentiment}
            sortByScore={(event: string) => updatesetSortValue(event)}
            title="Sort By"
          />
        </div>

        <div className="d-flex justify-content-center align-items-center mb-3">
          <span className="Search-Sources">Search Sources</span>&nbsp;
          <CardSearch
            open={open}
            onOpenChange={(newOpen: boolean) => setOpen(newOpen)}
            onShow={() => setOpen(true)}
            data={optionsData}
            onSearchChange={(value: any) => {
              if (value) {
                setSearchValue(value);
              } else {
                setSearchValue(null);
              }
              setOpen(false);
            }}
            value={searchValue}
            placeholder={`Search Sources`}
          />
        </div>

        <div className="insider-child">
          {(newsList as [])?.length > 0 &&
            (newsList as []).map((news: newsTypes, index) => (
              <div
                key={index}
                className="background-white p-3 mb-3"
                style={{
                  background:
                    news.active === true ? "rgb(227, 235, 255)" : "white",
                }}
                onClick={() => {
                  setSingleNews(news);
                  const copyNewsList = newsList.map((element, ind) =>
                    ind === index
                      ? { ...element, active: true }
                      : { ...element, active: false }
                  );
                  setNewsList(() => copyNewsList);
                }}
              >
                <div
                  className="d-flex 
                justify-content-between
                 align-items-center 
                 flex-row-reverse
                 "
                >
                  <span
                    className="statusDots"
                    style={{
                      background:
                        news.News_Sentiment === "negative"
                          ? "rgb(253, 24, 126)"
                          : news.News_Sentiment === "positive"
                          ? "#39E0A4"
                          : "#FEAF38",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span className="time">
                    {news.News_date &&
                      moment(news.News_date).format("DD-MMM-YYYY HH:mm ")}
                  </span>
                </div>
                <p className="mint-text mb-0">
                  <span>{news.News_Source}</span>: {news.News_Title}&nbsp;
                  <img src={rightArrow} alt="right arrow" />
                </p>
              </div>
            ))}

          {newsList.length === 0 && <p>No News</p>}
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
