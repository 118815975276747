import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


/**
 * The `NewSourceDistChart` component displays a bar chart that visualizes the distribution of news sources.
 * It uses the Chart.js library to render the chart.
 *
 * @param {Object} props - The component's props, including data for the bar chart.
 * @returns {JSX.Element} The rendered `NewSourceDistChart` component.
 */
const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      // position: 'top',
    },
    title: {
      display: false,
      //text: 'News Source Distribution',
    },
  },
};


const colorsss = [

  //canva design given colors
  'rgba(166, 187, 225)',
  'rgba(210, 232, 156)',
  'rgba(239, 214, 177)',
  'rgba(33, 139, 130)',

  //my selected colors
  'rgba(255, 238, 147)',
  'rgb(217,115,115)',
  'rgb(162,111,108)',
  'rgb(115,85,110)',
  'rgb(255,207,229)',
  'rgb(255,166,136)',


  //random colors
  'rgba(226, 166, 166)',
  'rgba(205, 191, 214)',
  'rgba(187, 224, 227)',
  'rgba(244, 209, 185)',



];
/**
 * Retrieves data from the provided data object based on the specified property.
 *
 * @param {Object} data - The data object.
 * @param {string} property - The property to extract data from.
 * @returns {Array} An array containing the extracted data or an empty array if the data is missing or empty.
 */
const getData = (data, property) => {
  return (
    data &&
      Object.keys(data || {})?.length > 0 &&
      data[property] &&
      data[property]?.length > 0 ?
      [...data[property]] : []  //create copy cause unsure if library modifies data
  );

};

/**
 * The `NewSourceDistChart` component renders a bar chart visualizing news source distribution.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @component
 * @param {Object} props - The component's props.
 * @param {Object} props.data - Data for the bar chart.
 * @returns {JSX.Element} The rendered `NewSourceDistChart` component.
 */
export default function NewSourceDistChart({ data }) {

  const labels = useMemo(
    () => getData(data, "x"),
    [data]
  );

  const graphdata = useMemo(
    () => getData(data, "y"),
    [data]
  );


  const dataaaaa = {
    labels,
    datasets: [
      {
        label: 'Distribution',
        data: graphdata, //create copy cause unsure if library modifies data , seen unadded properties on this data field 
        borderColor: colorsss,
        backgroundColor: colorsss,
      },

    ],
  };


  return (
    <div className={`col-md-6 col-margin `}>
      <div className="outer-wraper">
        <h6 className="graph-text-heading">News&nbsp;Source&nbsp;Distribution</h6>
        <div className="graph-container" >
          <Bar options={options} data={dataaaaa} />
        </div>
      </div>
    </div>
  );
}
