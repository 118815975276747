import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import messageBlue from "../../assets/images/icons/messageBlue.png";
import progressIcon from "../../assets/images/icons/progressPink.png";
import messageGreen from "../../assets/images/icons/messagebGreen.png";

import Cards from "../../Views/Menu/Cards";
import NewOverview from "../../Views/Overview/NewOverview";

import News from "../../Views/News/News";
import TechnicalAnalysis from "../../Views/TechnicalAnalysis/TechnicalAnalysis";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";

import { identifyTweet } from "../../Actions/TweetsList";
import { identifyNews } from "../../Actions/NewsList";
import { headerTypes, TabPanelProps } from "./menu.types";
import "./menu.scss";
import FinancialInsights from "../../Views/FinancialInsights/FinancialInsights";
import MutualFunds from "../../Views/MutualFunds/MutualFunds";
import MenuMarquee from "./MenuMarquee";
import SearchTicker from "../../Views/Overview/SearchTicker";
import backArrow from "../../assets/images/icons/back_arrow.svg";
import { useNavigate } from "react-router-dom";

/**
 * Represents a tab panel used within the Menu component.
 *
 * @param {TabPanelProps} props - The props for the TabPanel component.
 * @returns {JSX.Element} The JSX representation of the TabPanel.
 */
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}
/**
 * Helper function to generate accessibility props for a tab.
 *
 * @param {number} index - The index of the tab.
 * @returns {Object} Accessibility props for the tab.
 */
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * This React component represents the main menu of the application.
 *
 * The main menu allows users to navigate between different sections of the application,
 * including Overview, Technical Analysis, News, Tweets, Company Docs, and Knowledge Graph.
 *
 * This function is called in \src\Router\Router.tsx
 *
 * @returns {JSX.Element} The JSX representation of the Menu component.
 */
const Menu = () => {
  let { ticker: tickerID, contentID, newsid: newsID, tweetID } = useParams();

  let location = useLocation();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const tickerQuery = query.get("ticker");
  const tickerId = tickerQuery
    ? tickerQuery
    : tickerID
    ? tickerID
    : window.localStorage.getItem("tickerId");

  const [value, setValue] = React.useState(0);

  const navigateMenu = useSelector((state: any) => state.NavigateMenu);

  useLayoutEffect(() => {
    setValue(0);
  }, [navigateMenu]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [headerInf, setHeaderInf] = useState<headerTypes>({} as headerTypes);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  //search ticker
  const [optionsData, setOptionsData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: summaryData } = await axios.get(
          `${process.env.REACT_APP_API_URL}/summary.json`
        );

        if (summaryData?.summary) {
          const arr = summaryData.summary.map((item: any) => ({
            value: item.Stock_Ticker,
            label: item.Stock_Ticker,
          }));
          //sort by label
          arr.sort((a: any, b: any) => a.label.localeCompare(b.label));
          setOptionsData(arr);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  //Redirecting routes to the respective tabs
  const getHeaderData = () => {
    let tickerId = tickerQuery
      ? tickerQuery
      : tickerID
      ? tickerID
      : window.localStorage.getItem("tickerId");
    if (location.pathname.toLowerCase().includes("news")) {
      setValue(2);
    } else if (location.pathname.toLowerCase().includes("tweets")) {
      setValue(3);
    } else if (location.pathname.toLowerCase().includes("technicalanalysis")) {
      setValue(1);
    }

    setIsLoaderVisible(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${tickerId}/header_information.json`
      )
      .then(
        (response) => {
          setIsLoaderVisible(false);
          setHeaderInf(response.data);
        },
        (error) => {
          setIsLoaderVisible(false);
          console.log(error);
        }
      );
  };

  useEffect(() => {
    getHeaderData();
  }, []);

  useEffect(() => {
    if (value !== 3) {
      dispatch(identifyNews([]));
    }
    // if (value !== 4)
    else {
      dispatch(identifyTweet([]));
    }
  }, [value]);

  const { width }: any = useWindowDimemsions();
  const lgWidth = 991;

  const styleObj =
    width && width > lgWidth ? { fontSize: "14px" } : { fontSize: "13px" };

  const tabFontSize =
    width && width >= lgWidth
      ? { fontSize: "15px" }
      : width && width >= 685 && width < lgWidth
      ? { fontSize: "14px" }
      : { fontSize: "13px" };

  const arry = ["/search", "/detail", "/aggregator"];
  const navigate = useNavigate();

  return (
    <div
      style={{ background: "linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%)" }}
    >
      <div className="main-menu">
        <div className="main-menu-inner pt-4 mb-4">
        <div className="header">
          <header>
            <div className="toolbar-flex">
              {arry.includes(location.pathname) && (
                <button
                  className="backButton"
                  onClick={() => navigate("/dashboard")}
                >
                  <img src={backArrow} alt="backArrow" /> &nbsp; Dashboard
                </button>
              )}
            </div>
          </header>
        </div>
          <div className="main-menu-header">
            <div>
              <img
                src={`${process.env.REACT_APP_COMPANY_DOC}/Logos/${
                  tickerId?.split(".")[0]
                }.png`}
                alt="logo"
                className="stock-logo"
              />
            </div>
            <div>
              <h4 className="bank-name" style={styleObj}>
                {headerInf.Stock_Name}
              </h4>
            </div>
            <span className="finance" style={styleObj}>
              {headerInf.Stock_Sector}
            </span>
          </div>
          <MenuMarquee
            text1={" Previous Close : " + headerInf.Yesterdays_Close}
            text2={" Open : " + headerInf.Todays_Open}
            text3={" Last Traded Price : " + headerInf.Todays_Close}
            text4={
              " News Last Updated : " +
              moment(headerInf.Last_Updated).format("DD-MMM-YYYY HH:mm ")
            }
            text5={
              " Stocks Last Updated : " +
              moment(headerInf.Last_Updated_Stocks).format("DD-MMM-YYYY HH:mm ")
            }
          />
          <div className="main-menu-tabs">
            <div className="tab-content">
              <Cards
                image={messageBlue}
                heading={
                  headerInf.Yesterdays_Close === "Data not Present"
                    ? "NA"
                    : headerInf.Yesterdays_Close
                }
                status={"Previous Close"}
              />
            </div>
            <div className="tab-content">
              <Cards
                image={progressIcon}
                heading={
                  headerInf.Todays_Open === "Data not Present"
                    ? "NA"
                    : headerInf.Todays_Open
                }
                status={"Open"}
              />
            </div>
            <div className="tab-content">
              <Cards
                image={messageGreen}
                heading={
                  headerInf.Todays_Close === "Data not Present"
                    ? "NA"
                    : headerInf.Todays_Close
                }
                status={"Last Traded Price"}
              />
            </div>
            {/* <div className="tab-content">
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated).format(
                "DD-MMM-YYYY HH:mm "
              )}
              status={"News Last Updated"}
            />
          </div> */}
            {/* <div className="tab-content">
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated_Stocks).format(
                "DD-MMM-YYYY HH:mm "
              )}
              status={"Stocks Last Updated"}
            />
          </div> */}
          </div>
          <SearchTicker stocks={optionsData} />
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
            <Tabs
              variant={width && width > 718 ? "standard" : "scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab
                className="Tab-custom-style"
                label="Overview"
                {...a11yProps(0)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  maxHeight: "1rem",
                  minHeight: "37px",
                  ...tabFontSize,
                }}
              />
              <Tab
                className="Tab-custom-style"
                label="Technical Analysis"
                {...a11yProps(1)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  maxHeight: "1rem",
                  minHeight: "37px",
                  ...tabFontSize,
                }}
              />

              <Tab
                className="Tab-custom-style"
                label="News"
                {...a11yProps(2)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  maxHeight: "1rem",
                  minHeight: "37px",
                  ...tabFontSize,
                }}
              />
              <Tab
                label="Company Reports"
                className="Tab-custom-style"
                {...a11yProps(4)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  maxHeight: "1rem",
                  minHeight: "37px",
                  ...tabFontSize,
                }}
              />
              <Tab
                label="Mutual Funds"
                className="Tab-custom-style"
                {...a11yProps(5)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  maxHeight: "1rem",
                  minHeight: "37px",
                  ...tabFontSize,
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <NewOverview
              activeTab={setValue}
              stock_name={headerInf.Stock_Name}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TechnicalAnalysis />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <News tickerid={tickerID || ""} news_id={parseInt(newsID || "")} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <FinancialInsights />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MutualFunds />
          </TabPanel>
        </Box>

        {isLoaderVisible && (
          <div className="loader">
            {" "}
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
