import Footer from "../../Component/LandingV2/Footer";
import Navbar from "../../Component/LandingV2/Navbar";
import "./NiftyStatergy.scss";
import { useEffect, useState } from "react";
import BacktestingDataDummy from "../../data/nifty/backtesting_o2c.json";
import NiftyNewsDummy from "../../data/nifty/news_data.json";
import Table from "./BackTestingTable";
import "./BackTestingTable.scss";
import SubNavbar from "../../Component/LandingV2/SubNavbar";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

export type BacktestingData = {
  Backtesting?: Backtesting | null;
  "About Strategy"?: AboutStrategy | null;
};

export type AboutStrategy = {
  Text: string;
};

export type Backtesting = {
  [key: string]: string;
};
export type HistoricalDataItem = {
  Date: string;
  Prediction: number;
  Confidence: number;
  label: number;
};

export type HistoricalData = HistoricalDataItem[];

export type NiftyNews = {
  Nifty: NiftyNewsItem[];
  NSE: NiftyNewsItem[];
  BSE: NiftyNewsItem[];
  Sensex: NiftyNewsItem[];
};

export type NiftyNewsItem = {
  title: string;
  published: string;
  source: {
    href: string;
    title: string;
  };
};


const NiftyStatergy = () => {
  const today = new Date().toISOString().split("T")[0];
  // console.log(today);

  const [showtext, setShowtext] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasPredictionImage, setHasPredictionImage] = useState(true); // State to track if prediction image is available

  const iconHandler = () => {
    setShowtext(!showtext);
    setIsOpen(!isOpen);
  };

  const [backtestingData, setBacktestingData] = useState<BacktestingData>({
    Backtesting: BacktestingDataDummy[0]["Backtesting"],
    "About Strategy": BacktestingDataDummy[1]["About Strategy"],
  });

  // console.log(backtestingData);

  const [historicalData, setHistoricalData] = useState<HistoricalData>([]);

  const [newsData, setNewsData] = useState<NiftyNews>({
    Nifty: NiftyNewsDummy["Nifty"],
    NSE: NiftyNewsDummy["NSE"],
    BSE: NiftyNewsDummy["BSE"],
    Sensex: NiftyNewsDummy["Sensex"],
  });

  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_COMPANY_DOC +
          "/nifty_o2c_strategy/last_n_days_predictions.json"
      );
      const data = await response.json();

      // Sort data by Confidence in descending order
      // data.sort((a: HistoricalDataItem, b: HistoricalDataItem) => b.Confidence - a.Confidence);
      data.sort((a: HistoricalDataItem, b: HistoricalDataItem) => 
        new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );

      setHistoricalData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNewsData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_COMPANY_DOC + "/nifty_o2c_strategy/news.json"
      );
      const data = await response.json();
      setNewsData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchHistoricalData();
    fetchNewsData();
  }, []);

  // Function to handle image load error
  const handleImageError = () => {
    setHasPredictionImage(false);
  };

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);

  const [isNewsPaneVisible, setIsNewsPaneVisible] = useState<boolean>(false);

  const toggleNewsPane = () => {
    setIsNewsPaneVisible(!isNewsPaneVisible);
  };

  return (
    <>
      {/* <Navbar />
      <SubNavbar navData={subNavData} /> */}
      <div className="statergy-news-split">
        <div className="statergy-pane">
          <div className="statergy-prediction">
            <div className="title" style={{marginTop:"2rem"}}>Nifty Futures O2C Strategy Prediction</div>
            {hasPredictionImage ? (
              <div className="img">
                <img
                  src={`https://atsstoragefiles.blob.core.windows.net/plots/${today}_prediction_graph_new.jpg`}
                  alt="Prediction Graph"
                  onError={handleImageError}
                />
              </div>
            ) : (
              <div className="no-signal-text" style = {{textAlign: "center"}}>
                No signal generated For today
              </div>
            )}
            <div className="text-summary">
              <div className="heading" onClick={iconHandler}>
                <div className="icon-handler">
                  {isOpen ? (
                    <img src="assets/images/nifty/close.png" alt="open" />
                  ) : (
                    <img src="assets/images/nifty/Open.png" alt="close" />
                  )}
                </div>
                <div className="title">
                  What to know more about our Strategy?
                </div>
              </div>
              {showtext && (
                <div
                  className={`text ${
                    showtext ? "text-visible" : "text-hidden"
                  }`}
                  style={{
                    animation: "fadeIn 0.5s ease-in-out",
                  }}
                >
                  <p className = "about-strategy-text" style={{paddingLeft :"15%"}}>
                    {backtestingData["About Strategy"]?.Text ||
                      "No data available"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="table-split">
            <Table data={backtestingData} />
            <div className="historical-table">
              <div className="title">Historical Performance</div>
              <table className="table1 table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Prediction</th>
                    <th>Confidence</th>
                  </tr>
                </thead>
                <tbody>
                  {historicalData.map((data, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          data.Prediction === data.label
                            ? "#D5F3C8"
                            : "#F8D7DA",
                      }}
                    >
                      <td>{data.Date}</td>
                      <td>{data.Prediction}</td>
                      <td>{data.Confidence.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>


    {/* <div className={isMobile ? 'mobile-class' : 'desktop-class'}> */}

     {isMobile ? <div className="news-container">
      <button className="toggle-button" onClick={toggleNewsPane}>
        {isNewsPaneVisible ? <div> News <FaArrowRight style={{ marginLeft: "8px" }} /></div> 
        : 
        <div> News <FaArrowLeft style={{ marginLeft: "8px" }}/></div>
         }
      </button>

      <div className={`news-pane ${isNewsPaneVisible ? 'slide-in' : 'slide-out'}`}>
        <div className="news-card-contaier">
          {newsData.Nifty.map((news, index) => (
            <div key={index} className="news-item">
              <div className="news-published">{news.published}</div>
              <div><b>{news.source.title}</b></div>
              <div className="news-title">
                {news.title.split('-')[0]}
              </div>
              <div className="news-source">
                <a href={news.source.href} target="_blank" rel="noreferrer">
                  <img src="assets/images/nifty/arrow.png" alt="arrow-btn" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div> 
    </div>  : <div className="news-pane">
          <div className="title">News</div>
          <div className="news-card-contaier">
            {newsData.Nifty.map((news, index) => (
              <div key={index} className="news-item">
                <div className="news-published">{news.published}</div>
                <div><b>{news.source.title}</b></div>
                <div className="news-title">
                  {news.title.split('-')[0]}
                </div>
                <div className="news-source">
                  <a href={news.source.href} target="_blank" rel="noreferrer">
                    <img src="assets/images/nifty/arrow.png" alt="arrow-btn" />
                  </a>
                </div>
              </div>
            ))}
            {/* </div> */}
          </div>
        </div>   
        }    
      </div>

      <Footer />
    </>
  );
};

export default NiftyStatergy;
