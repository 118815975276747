import { Bar } from "react-chartjs-2";
import "./FinancialInsights.scss";

const CashFlowGraph = ({ graphData }) => {
  let cashFlowGraphData = null;
  if (graphData) {
    const cashFlowBarData = graphData.cash_flows_bar_coordinates;

    const cashWaterfallData = graphData.waterfall_net_cash_flow_coordinates;

    const barLabels = Object.keys(
      cashFlowBarData[Object.keys(cashFlowBarData)[0]]
    );
    // console.log(barLabels);

    const waterFallLabels = Object.keys(cashWaterfallData[Object.keys(cashWaterfallData)[0]]); 


    const barDataset = {
      label: Object.keys(cashFlowBarData)[0],
      data: Object.values(cashFlowBarData[Object.keys(cashFlowBarData)[0]]),
      backgroundColor: `rgba(255, 28, 28, 0.8)`,
      borderColor: `rgba(255, 28, 28, 1)`,
      borderWidth: 1,
    };
    // console.log(barDataset);

    const waterFallDataset = Object.keys(cashWaterfallData).map((key, index) => {
        return {
            label: key, // Assuming each key is a label for the dataset
            data: Object.values(cashWaterfallData[key]),
            backgroundColor: `rgba(${255 - index * 20}, ${index * 20}, 0, 0.2)`,
            borderColor: `rgba(${255 - index * 20}, ${index * 20}, 0, 1)`,
            borderWidth: 1,
        };
        });

    const Options = {
      indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right",
        },
        title: {
          display: true,
          text: "Cash Flow",
        },
      },
    };

    const BarGraphData = {
      labels: barLabels,
      datasets: [barDataset],
    };

    const WaterFallGraphData = {
        labels: waterFallLabels,
        datasets: waterFallDataset,
        };

    cashFlowGraphData = {
      BarGraphData,
      WaterFallGraphData,
      Options,
    };
  }
  return cashFlowGraphData ? (
    <>
      <div className="graph">
        <Bar
          data={cashFlowGraphData.BarGraphData}
          options={cashFlowGraphData.Options}
        />
      </div>
      <div className="graph">
        <Bar
          data={cashFlowGraphData.WaterFallGraphData}
          options={cashFlowGraphData.Options}
        />
      </div>
    </>
  ) : null;
};

export default CashFlowGraph;
