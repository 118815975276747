import React from "react";
import { useState, useEffect } from "react";
import "./FinancialInsights.scss";
import CompanyDocs from "../CompanyDocs/CompanyDocs";
import Table from "./Table";
import axios from "axios";
import BalanceSheetGraph from "./BalanceSheetGraph";
import CashFlowGraph from "./CashFlowGraph";
import ProfitLossGraph from "./ProfitLossGraph";
import QuarterlyResultsGraph from "./QuarterlyResultsGraph";

const fetchData = async (tickerId, typeofData) => {
  return await axios.get(
    `${process.env.REACT_APP_COMPANY_DOC}/${tickerId}/financial_statements_and_insight/${typeofData}`
  );
};

const FinancialInsights = () => {
  const [activeTab, setActiveTab] = useState("Balance Sheet");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [insightsData, setInsightsData] = useState(null);
  const [cashflowData, setCashflowData] = useState(null);
  const [profitAndLossData, setProfitAndLossData] = useState(null);
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [quarterlyResultData, setQuarterlyResultData] = useState(null);
  const [graphData, setGraphData] = useState(null);

  const tickerId = localStorage.getItem("tickerId");

  useEffect(() => {
    (async () => {
      try {
        const insightResponse = await fetchData(tickerId, "insights.json");
        setInsightsData(insightResponse.data);

        const cashflowResponse = await fetchData(tickerId, "cash_flows.json");
        setCashflowData(cashflowResponse.data);

        const balanceSheetResponse = await fetchData(
          tickerId,
          "balance_sheet.json"
        );
        setBalanceSheetData(balanceSheetResponse.data);

        const profitAndLossResponse = await fetchData(
          tickerId,
          "profit_and_loss.json"
        );
        setProfitAndLossData(profitAndLossResponse.data);

        const quarterlyResultResponse = await fetchData(
          tickerId,
          "quarterly_results.json"
        );
        setQuarterlyResultData(quarterlyResultResponse.data);

        const graphResponse = await fetchData(
          tickerId,
          "graph_coordinates.json"
        );
        setGraphData(graphResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [tickerId]);

  const activeInsights =
    insightsData?.insights?.[
      `insight_${activeTab.toLowerCase().replace(/ /g, "_")}`
    ];
  
  const dataMapping = {
    "Balance Sheet": balanceSheetData,
    "Cash Flow": cashflowData,
    "Profit and Loss": profitAndLossData,
    "Quarterly Result": quarterlyResultData,
  };

  const graphComponents = {
    "Balance Sheet": BalanceSheetGraph,
    "Cash Flow": CashFlowGraph,
    "Profit and Loss": ProfitLossGraph,
    "Quarterly Result": QuarterlyResultsGraph,
  };

  const activeData = dataMapping[activeTab];

  const activeGraphComponent = graphComponents[activeTab];

  const analysisSentences = activeInsights?.Analysis?.split('. ');
  const analysisBulletPoints = analysisSentences?.slice(0, -1)?.map((sentence, index) => (
    <li key={index}>{sentence.trim()}</li>
  ));
  const lastSentence = analysisSentences?.[analysisSentences.length - 1];

  // Determine background color based on Report Character
  const reportCharacter = activeInsights?.[Object.keys(activeInsights ?? {})?.[1]];
  let backgroundColor;
  let border;
  switch (reportCharacter?.toLowerCase()) {
    case "positive report":
      backgroundColor = "#D5F3C8";
      border = "1px solid #3CB93D";
      break;
    case "negative report":
      backgroundColor = "#F8D7DA";
      border = "1px solid #D9534F";
      break;
    case "neutral report":
      backgroundColor = "#FEEFB3";
      border = "1px solid #F0AD4E";
      break;
    default:
      backgroundColor = "white"; // default background color
      border = "1px solid #ccc";
  }

  return (
    <div className="container-fluid">
      <div className="tab-container">
        <div
          className={`tab ${activeTab === "Balance Sheet" ? "active" : ""}`}
          onClick={() => handleTabClick("Balance Sheet")}
        >
          Balance Sheet
        </div>
        <div
          className={`tab ${activeTab === "Cash Flow" ? "active" : ""}`}
          onClick={() => handleTabClick("Cash Flow")}
        >
          Cashflow
        </div>
        <div
          className={`tab ${activeTab === "Profit and Loss" ? "active" : ""}`}
          onClick={() => handleTabClick("Profit and Loss")}
        >
          Profit & Loss
        </div>
        <div
          className={`tab ${activeTab === "Quarterly Result" ? "active" : ""}`}
          onClick={() => handleTabClick("Quarterly Result")}
        >
          Quarterly Results
        </div>
        <div
          className={`tab ${
            activeTab === "Shareholding Pattern" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Shareholding Pattern")}
        >
          Shareholding Pattern
        </div>
      </div>
      <div className="financial-insights-container">
        {activeTab === "Shareholding Pattern" ? (
          <CompanyDocs />
        ) : (
          // <div className="financial-panel">
          //   <div className="financial-insights-top">
          //     <div className="table-container">
          //       <Table data={activeData} />
          //     </div>
          //     <div
          //       className="insights-container"
          //       style={{ backgroundColor: backgroundColor, border: border }}
          //     >
          //       <h2>Insights</h2>
          //       <div className="report-summary">
          //         <ul>{analysisBulletPoints}</ul>
          //         <p>{lastSentence}</p>
          //       </div>
          //     </div>
          //   </div>
          //   <div className="financial-insights-bottom">
          //     <div className="graphs-container">
          //       {activeGraphComponent
          //         ? React.createElement(activeGraphComponent, {
          //             graphData: graphData,
          //           })
          //         : null}
          //     </div>
          //   </div>
          // </div>
          <div className="financial-panel">
  <div className="financial-insights-top">
    <div className="table-container">
      <Table data={activeData} />
    </div>
    <div
      className="insights-container"
      style={{ backgroundColor: backgroundColor, border: border }}
    >
      <h2>Insights</h2>
      <div className="report-summary">
      {analysisBulletPoints && analysisBulletPoints.length > 0 ? (
  <ul>{analysisBulletPoints}</ul>
) : (
  <p className="no-data">NO DATA FOUND.</p>
)}
<p>{lastSentence}</p>

      </div>
    </div>
  </div>
  <div className="financial-insights-bottom">
    <div className="graphs-container">
      {activeGraphComponent
        ? React.createElement(activeGraphComponent, {
            graphData: graphData,
          })
        : null}
    </div>
  </div>
</div>

        )}
      </div>
    </div>
  );
};

export default FinancialInsights;