//sort data based on highest in the current quater 
//show 3 highest in chart
//sum and show the remianing as other in chart


import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { checkAllValuesAreZero, getGraphDataArr, getGraphLabels, getRestSum, getValues } from '../../../../utils/CompanyDocs';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            display: true,
            position: "bottom",
            //align: "start",
        }
    },
    responsive: true,

};

/**
 * PieChart Component.
 *
 * This component is responsible for rendering a pie chart based on provided data.
 * It can dynamically handle data sorting and grouping for the pie chart, including displaying
 * the top three values and summarizing the rest as "others" if applicable.
 *
 * This function is called in \src\Views\PromotersAndPublic\Promoters.jsx
 * 
 * @param {Object} data - The data for the pie chart.
 * @param {Array} pieChartLastQuarter - The labels for the pie chart, typically representing a quarter or month.
 * @returns {JSX.Element} - A React JSX element representing the PieChart component.
 */
const PieChart = ({ data, pieChartLastQuarter }) => {

    const property = `Name of Shareholder`;

    //step 1 -- get labels array --> month "DEC 2022"
    const month = pieChartLastQuarter?.length > 0 ?
        pieChartLastQuarter : '';

    let labelss = [];
    let dataa = []

    if (data?.length > 3) { //if more than 4 shareholders present

        //step 2 -- sort array based on max for that month/quarter
        const sortBasedOnMax = data?.length > 0 ? //no need to check labels.length as labels come from data.length array
            [...data]  //spread needed else state directly modified
                .sort((a, b) => (Number(a[month]) > Number(b[month])) ? -1 :
                    (Number((b[month]) > Number(a[month])) ? 1 : 0)) : [];

        //step 3 --  get first 3 max in array
        const firstThreeMax = sortBasedOnMax?.length > 2 ?
            [...sortBasedOnMax].slice(0, 3) : [];

        //step 4 --sort array in same `Name of Shareholder` order
        const firstThreeMaxLabels = firstThreeMax?.length > 2 ?
            getValues(firstThreeMax).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)) : [];

        //step 5 -- get graph data array and sort it 
        const dataArr = firstThreeMax?.length > 2 ?
            [...firstThreeMax]
                .sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0))   //sort array in same `Name of Shareholder` order
                .map((object, index) => object[month]) : [];


        //step 6 -- sum of all remaing  shareholders
        const sumrestNonMax = sortBasedOnMax?.length > 3 ?
            getRestSum([...sortBasedOnMax].slice(3), month) : 0; //sum of remaing shareholders

        //step 7 -- add remaing shareholders --- others
        labelss = firstThreeMaxLabels?.length > 0 ? [...firstThreeMaxLabels, 'others'] : [];
        dataa = dataArr?.length > 0 ? [...dataArr, sumrestNonMax] : [];
    } else {
        //Step 2 ---> get shareholder names 
        labelss = getGraphLabels(data);

        //Step 3 ---> get values related to shareholder names
        dataa = getGraphDataArr(data, property, month);
    }

   // console.log('piechart ', labelss, dataa, );


   //dont show graph if all values are zero
    const displayGraphh = checkAllValuesAreZero(dataa) === true ? false : true;


    //console.log('displayGraphh ', displayGraphh, dataa, checkAllValuesAreZero(dataa));
 
    const graphData = {
        labels: labelss,
        datasets: [
            {
                label: `${month}`,
                data: dataa,
                backgroundColor: [
                    // 'rgb(142, 167, 233, 0.8)',
                    // 'rgb(114, 134, 211, 0.8)',
                    // 'rgb(229, 224, 255, 0.8)',
                    // 'rgba(126, 200, 227, 0.8)',

                    'rgb(0, 63, 92, 1)',
                    'rgb(122, 81, 149, 1)',
                    'rgb(255, 166, 0, 1)',
                    'rgba(255, 118, 74, 1)',
                ],
                borderColor: [
                    // 'rgb(142, 167, 233, 0.8)',
                    // 'rgb(114, 134, 211, 0.8)',
                    // 'rgb(229, 224, 255, 0.8)',
                    // 'rgba(126, 200, 227, 0.8)',

                    'rgb(0, 63, 92, 1)',
                    'rgb(122, 81, 149, 1)',
                    'rgb(255, 166, 0, 1)',
                    'rgba(255, 118, 74, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    //console.log('piechart ', data, month, );


    return (
       
            <div
                className='d-flex flex-column justify-content-center align-items-center '
                style={{ maxHeight: 300 }}
            >
            {displayGraphh === true ?
                <>
                    <h6 className='pb-3'>
                        {`Shareholding for ${pieChartLastQuarter}`}
                    </h6>
                    <Pie
                        data={graphData}
                        width={300}
                        //width={"20%"}
                        //height={150}
                        //options={{ maintainAspectRatio: false }} //in order for Chart.js to obey the custom size you need to set maintainAspectRatio to false:
                        options={options}
                    />
                </>
                :
                <h6 className='pb-3'>
                    {`No Data for Graph For Current Quarter`}
                </h6>
            }

            </div>
            
    );

}


export default PieChart;
