export const summary = (data: any) => {
  return {
    type: "GET_VALUE_OF_FILTER",
    payload: data,
  };
  
};



export const searchSummary = (data: any) => {
  return {
    type: "GET_VALUE_OF_SEARCH",
    payload: data,
  };
  
};


export const resetSummary = () => {
  return {
    type: "RESET_INITIAL_STATE",
  };
  
};