import "./ConfidenceSection.scss";

const ConfidenceSection = () => {
  return (
    <div className="confidence-section">
      <div className="selection-section">
        <div className="selection-title">
          <h1>Select With Confidence</h1>
        </div>
        <div className="selection-content">
          <div className="selection-content-item">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3V21H21M20 18V21M16 16V21M12 13V21M8 16V21M3 11C9 11 8 6 12 6C16 6 15 11 21 11"
                  stroke="#FE7F77"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a className="links" href="/strong-stocks-home">
              <div>
                <h3>Smart Stock Picks</h3>
                <p>
                  Daily curated stock portfolio powered by advanced algorithms.
                </p>
              </div>
            </a>
          </div>
          <div className="selection-content-item">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 8H4M3 16H4M8 3V4M16 3V4M20 8H21M20 16H21M8 20V21M16 20V21M8 9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15V9Z"
                  stroke="#FE7F77"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a className="links" href="/strong-stocks-home">
              <div>
                <h3>Nifty Future Insights</h3>
                <p>Receive daily actionable tips for Nifty Futures.</p>
              </div>
            </a>
          </div>
          <div className="selection-content-item">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 7C11 8.06087 10.5786 9.07828 9.82843 9.82843C9.07828 10.5786 8.06087 11 7 11C5.93913 11 4.92172 10.5786 4.17157 9.82843C3.42143 9.07828 3 8.06087 3 7C3 5.93913 3.42143 4.92172 4.17157 4.17157C4.92172 3.42143 5.93913 3 7 3M11 7C11 5.93913 10.5786 4.92172 9.82843 4.17157C9.07828 3.42143 8.06087 3 7 3M11 7H7V3M9 17V21M17 14V21M13 13V21M21 12V21"
                  stroke="#FE7F77"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a className="links" href="/strong-stocks-home">
              <div>
                <h3>Stock C2C</h3>
                <p>Get end-of-day stock performance updates.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="image-section">
        <div className="selection-image">
          <div className="img1">
            <img src="assets/images/landingv2/CARD1.png" alt="card1" />
          </div>
          <div className="img2-3">
            <div className="img2">
              <img src="assets/images/landingv2/CARD2.png" alt="card2" />
            </div>
            <div className="img3">
              <img src="assets/images/landingv2/CARD3 .png" alt="card3" />
            </div>
          </div>
        </div>
        {/* <div className="selection-bg-img">
          <img src="assets/images/landingv2/Sphere.png" alt="sphere-img" />
        </div> */}
      </div>
    </div>
  );
};

export default ConfidenceSection;
