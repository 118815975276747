import React, { useState, useEffect } from "react";
import "./candlestickPerDay.scss";
import Charts from "react-apexcharts";
// import moment from "moment";
import { candlestickPerDayProps } from "./candleStickPerDay.types";
import { format } from "date-fns";
const chartInf = {
  series: [
    {
      name: "candle",
      data: [],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "candlestick",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "",
      align: "left",
    },
    annotations: {
      xaxis: [
        {
          x: "",
          borderColor: "#00E396",
          label: {
            showDuplicates: false,
            borderColor: "#00E396",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#00E396",
            },
            orientation: "horizontal",
            offsetY: 7,
            text: "Annotation Test",
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: function (val: any) {
          //return moment(val).format("DD-MM-YYYY"); //yyyy-MM-dd
          return val ? format(new Date(val), 'dd-MM-yyyy') : val;
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: false,
      },
    },
  },
};
/**
 * CandlestickPerDay Component.
 *
 * This component displays a candlestick chart for stock sentiment over time.
 * It receives stock sentiment data and renders it as a candlestick chart.
 *
 * This function is called in \src\Views\TechnicalAnalysis\TechnicalAnalysis.tsx
 * 
 * @param {Object} stockSentimentOverTime - Stock sentiment data over time.
 * @param {Array} stockSentimentOverTime.x - Date values.
 * @param {Array} stockSentimentOverTime.y - Y-axis values.
 *
 * @returns {JSX.Element} - A React JSX element representing the CandlestickPerDay component.
 */
const CandlestickPerDay = ({
  stockSentimentOverTime,
}: candlestickPerDayProps) => {
  const [chartData, setchartData] = useState<any>(chartInf);
  const [chartUpdate, setChartUpdate] = useState<any>("");
  //console.log('stockSentimentOverTime', stockSentimentOverTime)
  useEffect(() => {
    try {
      setchartData({
        series: [
          {
            name: "candle",
            data: stockSentimentOverTime.map((stock) => {
              return {
                x: new Date(stock.x),
                y: stock.y,
              };
            }),
          },
        ],
        options: {
          chart: {
          //  height: 350,
            type: "candlestick",
            toolbar: {
              show: false,
            },
          },
          title: {
            text: "",
            align: "left",
          },
          annotations: {
            xaxis: [
              {
                x: "",
                borderColor: "#00E396",
                label: {
                  borderColor: "#00E396",
                  style: {
                    fontSize: "12px",
                    color: "#fff",
                    background: "#00E396",
                  },
                  orientation: "horizontal",
                  offsetY: 7,
                  text: "Annotation Test",
                },
              },
            ],
          },
          tooltip: {
            enabled: true,
          },
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val: any) {
                // return moment(val).format("DD MMM");
                return val ? format(new Date(val), 'dd LLL') : val;
              },
              rotate: -90,
              rotateAlways: true,
            },
          },
          yaxis: {
            tooltip: {
              enabled: true,
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
    //console.log('stockSentimentOverTime', stockSentimentOverTime)

  }, [stockSentimentOverTime]);

  useEffect(() => {
    setChartUpdate(
      <Charts
        options={chartData.options}
        series={chartData.series}
        type="candlestick"
        // height={240}
        height={235}

      />
    );
    return () => {
      setChartUpdate("");
    };
  }, [chartData]);


  return (
    <div className={`candlestick-per-day-graph  col-xl-4 `}>
      <div className="white-box">
        <h6 >Candlestick&nbsp;Per&nbsp;Day</h6>
        {chartUpdate}
      </div>
    </div>
  );
};

export default React.memo(CandlestickPerDay);
