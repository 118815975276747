import './VideoSection.scss';
import React, { useEffect, useRef } from 'react';

const VideoSection = () => {

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentAnchor = anchorRef.current;
    // Function to handle visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Document is now hidden, pause the YouTube video
        if (iframeRef.current) {
          iframeRef.current.src = iframeRef.current.src.replace('autoplay=1', 'autoplay=0');
        }
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Video is in view, autoplay it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace('autoplay=0', 'autoplay=1');
          }
        } else {
          // Video is not in view, pause it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace('autoplay=1', 'autoplay=0');
          }
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentAnchor) {
      observer.observe(anchorRef.current);
    }
  
    // Listen for visibility changes
    window.addEventListener('visibilitychange', handleVisibilityChange);
  
    // Cleanup function to remove the event listener
    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    };
  },);
  return (
    <div className="video-section">
      <div className="video-container">
        <div className="video" ref={anchorRef}>
            <iframe
              ref={iframeRef}
              width="914"
              height="700"
              src="https://www.youtube.com/embed/3VZ7JHZ-fWE"
              title="AlgoFabric: AI-Powered Financial Insights Tool"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
