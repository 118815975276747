import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const options = {
  animation: {
    duration: 0
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: "top",
      // maxHeight:5,
      // maxWidth:5
      labels: {
        boxHeight: 10,
        boxWidth: 10,
        //borderRadius:5
      }
      // onHover:handleHover
    }

  },
  maintainAspectRatio: false,
  // scales: {
  //   y: {
  //     stacked: true
  //   }
  // },
  scales: {
    x: {
      // type: 'category',
      // labels: ['January', 'February', 'March', 'April', 'May', 'June']
      ticks: {
        //autoSkip: false,
        // maxRotation: 90,
        // minRotation: 90
      },
    },
    A: {
      type: 'linear',
      display: true,
      position: 'left',
      suggestedMin: 0,
      // title: {
      //   display: true,
      //   text: 'News'
      // },
      // z:10,
    },
    B: {
      type: 'linear',
      display: true,
      position: 'right',
      //suggestedMin:0,
      min: 0,
      ticks: {
        stepSize: 1    //remove decimel
      },

      // title: {
      //   display: true,
      //   text: 'Volume'
      // },


      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up

      },
    },
  },

};



/**
 * The `AggStackedBarLine` component renders a combined line and stacked bar chart
 * displaying negative, neutral, and positive sentiment data along with news volume data.
 * It uses Chart.js and React-Chartjs-2 to visualize this data.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @param {Object} data - The data to be displayed in the chart.
 * @returns {JSX.Element} The rendered `AggStackedBarLine` component.
 */
export default function AggStackedBarLine({ data }) {


  const getGraphMonthYearStr = (arr) => {

    let data = [];

    if (arr?.length === 0) {
      //ignore
    } else {
      data = arr
        .map(dated => format(new Date(dated), 'dd LLL'))
        .map(dated => dated ? String(dated).toUpperCase() : dated);
    }
    //console.log('getGraphMonthYearStr', arr)

    return data;
  };


  const dataaaaaa = {
    labels: data?.length > 0 ? getGraphMonthYearStr(data.map(ele => ele.dated)) : [], //cause ignore dates where data not present
    datasets: [
      {
        label: 'Negative',
        data: data?.length > 0 ? data.map(ele => ele.neg_count) : [],
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgb(255, 0, 0, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',
      },

      {
        label: 'Neutral',
        data: data?.length > 0 ? data.map(ele => ele.neutral_count) : [],
        borderColor: 'rgb(255, 165, 0)',
        backgroundColor: 'rgb(255, 165, 0, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',
      },

      {
        label: 'Positive',
        data: data?.length > 0 ? data.map(ele => ele.pos_count) : [],
        borderColor: 'rgb(50, 205, 50)',
        backgroundColor: 'rgb(50, 205, 50, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',
      },

      {
        label: 'News Volume',
        data: data?.length > 0 ? data.map(ele => ele.y) : [],
        borderColor: 'rgb(46, 147, 250)',
        backgroundColor: 'rgb(46, 147, 250, 0.5)',
        stack: 'combined',
        yAxisID: 'B',
      }
    ]
  };


  return (<>

    <div className={`col-md-6 col-margin `}>
      <div className="outer-wraper">
        <h6 className="graph-text-heading">News&nbsp;Sentiment vs News&nbsp;Volume</h6>
        <div className="graph-container" >
          <Line options={options} data={dataaaaaa} />
        </div>
      </div>
    </div>

  </>);
}
