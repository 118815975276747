/**
 * Action creator function to get a single tweet's index.
 *
 * This function is called in \src\Component\Search\Search.tsx
 * This function is called in \src\Views\KnowledgeGraph\KnowledgeGraph.tsx
 * This function in called in \src\Views\News\News.tsx
 * This function is called in \src\Views\NewsCount\NewsCount.tsx
 * This function is called in \src\Views\Overview\Overview.tsx
 * This function is called in \src\Views\Prediction\Prediction.tsx
 * This function is called in \src\Views\TechnicalAnalysis\TechnicalAnalysis.tsx
 * This function is called in \src\Views\TodayTweets\TodayTweets.tsx
 * 
 * @param {any} data - The data payload for the action.
 * @returns {Object} An action object with type and payload.
 */
const TweetsList = (data: any) => {
  return {
    type: "GET_SINGLE_TWEETS_INDEX",
    payload: data,
  };
};
/**
 * Action creator function to identify a tweet by a word cloud.
 *
 * @param {any} data - The data payload for the action.
 * @returns {Object} An action object with type and payload.
 */
export const identifyTweet = (data: any) => {
  return {
    type: "IDENTIFY_TWEET_BY_WORD_CLOUD",
    payload: data,
  };
};
/**
 * Action creator function to get all tweet items.
 *
 * This function is called in \src\Views\TodayTweets\TodayTweets.tsx
 * 
 * @param {any} data - The data payload for the action.
 * @returns {Object} An action object with type and payload.
 */
export const getAllTweetItem = (data: any) => {
  return {
    type: "GET_ALL_TWEET_ITEM",
    payload: data,
  };
};

export default TweetsList;
