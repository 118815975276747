import { ReportHandler } from 'web-vitals';
/**
 * Web Vitals Reporting Function.
 *
 * This function reports web vitals data such as CLS, FID, FCP, LCP, and TTFB to the provided `onPerfEntry` handler.
 * It uses the `web-vitals` library to gather and report these metrics when available.
 *
 * This function is called in \src\index.tsx
 * 
 * @param {ReportHandler|undefined} onPerfEntry - The handler function to which web vitals data is reported.
 */
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
