/**
 * ShowPdf reducer handles the state related to the URL of a PDF document.
 * It stores the URL of the currently selected PDF document.
 *
 * @param {string} state - The current URL of the selected PDF document.
 * @param {Object} action - The action object containing the type and payload.
 * @returns {string} - The updated URL of the selected PDF document.
 */
const ShowPdf = (state = "", action: any) => {
  switch (action.type) {
    case "GET_PDF_URL":
      return (state = action.payload);

    default:
      return state;
  }
};

export default ShowPdf;
