// export type FilterSelectProps = {
//   options: string[];
//   value: string;
//   placeholder: string;
//   onChange?: (value: string) => void;
//   disabledOptions?: string[];
// };

// const FilterSelect = ({
//   options,
//   value,
//   placeholder,
//   onChange,
//   disabledOptions = [],
// }: FilterSelectProps) => {
//   return (
//     <div className="filter">
//       <select
//         defaultValue={value ? value[0] : ""}
//         onChange={(e) => {
//           // console.log(e.target.value);
//           if (onChange) {
//             onChange(e.target.value);
//           }
//         }}
//       >
//         <option value="" disabled>
//           {placeholder}
//         </option>
//         {options.map((item, i) => (
//           <option key={i} value={item} disabled={disabledOptions.includes(item)}>
//             {item}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default FilterSelect;



export type FilterSelectProps = {
  options: string[];
  value: string;
  placeholder: string;
  onChange?: (value: string) => void;
  disabledOptions?: string[];
};

const FilterSelect: React.FC<FilterSelectProps> = ({
  options,
  value,
  placeholder,
  onChange,
  disabledOptions = [],
}) => {
  return (
    <div className="filter">
      <select
        value={value} // Use value prop instead of defaultValue
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((item, i) => (
          <option key={i} value={item} disabled={disabledOptions.includes(item)}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterSelect;

