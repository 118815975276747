import ReactApexChart from "react-apexcharts";
import React from 'react';
import './HeatMapChart.scss';



const optionss = {
  legend: {
    show: true,
    position: 'right',
    fontSize: '12px',
    markers: {
      width: 20,
      height: 20,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 2,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0
    },
    itemMargin: {
      horizontal: 5,
      vertical: 0
    },
  },
  xaxis: {
    labels: {
      rotate: -40,
      rotateAlways: true,
      hideOverlappingLabels: false,
      style: {

        fontSize: '9px',
      },
    }
  },

  chart: {
    //height: 350,
    type: 'heatmap',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 1
  },
  //colors: ["#008FFB"],
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      //shadeIntensity: 1,
      radius: 0,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          //  {
          //   from: 0,
          //   to: 40,
          //   // name: 'low',
          //   color: '#80c8ff'

          // },



          {
            from: 0,
            to: 2,
            color: '#80C8FF'

          },

          {
            from: 3,
            to: 5,
            color: '#74BFF8'

          },

          {
            from: 6,
            to: 8,
            color: '#69B6F1'

          },

          {
            from: 9,
            to: 11,
            color: '#5DADEA'

          },

          {
            from: 12,
            to: 14,
            color: '#51A4E3'

          },

          {
            from: 15,
            to: 17,
            color: '#469BDC'

          },

          {
            from: 18,
            to: 20,
            color: '#3A93D6'

          },

          {
            from: 21,
            to: 23,
            color: '#2F8ACF'

          },


          {
            from: 24,
            to: 26,
            color: '#2381C8'

          },


          {
            from: 27,
            to: 200,
            color: '#1778C1'

          },



        ]
      }
    }
  },

};


const modifiedOptions = { ...optionss, legend: { ...optionss.legend, show: false } };
//console.log('obj----', modifiedOptions.legend.show, optionss.legend.show)
/**
 * The `HeatMapChart` component displays a heatmap chart using the ReactApexChart library.
 * It visualizes data with varying color intensities to represent event occurrences over time.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @component
 * @param {Object} props - The component's props, including data for the heatmap chart and screen width.
 * @returns {JSX.Element} The rendered `HeatMapChart` component.
 */
class HeatMapChart extends React.Component {
  constructor(props) {
    super(props);
    //the color of the box shows how many times the event has occured on that day, darker the color, more the frequency
    //Math.floor(Math.random() * (max - min + 1)) + min   


    //check if missing date shows
    this.state = {

      series: this.props.data,
      options: this.props.screenWidth < 600 ? modifiedOptions : optionss


    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data || prevProps.screenWidth !== this.props.screenWidth) {
      this.setState({ series: this.props.data, options: this.props.screenWidth < 600 ? modifiedOptions : optionss });
    }


  }



  render() {
    return (

      <div className={`col-md-12 HeatMapChart `}>
        <div className="outer-wraper">
          <h6 className="graph-text-heading" >Heat Map&nbsp;Chart</h6>
          <div className="graph-container">
            <ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height={400} />
          </div>
        </div>
      </div>


    );
  }
}


export default HeatMapChart;


// series: [
//     {
//       name: '2023-05-26',
//       data: [
//         {
//           x: "Partnership & JointVenture",
//           y: Math.floor(Math.random() * (200 - 0 + 1)) + 0
//         },
//         {
//           x: "Share Repurchase",
//           y: Math.floor(Math.random() * (200 - 0 + 1)) + 0
//         },
//         {
//           x: "Macroeconomics",
//           y: Math.floor(Math.random() * (200 - 0 + 1)) + 0
//         },
//         {
//           x: "Debt",
//           y: Math.floor(Math.random() * (200 - 0 + 1)) + 0
//         },
//     ],

//     },


// ],
