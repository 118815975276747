import "./NewsletterDialog.scss";
import tickerNamesConstant from "../../../data/tickerNamesConstant";
import { useRef, useState } from "react";
import jsonpPromise from "../../../utils/Common/jsonpPromise";

type NewsletterDialogProps = {
  dialogRef: React.RefObject<HTMLDialogElement>;
};

const NewsletterDialog = ({ dialogRef }: NewsletterDialogProps) => {
  const [formRes, setformRes] = useState<{
    status: "loading" | "ok" | "error";
    message: string | undefined;
  } | null>(null);

  const formRef = useRef<HTMLFormElement>(null);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("Form submitted");
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    data["group[147][1]"] = "true";
    // console.log(data);
    const postUrl =
      "https://algoanalytics.us21.list-manage.com/subscribe/post-json?u=8af1592f39e046313b4578233&amp;id=f095f5f234&amp;f_id=0005c3e1f0";
    // Add form data as query params to the url
    const url = new URL(postUrl);
    Object.keys(data).forEach((key) =>
      url.searchParams.append(key, data[key].toString())
    );
    console.log(url.toString());
    // Send a POST request to the url
    try {
      setformRes({
        status: "loading",
        message: "Submitting form...",
      });
      // Using jsonp to bypass CORS
      const res = (await jsonpPromise(url.toString(), {
        // Copied from lib react-mailchimp-subscribe
        param: "c",
      })) as { result: string; msg: string };

      // const resJson = await res.text();
      // console.log(res);

      // console.log("Form submitted successfully");
      setformRes({
        status: "ok",
        message: res.msg || "Form submitted successfully",
      });
      // Close the dialog after 5 seconds
      setTimeout(() => {
        dialogRef.current?.close();
        // Reset the form status and values
        setformRes(null);
        formRef.current?.reset();
      }, 3000);
    } catch (err) {
      console.log(err);
      setformRes({
        status: "error",
        message: "Something went wrong. Please try again later.",
      });
      // Reset the form status after 5 seconds
      setTimeout(() => {
        setformRes(null);
      }, 5000);
    }
  };

  // Change the color of the submit button based on the form status
  // Null == dont change color
  let submitButtonBgColor: string | undefined = undefined;
  if (formRes?.status === "ok") {
    submitButtonBgColor = "#32a852";
  } else if (formRes?.status === "error") {
    submitButtonBgColor = "#ce1537";
  } else if (formRes?.status === "loading") {
    submitButtonBgColor = "#e3e354";
  }

  return (
    <dialog ref={dialogRef} className="newsletter-dialog">
      {/* Close button */}
      <div className="close-div">
        <button
          className="close-button"
          onClick={() => dialogRef.current?.close()}
        >
          X
        </button>
      </div>
      {/* Input form */}
      <form onSubmit={onFormSubmit} action="" method="post" ref={formRef}>
        <h3 className="title">Join a community of savvy investors</h3>
        {/* Email */}
        <div className="email">
          <input
            type="email"
            name="EMAIL"
            id="email"
            placeholder="Email address"
          />
        </div>
        {/* First Name */}
        <div className="first-name">
          <input
            type="text"
            name="FNAME"
            id="first-name"
            placeholder="First Name"
          />
        </div>
        {/* Last Name */}
        <div className="last-name">
          <input
            type="text"
            name="LNAME"
            id="last-name"
            placeholder="Last Name"
          />
        </div>

        {/* Stock selection */}
        <h4 className="stock-title">Please Select at least 1 stock</h4>

        {/* Stock dropdowns */}
        {[1, 2, 3].map((stockNumber) => (
          <div className="stock" key={stockNumber}>
            <label htmlFor="stock">{`Stock ${stockNumber}`}</label>
            <select
              name={`STOCK${stockNumber}`}
              id={`stock${stockNumber}`}
              // defaultValue={"NONE"}
            >
              <option value="None">Select stock from Dropdown</option>
              {tickerNamesConstant.map((tickerName) => (
                <option key={tickerName} value={tickerName}>
                  {tickerName}
                </option>
              ))}
            </select>
          </div>
        ))}

        {/* Checkbox */}
        <h4 className="tnc-title">Agreement</h4>
        <div className="tnc">
          <input
            type="checkbox"
            name="group[147][1]"
            id="tnc"
            required={true}
          />
          <label htmlFor="tnc">
            I agree to receive daily email newsletter from AlgoFabric for my
            chosen stocks.
          </label>
        </div>

        {/* Submit button */}
        <button
          className="submit"
          type="submit"
          disabled={!(formRes === null || formRes.status === "error")}
          style={{
            backgroundColor: submitButtonBgColor,
          }}
        >
          {formRes === null ? "Submit" : formRes.message}
        </button>
      </form>
    </dialog>
  );
};

export default NewsletterDialog;
