import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

import Navbar from "../Component/LandingV2/Navbar";
import Footer from "../Component/LandingV2/Footer";

import { UserInfo } from "../context/AuthContext";
import hashSHA256 from "../utils/hashSHA256";
import "./LoginPage.scss";

export interface LoginPageProps {
  setUserInfo: (userInfo: UserInfo) => void;
}

const LoginPage = (props: LoginPageProps) => {
  // Form state
  const [loadingState, setLoadingState] = useState(false);
  const [messageState, setMessageState] = useState<string | null>(null);
  const [errorState, setErrorState] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Dont refresh page
    e.preventDefault();
    // Reset states
    setLoadingState(true);
    setErrorState(null);
    setMessageState(null);

    try {
      // Setting explicit type for e.target to prevent using external libraries
      const eTarget = e.target as typeof e.target & {
        email: { value: string };
        password: { value: string };
      };

      // Destructure values for easier access
      const [email, password] = [eTarget.email.value, eTarget.password.value];
      // console.log(username, email, password, confirmPassword);

      // Send post request to backend
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_URL || "http://localhost:3000"}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            passwordHash: await hashSHA256(password),
            // passwordHash: password,
            scope: ["default"],
          }),
        }
      );

      const resData = await res.json();
      console.log(resData);
      // If successful, redirect to login page
      if (res.ok) {
        setMessageState("User verified successfully 🎉 Redirecting to Home");
        // Set user info
        props.setUserInfo({
          username: resData.username,
          email: resData.email,
          scopes: resData.scopes, //Including scope here..
        });
        // Redirect to login page
        setTimeout(() => {
          console.log("Redirecting now");
          // window.location.href = "/";
          navigate("/");
        }, 2000);
      } else {
        // If unsuccessful, display error message
        throw new Error(resData?.message || "Could not verify user");
      }
    } catch (err: any) {
      setErrorState(err?.message || "Could not verify user");
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <>
      <Navbar />

      <div className="">
        {/* Intro + tagline */}
        <div className="login-page">
          {/* Background Image */}
          <div className="modal-backdrop"></div>
          <div className="login-background-image">
            <img
              src="/assets/images/login/background_1108.png"
              alt="Background"
            />
          </div>

          {/* Form card */}
          <div className="login-form-card">
            <h2 className="login-title">Welcome Back</h2>

            <form onSubmit={onSubmit} className="login-form">
              <input
                className="input-field"
                type="text"
                name="email"
                placeholder="Email"
              />

              {/* Show Password feature */}
              <div className="password-field">
                <input
                  className="input-field"
                  type={showPassword ? "text" : "password"} // Toggle (text:password)
                  name="password"
                  placeholder="Password"
                />
                <span
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Icon
                    icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                    className="password-icon"
                  />
                </span>
              </div>

              {/* Forgot Password link */}
              <div className="forgot-password">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>

              <button
                type="submit"
                className="login-button"
                disabled={loadingState}
              >
                {loadingState && (
                  <Icon icon="akar-icons:loading" className="animate-spin" />
                )}
                Login
              </button>
            </form>

            {errorState && <p className="error-message">{errorState}</p>}
            {messageState && <p className="success-message">{messageState}</p>}

            {/* Don't have an account? */}
            <div className="redirect-register">
              Don't have an account?{"  "}
              <Link to="/register">Register</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LoginPage;
