
//show last 1 quarter

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getGraphDataArr, getGraphLabels } from '../../../../utils/CompanyDocs';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      //align: "start",
    }
  },
  responsive: true,

};

/**
 * A React component for displaying a doughnut chart representing data for the last quarter.
 *
 * This component takes data and generates a doughnut chart with
 * categories on the chart and corresponding values for the last quarter.
 *
 * This function is called in \src\Views\CompanyDocSummary\CompanyDocSummary.jsx
 * 
 * @param {Object} data - The data used to generate the chart.
 * @param {string} lastQuarter - The name of the last quarter.
 * @returns {JSX.Element} - A React component representing the doughnut chart.
 */
const DoughnutComp = ({ data, lastQuarter }) => {

  const property = `Category of shareholder`;

  //Step 1 ---> get last quarter month name
  const month = lastQuarter?.length > 0 ? lastQuarter : ''; //get current quarter month
  
  //Step 2 ---> get types like 'public', 'Promoter & Promoter Group'
  const graphLabels = getGraphLabels(data);

  //Step 3 ---> get last quarter month values and sort it
  const dataArr = getGraphDataArr(data, property, month);

  const graphData = {
    labels: graphLabels,
    datasets: [
      {
        label: `${month}`,
        data: dataArr,
        backgroundColor: [
          'rgba(255, 118, 74, 1)',
          'rgb(122, 81, 149, 1)',
          'rgb(255, 166, 0, 1)',
        ],
        borderColor: [
          'rgba(255, 118, 74, 1)',
          'rgb(122, 81, 149, 1)',
          'rgb(255, 166, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  //console.log(`DoughnutComp`, month, data );

  return <Doughnut
    data={graphData}
    options={options}
  />;
}


export default DoughnutComp;