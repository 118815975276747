import React from "react";
import Plot from "react-plotly.js";

import "./PeerComparison.scss";

import { ComparisonData } from "./AggregatorV2";

type PeerComparisonProps = {
  comparisonData: ComparisonData;
};

const PeerComparison = ({ comparisonData }: PeerComparisonProps) => {

  // Get the peer comparison data from the props
  // const data = comparisonData["peer_comparison_data"];
  // console.log("Comparison data fetched successfully", data);

  const { comparison_table, peer_percentChange_graph_coordinates } =
    (comparisonData as ComparisonData).peer_comparison_data || {};

  const renderTable = () => {
    if (!comparison_table) {
      return <p>No data available</p>;
    }

     // Assuming the first metric's keys represent all companies
    const companies = Object.keys(comparison_table["PRICERs."]);
    const metrics = Object.keys(comparison_table);

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Metric</th>
              {metrics.map((metric, index) => (
                <th key={index}>{metric}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => (
              <tr key={company}>
                <td>{company}</td>
                {metrics.map((metric, index) => (
                  <td key={index}>{comparison_table[metric][company]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderGraph = () => {
    if (!peer_percentChange_graph_coordinates) {
      return <p>No data available</p>;
    }

    const data: Partial<Plotly.PlotData>[] = Object.keys(
      peer_percentChange_graph_coordinates
    ).map((company) => {
      const coordinates = peer_percentChange_graph_coordinates[company];
      const dates = Object.keys(coordinates);
      const values = Object.values(coordinates);
      return {
        x: dates,
        y: values,
        type: "scatter" as const,
        mode: "lines" as const,
        name: company,
      };
    });

    return (
      <div className="graph-container">
        <Plot
          data={data}
          className="graph"
          layout={{ title: "Peer Percent Change Over Time for 1 rupee",
            legend: {
              orientation: "h",
              y: -0.2,
              x: 0.5,
              xanchor: "center"
            },
            margin: { t: 60 },
          }}
        />
      </div>
    );
  };

  return (
    <div className="navbar-container">
      <div className="peer-comparison">
        {/* <h1>Peer Comparison</h1> */}
        {renderTable()}
        {renderGraph()}
      </div>
    </div>
  );
};

export default PeerComparison;
