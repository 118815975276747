import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
// import { AnyARecord } from 'dns';
import './StackedBarLine.scss';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// function  handleHover(event, legendItem, legend){
// console.log('handleHover', event, legendItem, legend)
// }


const options:any = {
  animation: {
    duration: 0
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: "bottom",
      // onHover:handleHover
    }

  },
  maintainAspectRatio: false,
  // scales: {
  //   y: {
  //     stacked: true
  //   }
  // },
  scales: {
    x: {
      // type: 'category',
      // labels: ['January', 'February', 'March', 'April', 'May', 'June']
      ticks: {
        //autoSkip: false,
        maxRotation: 90,
        minRotation: 90
      },
    },
    A: {
      type: 'linear',
      display: true,
      position: 'left',
      suggestedMin: 0,
      // title: {
      //   display: true,
      //   text: 'News'
      // },
      // z:10,
    },
    B: {
      type: 'linear',
      display: true,
      position: 'right',
      //suggestedMin:0,
      min: 0,
      ticks: {
        stepSize: 1    //remove decimel
      },

      // title: {
      //   display: true,
      //   text: 'Volume'
      // },


      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up

      },
    },
  },

};


const getFormattedDateArr = (arr:any) => {
  const data = arr
    .map((dated:any) => format(new Date(dated), 'dd LLL'))
    .map((dated:any) => dated ? String(dated).toUpperCase() : dated);
  return data;
}


/**
 * The `StackedBarLine` component displays a line chart with stacked bar datasets.
 * It visualizes the mean sentiment score and total news volume over a period of time.
 *
 * @param {Object} data - The data for the line chart with stacked bar datasets.
 * @returns {JSX.Element} The rendered `StackedBarLine` component.
 */
export default function StackedBarLine({ data }:any) {


  //console.log('StackedBarLine', data);

  const dataaaaaa:any = {
    labels: data?.dates?.length > 0 ? getFormattedDateArr(data?.dates) : [],
    datasets: [
      {
        label: 'Negative',
        data: data?.neg_sent?.length > 0 ? data?.neg_sent.map((val:any )=> Number(val.value)) : [],
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgb(255, 0, 0, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',
      },
      {
        label: 'Neutral',
        data: data?.neu_sent?.length > 0 ? data?.neu_sent.map((val:any ) => Number(val.value)) : [],
        borderColor: 'rgb(255, 165, 0)',
        backgroundColor: 'rgb(255, 165, 0, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',
      },
      {
        label: 'Positive',
        data: data?.pos_sent?.length > 0 ? data?.pos_sent.map((val:any ) => Number(val.value)) : [],
        borderColor: 'rgb(50, 205, 50)',
        backgroundColor: 'rgb(50, 205, 50, 0.5)',
        stack: 'combined',
        type: 'bar',
        yAxisID: 'A',

      },
      {
        label: 'Volume',
        data: data?.news?.length > 0 ? data?.news.map((val:any ) => Number(val.value)) : [],
        borderColor: 'rgb(46, 147, 250)',
        backgroundColor: 'rgb(46, 147, 250, 0.5)',
        stack: 'combined',
        yAxisID: 'B',


      }
    ]
  };


  return (<>
    <div className={`News-Sentiment-Stacked-Bar-Line  col-xl-4`}>
      <div className="white-box">
        <h6>News Mean&nbsp;Sentiment&nbsp;score vs Total&nbsp;News</h6>
        <div style={{ maxHeight: 250, minHeight: 250, width: '100%' }}
          className='d-flex justify-content-center align-items-center'
        >
          <Line options={options} data={dataaaaaa} />
        </div>
      </div>
    </div>
  </>);
}
