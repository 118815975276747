
//returns a Stacked bar graph and a Doughnut chart with bullet points

import React from 'react';
import StackedBarChart from '../Graphs/CompanyDocs/StackedBarChart';
import DoughnutComp from '../Graphs/CompanyDocs/Doughnut';
import NoResultsBox from '../Common/NoResultsBox/NoResultsBox';
import { getKeys } from '../../utils/CompanyDocs';


/**
 * CompanyDocSummary Component
 * 
 * This component displays a summary of company documents, including a stacked bar graph
 * representing the shareholding pattern for the last 4 quarters and a doughnut chart with
 * bullet points summarizing insights. If no summary data is available, it displays a message.
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Array} props.summary - An array of summary data for company documents.
 * @param {Array} props.insights - An array of insights related to the summary data.
 * @returns {JSX.Element} - The rendered CompanyDocSummary component.
 */
const CompanyDocSummary = ({ summary, insights }) => {
    //console.log('insights', insights);
    const labels = summary?.length > 0 ? getKeys([...summary], 'DoughnutComp') : [];
    const lastQuarter = labels?.length > 0 ? labels[0] : '';  //last quarter for summary ---> 'DEC 2022'
    // insights={
    //     "Updated": "SEP 2023",
    //     "Insights": [
    //         "Number of Promoters increased by 2 compared to previous quarter.",
    //         "No change in shares held by the Promoters (52.63 %).",
    //         "Number of shares pledged by Promoters decreased by 0.77 %, compared to previous quarter.",
    //         "Public Investors decreased by 12241. However their holding stayed the same."
    //     ]
    // }
    return (
      <>
        {summary?.length > 0 ? (
          <div className="row mx-auto">
            <div
              className="col-lg-5 p-5  mb-5"
              style={{ background: "#fff", borderRadius: 4 }}
            >
              <div
                className="d-flex flex-column  align-items-center"
                style={{ maxHeight: 300 }}
              >
                <h6>Last 4 Quarters Shareholding pattern</h6>
                <StackedBarChart data={summary} />
              </div>
            </div>
            <div
              className="col-lg-7 p-5  mb-5 "
              style={{ background: "#fff", borderRadius: 4 }}
            >
              <div className="graph-bullet-points">
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ maxHeight: 300 }}
                >
                  <div className="doughnut-header">
                    <h6 className="doughnut-header">{`${lastQuarter} Quarter Summary`}</h6>
                  </div>

                  <DoughnutComp data={summary} lastQuarter={lastQuarter} />
                </div>
                {insights.Insights?.length > 0 ? (
                  <div
                    className="bullet-points d-flex  align-items-center"
                    //  style={{maxWidth:400}}  //if limit width on large screen
                  >
                    <ul>
                      {insights.Insights.map((insight, index) => (
                        <li key={index}>{insight}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="row mx-auto">
            <div
              className="col-12 mb-5"
              style={{
                background: "#fff",
                borderRadius: 4,
                minHeight: `150px`,
              }}
            >
              <NoResultsBox
                showEmptyImage={true}
                displayText={`No Summary Data`}
              />
            </div>
          </div>
        )}
      </>
    );
}

export default CompanyDocSummary;