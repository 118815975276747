import React from 'react';

import { CloseOutlined, MessageOutlined } from '@ant-design/icons';

/**
 * ChatHeader Component
 * 
 * This component represents the header of the chat interface, displaying the InfoBot's avatar,
 * name, and status. It also provides a close button for toggling the chat popover.
 * 
 * This function is called in \src\Views\ChatBot\CustomPopover\CustomPopover.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Function} props.togglePopover - A callback function to toggle the chat popover.
 * @returns {JSX.Element} - The rendered chat header component.
 */
const ChatHeader = ({ togglePopover }) => {
    return (

        <div className="chat-header  d-flex justify-content-between">

            <div className='combine-design'>

                <div className="avatar">
                    <MessageOutlined/>
                </div>

                <div className="header-design">
                    <h3>InfoBot</h3>
                    <p className='online'>Beta</p>
                </div>

            </div>


            <CloseOutlined
                className='popover-trigger-close'
                onClick={() => togglePopover()}
            />
        </div>

    );
}

export default ChatHeader;