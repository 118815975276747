import { useEffect, useState } from "react";
/**
 * A custom React Hook to track and retrieve window dimensions (width and height).
 * This Hook provides the current window width and height and updates them when the window is resized.
 * 
 * This function is called in \src\Component\Landing\Landing.tsx
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * This function is called in \src\Header\Header.tsx
 * This function is called in \src\Views\AnalyzedTweet\AnalyzedTweet.tsx
 * This function is called in \src\Views\AnalyzedTweet\AnalyzedTweetSearch.tsx
 * This function is called in \src\Views\Common\NoResultsBox\NoResultsBox.jsx
 * This function is called in \src\Views\Common\SearchBar\SearchBar.jsx
 * This function is called in \src\Views\Common\Sort\Sort.tsx
 * This function is called in \src\Views\CustomForm\CustomForm.jsx
 * This function is called in \src\Views\Dashboard\DashboardTableHeader\DashboardHeader.jsx
 * This function is called in \src\Views\Graphs\CandlestickPerDay\CandlestickPerDay.tsx
 * This function is called in \src\Views\Graphs\NewsAndStock\NewsAndStock.tsx
 * This function is called in \src\Views\Graphs\RandomChart\RandomChart.tsx
 * This function is called in \src\Views\Graphs\StackedBarLine\StackedBarLine.jsx
 * This function is called in \src\Views\Graphs\StockSentiment\StockSentiment.jsx
 * This function is called in \src\Views\Graphs\StockVolumeClose\StockVolumeClose.jsx
 * This function is called in \src\Views\NewsArticle\NewsArticle.tsx
 * This function is called in \src\Views\NewsArticle\NewsArticleSearch.tsx
 * This function is called in \src\Views\NewsContent\NewsContent.tsx
 * This function is called in \src\Views\NewsTopics\NewsTopics.tsx
 * This function is called in \src\Views\Common\SearchBar\SearchBar.jsx
 * This function is called in \src\Views\SearchList\NewsTopics.tsx
 * This function is called in \src\Views\TodayNews\TodayNews.tsx
 * This function is called in \src\Views\TodayTweets\TodayTweets.tsx
 * This function is called in \src\Views\TweetsContent\TweetsContent.tsx
 * This function is called in \src\Views\TweetsTopics\TweetsTopics.tsx
 * 
 * @returns {Object} An object containing the window width and height.
 * @property {number|undefined} width - The current window width.
 * @property {number|undefined} height - The current window height.
 */
const useWindowDimemsions = () => {

    const [width, setWidth]=useState(undefined);
    const [height, setHeight]=useState(undefined);


    useEffect(()=>{

        window.addEventListener("resize", handleResize);

        handleResize();

        return ()=>{
            window.removeEventListener("resize", handleResize);

        }

    }
    ,[])

    const handleResize=()=>{
        setWidth(window?.innerWidth);
        setHeight(window?.innerHeight);
    }

    return ({ width , height });
}
 
export default useWindowDimemsions;