/**
 * Redux reducer responsible for managing the state related to identifying news content.
 *
 * @param {string} state - The current identified news content state.
 * @param {Object} action - The action dispatched to update the state.
 * @returns {string} The updated identified news content state based on the action.
 */
const IdentifyNews = (state = "", action: any) => {
    switch (action.type) {
      case "GET_CONTENT":
        return (state = action.payload);
      default:
        return state;
    }
  };
  
  export default IdentifyNews;
  