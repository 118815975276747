import React from 'react';
import { Collapse, theme } from 'antd';
import axios from "axios";
import { CaretRightOutlined } from '@ant-design/icons';
import CircularProgress from "@mui/material/CircularProgress";
import Promoters from '../PromotersAndPublic/Promoters';
import NoResultsBox from '../Common/NoResultsBox/NoResultsBox';
import CompanyDocSummary from '../CompanyDocSummary/CompanyDocSummary';
import { getKeys, getTableHeaderData,getTableHeaderDataPromoter, sortTable } from '../../utils/CompanyDocs';
import './CompanyDocmnt.scss';

const { Panel } = Collapse;

//common function to fetch data from url
const fetchData = async (tickerId: any, typeofData: any) => {
  return await axios.get(`${process.env.REACT_APP_COMPANY_DOC}/${tickerId}/clean_data/${typeofData}`)
}


const CompanyDocs = () => {

  const { token } = theme.useToken();

  const [summary, setSummary] = React.useState([]);
  const [promoter, setPromoter] = React.useState([]); //original data, original unsorted data used by chart, used to get table quarter months
  const [publicc, setPublic] = React.useState([]); //original data, original unsorted data used by chart, used to get table quarter months
  const [isLoaderVisible, setIsLoaderVisible] = React.useState<boolean>(false);

  //two different tables so each has own state
  const [promoterTableData, setPromoterTableData] = React.useState([]); //sorted  data 
  const [publicTableData, setPublicTableData] = React.useState([]);
  const [insights, setInsights] = React.useState([]);


  const [sortValPromoter, setSortValPromoter] = React.useState('asc'); //default sort order table
  const [sortValPulic, setSortValPulic] = React.useState('asc');

  const [filterVal, setFilterVal] = React.useState('Name of Shareholder'); //used by promoter
  const [filterValPublic, setFilterValPublic] = React.useState('Name of Shareholder'); //used by public

  const tickerId = localStorage.getItem("tickerId"); //company name
 // console.log('tickerId-------', tickerId, typeof (tickerId));

  React.useEffect(() => {

    try {

      setIsLoaderVisible(true);

      (async () => {

        const { data: summaryData } = await fetchData(tickerId, 'Summary.json');
        setSummary(summaryData?.length > 0 ? summaryData : []);

        const { data: promoter } = await fetchData(tickerId, 'Promoter.json');
        setPromoter(promoter?.length > 0 ? promoter : []);

        const sortedPromoter: any = sortTable(promoter, 'Name of Shareholder', 'asc'); //sort on component mount
        setPromoterTableData(sortedPromoter?.length > 0 ? sortedPromoter : []);

        const { data: publicData } = await fetchData(tickerId, 'Public.json');
        setPublic(publicData?.length > 0 ? publicData : []);

        const sortedPublic: any = sortTable(publicData, 'Name of Shareholder', 'asc'); //sort on component mount
        setPublicTableData(sortedPublic?.length > 0 ? sortedPublic : []);

        const { data: iinsights } = await fetchData(tickerId, 'Insights.json');      //Insights.json used by bullet points
        setInsights(iinsights); //bullet points data

      })();

    } catch (ex) {
      //console.log('error', ex);
    } finally {
      setIsLoaderVisible(false);
    }

  }, [tickerId])

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  // console.log('publicc', publicc);
  // console.log('promoter', promoter);
  //console.log('summary', summary);



  const tableMonthsPromoter = promoter?.length > 0 ? getKeys([...promoter], 'promoterTable') : [];  //months displayed by table
  const tableHeaderDataPromoter = getTableHeaderDataPromoter(tableMonthsPromoter); 

  const tableMonthsPulic = publicc?.length > 0 ? getKeys([...publicc], 'publicTable') : [];  //months displayed by table
  const tableHeaderDataPulic = getTableHeaderData(tableMonthsPulic); 


  //console.log('tables data', tableHeaderDataPromoter, tableHeaderDataPulic);


  const handleSortTable = (filterName: any, mode: any, sortFilter: any) => {
    // console.log('sortFilter----', sortFilter);

    const valued = sortFilter === 'asc' ? 'desc' : 'asc'; //toggle sort order

    if (mode === 'promoter' && promoter?.length > 0) {
      const copyArr = [...promoter]; //original data
      const newData: any = sortTable(copyArr, filterName, valued);
      setPromoterTableData(newData);
      setSortValPromoter(valued);
      setFilterVal(filterName); //`Name of Shareholder` or  'DEC 2022' or  'MAR 2021' ...
    }

    if (mode === 'public' && publicc?.length > 0) {
      const copyArr = [...publicc]; //original data 
      const newData: any = sortTable(copyArr, filterName, valued);
      setPublicTableData(newData);
      setSortValPulic(valued);
      setFilterValPublic(filterName);
    }

  }

  //  console.log('state', sortValue, filterVal)

  return (
    <>
      <div className="custom-company-doc">
        <section>
          <div className='container-fluid'>

            <CompanyDocSummary
              summary={summary}
              insights={insights}
            />

            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              style={{ background: token.colorBgContainer}}
            >
              <Panel header="Promoters (No of shares)" key="1" style={panelStyle}>
                {promoter?.length > 0 ?
                  <Promoters
                    onSort={handleSortTable}
                    mode={'promoter'}
                    data={promoter} //all data --used by chart, original unsorted data used by chart
                    tableHeaderData={tableHeaderDataPromoter}
                    tableData={promoterTableData} //all data ---used by table 
                    sortFilterVal={sortValPromoter}
                    filterVal={filterVal}
                  />
                  :
                  <NoResultsBox
                    showEmptyImage={true}
                    displayText={`No Promoter Data`}
                  />
                }
              </Panel>

              <Panel header="Public (No of shares)" key="2" style={panelStyle}>
                {publicc?.length > 0 ?
                  <Promoters
                    onSort={handleSortTable}
                    mode={'public'}
                    data={publicc}
                    tableHeaderData={tableHeaderDataPulic}
                    tableData={publicTableData}
                    sortFilterVal={sortValPulic}
                    filterVal={filterValPublic}
                  />
                  :
                  <NoResultsBox
                    showEmptyImage={true}
                    displayText={`No Public Data`}
                  />
                }
              </Panel>
            </Collapse>

          </div>

          {isLoaderVisible && (
            <div className="loader">
              {" "}
              <CircularProgress />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default CompanyDocs;
