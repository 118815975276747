import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

import Navbar from "../Component/LandingV2/Navbar";
import Footer from "../Component/LandingV2/Footer";
// import hashSHA256 from "../helperFunctions/hashSHA256";

import { UserInfo } from "../context/AuthContext";
import hashSHA256 from "../utils/hashSHA256";
import "./RegisterPage.scss";

export interface RegisterPageProps {
  setUserInfo: (userInfo: UserInfo) => void;
}

const RegisterPage = (props: RegisterPageProps) => {
  // Form state
  const [loadingState, setLoadingState] = useState(false);
  const [messageState, setMessageState] = useState<string | null>(null);
  const [errorState, setErrorState] = useState<string | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Dont refresh page
    e.preventDefault();
    // Reset states
    setLoadingState(true);
    setErrorState(null);
    setMessageState(null);

    try {
      // Setting explicit type for e.target to prevent using external libraries
      const eTarget = e.target as typeof e.target & {
        email: { value: string };
        password: { value: string };
        confirmPassword: { value: string };
      };

      // Destructure values for easier access
      const [username, email, password, confirmPassword] = [
        eTarget.email.value.split("@")[0],
        eTarget.email.value,
        eTarget.password.value,
        eTarget.confirmPassword.value,
      ];
      // console.log(username, email, password, confirmPassword);

      // Validate password is the same as confirm password
      if (password !== confirmPassword) {
        setErrorState("Confirm Passwords do not match");
        return;
      }

      // Send post request to backend
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_URL || "http://localhost:3000"}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            username,
            email,
            passwordHash: await hashSHA256(password),
            // passwordHash: password,
          }),
        }
      );

      const resData = await res.json();
      console.log(resData);
      // If successful, redirect to register page
      if (res.ok) {
        setMessageState(
          "User registered successfully 🎉 Please Verify your Email and Login."
        );
        // Set user info
        // props.setUserInfo({
        //   username: resData.username,
        //   email: resData.email,
        //   // scopes: resData.scopes,
        // });
        // Redirect to register page
        setTimeout(() => {
          console.log("Redirecting now");
          // window.location.href = "/";
          navigate("/");
        }, 2000);
      } else {
        // If unsuccessful, display error message
        throw new Error(resData?.message || "Could not register user");
      }
    } catch (err: any) {
      setErrorState(err?.message || "Could not register user");
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <>
      <Navbar />

      <div className="">
        {/* Intro + tagline */}
        <div className="register-page">
          {/* Background Image */}
          <div className="modal-backdrop"></div>
          <div className="register-background-image">
            <img
              src="/assets/images/login/background_1108.png"
              alt="Background"
            />
          </div>
          {/* Form card */}
          <div className="register-form-card">
            <h2 className="register-title">
              {/* <Icon icon="ic:round-register" className="icon" /> */}
              Create Account
            </h2>
            <form onSubmit={onSubmit} className="register-form">
              <input
                className="input-field"
                type="text"
                name="email"
                placeholder="Email"
              />
              <input
                className="input-field"
                type="password"
                name="password"
                placeholder="Password"
              />
              <input
                className="input-field"
                type="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
              />

              {/* Agree to the terms and conditions */}
              <label htmlFor="terms" className="terms">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="terms-checkbox"
                />
                I have read and agreed to the Terms of Service and Privacy
                Policy
              </label>

              <button
                type="submit"
                className="register-button"
                disabled={loadingState}
              >
                {
                  // If loading, show spinner
                  loadingState && (
                    <Icon icon="akar-icons:loading" className="animate-spin" />
                  )
                }
                Create Account
              </button>
            </form>
            {errorState && <p className="error-message">{errorState}</p>}

            {messageState && <p className="success-message">{messageState}</p>}

            {/* Already have an account? */}
            <div className="redirect-login">
              Already have an account?{"  "}
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterPage;
