import { NavLink ,Link} from "react-router-dom";
import { useEffect, useState } from "react";
import "./SubNavbar.scss";

export interface NavItem {
  name: string;
  href?: string;
  triggerFunction?: () => void;
  isActive?: boolean;
  dropdown?: NavItem[];
}

const SubNavbar = ({ navData }: { navData: NavItem[] }) => {

  const [activeDropdown, setActiveDropdown] = useState<String | null>(null);
  const [dropdownTimeoutId, setDropdownTimeoutId] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      // Clear timeout when component unmounts
      if (dropdownTimeoutId) {
        clearTimeout(dropdownTimeoutId);
      }
    };
  }, [dropdownTimeoutId]);

  const handleMouseEnter = (item: NavItem) => {
    if (item.dropdown) {
      setActiveDropdown(item.name);
      // console.log(`Dropdown set for: ${item.name}`);
    }
  };

  const handleMouseLeave = (item: NavItem) => {
    if (item.dropdown) {
      const timeoutId = setTimeout(() => {
        setActiveDropdown(null);
        // console.log(`Dropdown unset for: ${item.name}`);
      }, 300); // Adjust delay as needed
      setDropdownTimeoutId(timeoutId);
    }
  };

  const handleMouseEnterDropdown = () => {
    // Prevent hiding dropdown when mouse enters it
    if (dropdownTimeoutId) {
      clearTimeout(dropdownTimeoutId);
    }
  };

  return (
    <section className="stock-selection-top">
      <div className="subNavbar">
        <ul className="nav-items">
          {navData.map((item, index) => (
            <li
              key={index}
              className="nav-link"
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
            >
              {item.triggerFunction ? (
                <button
                className={item.isActive ? "active" : ""}
                onClick={item.triggerFunction}>{item.name}</button>
              ) : (
                <NavLink
                  to={item.href ? item.href : "#"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {item.name}
                </NavLink>
              )}
              {item.dropdown && activeDropdown === item.name && (
                <ul
                  className="dropdown"
                  onMouseEnter={handleMouseEnterDropdown}
                >
                  {item.dropdown.map((subItem, subIndex) => (
                    <li key={subIndex} className="dropdown-item">
                      {item.triggerFunction ? (
                        <button
                        onClick={item.triggerFunction}>{item.name}</button>
                      ) : (
                        <Link to={subItem.href ? subItem.href : "#"}>
                          {subItem.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SubNavbar;
