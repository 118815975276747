
import './Dots.css';
/**
 * Dots Component
 * 
 * This component represents a loading animation consisting of three animated dots.
 * It is used to indicate ongoing background processes or loading states.
 * 
 * This function is called in src\Views\ChatBot\ChatBody\ChatBody.jsx
 * 
 * @component
 * @returns {JSX.Element} - The rendered dots component.
 */
function Dots(props) {

//3 dots loading animation

  return (
    <div className="dots-container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>

  );

}

export default Dots;
