import './NewsClasses.scss';


//told max 10 tems in data array

const allowedColors = ['#FFCCE1', '#EFDAFF', '#DAFFDC', '#FFEADA'];

const colorsArr = [...allowedColors, ...allowedColors, ...allowedColors]; //array of length 12

//console.log('colorsArr', colorsArr)


const removeSpace = (item:any) => {
    let str:any = item;

    if (str.indexOf(" ") >= 0) {
        str = str.split(' ').join('\u00A0')
    }

    return str;
};
/**
 * The `NewsClasses` component displays news types with highlighted backgrounds.
 *
 * This function is called in \src\Views\NewsArticle\NewsArticle.tsx
 * 
 * @param {Array} data - An array of news types to be displayed.
 * @returns {JSX.Element} The rendered `NewsClasses` component.
 */
const NewsClasses = ({ data }:any) => {
    return (
        <div className="insider-child mb-3">
            <div className="header mt-3 mb-3">
                <h6>News Type</h6>
            </div>

            <div className="background-white p-3">

                {data?.length > 0 ?
                    <div className="new-classes-grid-container">
                        {data.map((item:any, index:any) =>
                            <div className="flex-item" key ={index}>
                                <span style={{ background: colorsArr[index] }} className="highlight-text mb-3">
                                    <span className="unhighlight">{removeSpace(item)}</span>
                                </span>
                            </div>
                        )}
                    </div>

                    :
                    <p className="new-classes-no-news" >No Data</p>
                }

            </div>
        </div>
    );
}

export default NewsClasses;