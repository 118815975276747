import { BacktestingData } from "./NiftyStatergy";
import './BackTestingTable.scss'

interface TableProps {
  data: BacktestingData | null;
}

const Table = ({ data }: TableProps) => {
  if (!data?.Backtesting) {
    return null;
  }
  const getTableData = () => {
    // Provide a fallback empty object if data.Backtesting is undefined or null
    const backtestingData = data?.Backtesting || {};
    return Object.keys(backtestingData).map((key) => {
      return {
        parameter: key,
        value: backtestingData[key],
      };
    });
  };
  const tableData = getTableData();
  console.log(tableData);
  return (
    <div className="backtesting-table">
      <div className="title">Back Testing Result</div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Parameters</th>
            <th>Values</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data) => (
            <tr key={data.parameter}>
              <td>{data.parameter}</td>
              <td>{data.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
