import {Bar,Line} from "react-chartjs-2";
import{
    Chart,Filler
} from "chart.js";
import "./FinancialInsights.scss";

Chart.register(Filler);

const ProfitLossGraph = ({graphData}) => {
    let profitLossGraphData = null;
 if (graphData) {
    const profitLossBarData = graphData.profit_loss_bar_coordinates;
    const profitLossAreaData = graphData.profit_loss_area_coordinates;
    
    const barLabels = Object.keys(
      profitLossBarData[Object.keys(profitLossBarData)[0]]
    );
    
    const areaLabels = Object.keys(
        profitLossAreaData[Object.keys(profitLossAreaData)[0]]
    );
    
    const barDataset = {
      label: Object.keys(profitLossBarData)[0],
      data: Object.values(profitLossBarData[Object.keys(profitLossBarData)[0]]),
      backgroundColor: `rgba(255, 28, 28, 0.8)`,
      borderColor: `rgba(255, 28, 28, 1)`,
      borderWidth: 1,
    };

    const allValuesPositive = Object.values(profitLossAreaData[Object.keys(profitLossAreaData)[0]]).every(value => value > 0);
    
    const areaDataset = {
        fill: allValuesPositive,
        label: Object.keys(profitLossAreaData)[0],
        data: Object.values(profitLossAreaData[Object.keys(profitLossAreaData)[0]]),
        backgroundColor: `rgba(255, 28, 28, 0.8)`,
        borderColor: `rgba(255, 28, 28, 1)`,
        borderWidth: 1,
    }

    const Options = {
        indexAxis: "x",
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
            position: "right",
            },
            title: {
            display: true,
            text: "Profit Loss",
            },
        },
        };
    
    const BarGraphData = {
      labels: barLabels,
      datasets: [barDataset],
    };

    const AreaGraphData = {
        labels: areaLabels,
        datasets: [areaDataset],
    };

    profitLossGraphData = {
        BarGraphData,
        AreaGraphData,
        Options,
    };
     
 }
  return profitLossGraphData ? (
    <>
    <div className="graph">
      <Bar data={profitLossGraphData.BarGraphData} options={profitLossGraphData.Options} />
    </div>
    <div className="graph">
      <Line data={profitLossGraphData.AreaGraphData} options={profitLossGraphData.Options} />
    </div>
    </>
    
    ) : null;

};

export default ProfitLossGraph;
