/**
 * ChatFooter Component
 * 
 * This component represents the footer of the chat interface, displaying a "Powered by AlgoFabric" message.
 * 
 * This function is called in \src\Views\ChatBot\CustomPopover\CustomPopover.jsx
 * 
 * @component
 * @returns {JSX.Element} - The rendered chat footer component.
 */
const ChatFooter = () => {
    return (
        <div className="chat-footer">
            <p><span>Powered by </span>AlgoFabric</p>
        </div>
    );
}

export default ChatFooter;