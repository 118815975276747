import React from 'react';
import moment from 'moment';
import Sort from '../../Common/Sort/Sort';
import CardSearch from '../../Common/CardSearch';
import rightArrow from "../../../assets/images/icons/rightArrow.png";
import './AggregatorNewsTab.scss';
import NoResultsBox from '../../Common/NoResultsBox/NoResultsBox';


const sortSentiment = [
    "Date: Newest to Oldest",
    "Date: Oldest to Newest",

    
//removed cause sentiment not present in data yet

    // "Sentiment: Positive to Negative", 
    // "Sentiment: Negative to Positive",
];



/**
 * The `AggregatorNewsTab` component displays a tab containing aggregated news data.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @param {Object} data - The array of news data to display.
 * @param {boolean} open - Indicates whether the search card is open.
 * @param {Function} onOpenChange - Callback to change the search card's open state.
 * @param {Function} onShow - Callback to show the search card.
 * @param {Object} optionsData - Data for options in the search card.
 * @param {Function} onSearchChange - Callback for when the search query changes.
 * @param {string} searchValue - The current search query.
 * @param {Function} onsortByScore - Callback for sorting news by sentiment score.
 * @returns {JSX.Element} The rendered `AggregatorNewsTab` component.
 */
const AggregatorNewsTab = ({ data, open, onOpenChange, onShow, optionsData, onSearchChange, searchValue, onsortByScore }) => {

    //console.log('AggregatorNewsTab', data);

    return (
        <>
            <div className="aggregator-news-tab">

                <div className="header">
                    <h6 style={{ margin: 0, paddingRight: '2px' }}>News&nbsp;</h6>

                    <div className="d-flex justify-content-center align-items-center ">

                        <span className="Search-Sources">Search Sources</span>

                        <CardSearch
                            open={open}
                            onOpenChange={onOpenChange}
                            onShow={onShow}
                            data={optionsData}
                            onSearchChange={onSearchChange}
                            value={searchValue}
                            placeholder={`Search Sources`}
                        />

                    </div>

                    <Sort
                        sortSentiment={sortSentiment}
                        sortByScore={onsortByScore}
                        title="Sort By"
                    />

                </div>

                <div className='scrollable-container'>
                    {  data.length > 0 ?
                    data.map((news, index) =>
                        <a target="_blank" rel="noreferrer" href={news?.news_link} key={index} style={{ textDecoration: 'none' }}>
                            <div
                                className='small-cards'>

                                <div className="d-flex  justify-content-between align-items-center flex-row-reverse">
                                    <span
                                        className="statusDots"
                                        style={{
                                            background:
                                                news?.news_sentiment === "negative"
                                                    ? "rgb(253, 24, 126)"
                                                    : news?.news_sentiment === "positive"
                                                        ? "#39E0A4"
                                                        : "#FEAF38",
                                        }}
                                    >
                                        &nbsp;
                                    </span>

                                    <span className="time">
                                        {news.news_date &&
                                            moment(news.news_date).format("DD-MMM-YYYY HH:mm ")}
                                    </span>

                                </div>
                                <p className="mint-text">
                                    <span>{news?.news_source || `News_Source`}</span>: {news.title}&nbsp;
                                    <img src={rightArrow} alt="right arrow" />
                                </p>
                            </div>
                        </a>
                    )
                :
               
                <NoResultsBox displayText={"No Data"} showEmptyImage={true}/>
                }

                </div>
            </div>
        </>
    );


}

export default AggregatorNewsTab;