import React from 'react';
import SearchBar from '../../Common/SearchBar/SearchBar';
import './NewsAggSearchBar.scss';
import DateRangePickerr from '../../Common/DateRangePickerr/DateRangePickerr';



/**
 * The `NewsAggSearchBar` component represents a search bar and form for news aggregation.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @param {Function} onSearchSubmit - Callback function to handle form submission.
 * @param {string} selectedCompany - The selected company name.
 * @param {Array} options - The list of company name options for selection.
 * @param {Function} onCompanyChange - Callback function for handling company name selection change.
 * @param {Object} errors - Error messages for form validation.
 * @param {string} startDate - The selected start date.
 * @param {Function} onChangeStartDate - Callback function for handling start date change.
 * @param {string} endDate - The selected end date.
 * @param {Function} onChangeEndDate - Callback function for handling end date change.
 * @returns {JSX.Element} The rendered `NewsAggSearchBar` component.
 */
const NewsAggSearchBar = ({ onSearchSumbiit, selectedCompany, options, onCompanyChange, errors,
    startDate, onChangeStartDate, endDate, onChangeEndDate }) => {
    return (
      <div className="news-aggregator">
        <form onSubmit={onSearchSumbiit}>
          <div className="search-container my-4 mx-auto ">
            <div className="aggregator-title">
              <h2>NEWS AGGREGATOR</h2>
            </div>

            <div className="search-item-conatiner">
              <label>
                Company Name <span>*</span>
              </label>
              <div className="search-item">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SearchBar
                    value={selectedCompany}
                    data={options}
                    onChange={onCompanyChange}
                    placeholder={"Search..."}
                    allowClearSearch={true}
                    styled={{
                      background: "#FFFCFC",
                      padding: "1px 0",
                      borderRadius: "4px",
                      border: `1px solid #d3d3d3`,
                      minWidth: 250,
                      margin: 0,
                    }}
                    selectStyled={{
                      minWidth: `300px`,
                      maxWidth: "300px",
                    }}
                    //styled={{}}
                    //showBorder={true}
                  />
                </div>
              </div>
              {errors.companyName && (
                <div className="alert-box">
                  <p>Company Name is Required</p>
                </div>
              )}
            </div>

            <div className="search-item-conatiner">
              <label>
                From Date<span>*</span>
              </label>
              <div className="search-item">
                <DateRangePickerr
                  value={startDate}
                  onChange={onChangeStartDate}
                />
              </div>
              {errors.startDates && (
                <div className="alert-box">
                  <p>{errors.startDatesErrMsg}</p>
                </div>
              )}
            </div>

            <div className="search-item-conatiner">
              <label>
                Till date<span>*</span>
              </label>
              <div className="search-item">
                <DateRangePickerr value={endDate} onChange={onChangeEndDate} />
              </div>
              {errors.endDates && (
                <div className="alert-box">
                  <p>{errors.endDatesErrMsg}</p>
                </div>
              )}
            </div>

            <div className="search-button-style">
              <button className="custom-submit" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    );
}

export default NewsAggSearchBar;