/**
 * NavigateMenu reducer handles the state related to navigation menu in the application.
 * It keeps track of the selected menu item or index in the root menu.
 */
const NavigateMenu = (state = 0, action: any) => {
  switch (action.type) {
    case "NAVIGATE_ROOT_MENU":
      return (state = action.payload);
    default:
      return state;
  }
};

export default NavigateMenu;
