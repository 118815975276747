import { Link } from "react-router-dom";
import "./HomeSection.scss";
import { AlertData } from "../../types/alerts.json";
import { useEffect, useState } from "react";
import axios from "axios";
import StockSelectionDropdown from "./StockSelectionDropdown";

const HomeSection = () => {
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [finalStocks, setFinalStocks] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: summaryData } = await axios.get(
          `${process.env.REACT_APP_API_URL}/summary.json`
        );

        if (summaryData?.summary) {
          const arr = summaryData.summary.map((item: any) => ({
            value: item.Stock_Ticker,
            label: item.Stock_Ticker,
          }));
          //sort by label
          arr.sort((a: any, b: any) => a.label.localeCompare(b.label));
          setOptionsData(arr);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const fetchAlertData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_COMPANY_DOC || "") + "/Alerts/alerts_count.json"
      );
      const data: AlertData = await response.json();
      // console.log(data);
      const filteredStocks: any[] = [];

      Object.entries(data).forEach(([ticker, alerts]: [string, any[]]) => {
        // Count numerical alerts (price change, volume change, ADX, ATR, momentum change, VWAP)
        let count = 0;
        alerts.forEach((alert) => {
          if (
            alert.price_change_alerts ||
            alert.StockVolume_change ||
            alert.ADX_change ||
            alert.ATR_change ||
            alert.VWAP_change ||
            alert.Momentum_change ||
            alert.AD_alert ||
            alert.dpo_alert
          ) {
            count++;
          }
        });

        // Only include stocks with at least 2 numerical alerts
        if (count === 2) {
          filteredStocks.push({
            ticker,
            alerts,
          });
        }
      });
      // console.log("Filtered Stocks: ", filteredStocks);

      // take only 4 stocks to pass in component
      // Get alert ui value for each stock
      const finalStocks = filteredStocks
        .slice(0, 4)
        .map(({ ticker, alerts }) => {
          const alertsModified = alerts.flatMap((alert: any) => {
            // Get the key and value of the alert
            const [alertKey, alertData]: any[] = Object.entries(alert)[0];
            return Object.entries(alertData).flatMap(([key, value]: any) => {
              if (alertKey === "price_change_alerts") {
                return key === "alerts_open_highest"
                  ? {
                      name: "Open Price",
                      value: value.live_day_open,
                      type: "high",
                    }
                  : key === "alerts_open_lowest"
                  ? {
                      name: "Open Price",
                      value: value.live_day_open,
                      type: "low",
                    }
                  : key === "alerts_close_highest"
                  ? {
                      name: "Close Price",
                      value: value.live_day_close,
                      type: "high",
                    }
                  : key === "alerts_close_lowest"
                  ? {
                      name: "Close Price",
                      value: value.live_day_close,
                      type: "low",
                    }
                  : key === "alerts_price_change"
                  ? { name: "Price Change", value: value.percentage_change }
                  : key === "top_n_price_change"
                  ? { name: "Price Change", value: value.percentage_change }
                  : null;
              }

              if (alertKey === "ADX_change") {
                if (key === "weak_trend") {
                  return {
                    name: "ADX Change",
                    value: parseInt(value.ADX).toFixed(2),
                    type: "weak",
                  };
                } else if (key === "strong_trend") {
                  return {
                    name: "ADX Change",
                    value: parseInt(value.ADX).toFixed(2),
                    type: "strong",
                  };
                }
              }

              if (alertKey === "ATR_change") {
                if (key === "low_volatility") {
                  return {
                    name: "ATR Change",
                    value: parseFloat(value.Last_ATR).toFixed(2),
                    type: "low",
                  };
                } else if (key === "high_volatility") {
                  return {
                    name: "ATR Change",
                    value: parseFloat(value.Last_ATR).toFixed(2),
                    type: "high",
                  };
                }
              }

              if (alertKey === "StockVolume_change") {
                if (key === "Stock_volume_high") {
                  return {
                    name: "Stock Volume",
                    value: parseInt(value["Current Volume"]).toFixed(2),
                    type: "high",
                  };
                } else if (key === "Stock_volume_low") {
                  return {
                    name: "Stock Volume",
                    value: parseInt(value["Current Volume"]).toFixed(2),
                    type: "low",
                  };
                }
              }

              if (alertKey === "Momentum_change") {
                if (key === "Oversold") {
                  return {
                    name: "Momentum Change",
                    value: "oversold", // No value provided in the original JSX
                    type: "Oversold",
                  };
                } else if (key === "Overbought") {
                  return {
                    name: "Momentum Change",
                    value: "overbought", // No value provided in the original JSX
                    type: "Overbought",
                  };
                }
              }

              if (alertKey === "VWAP_change") {
                if (key === "Downtrend") {
                  return {
                    name: "VWAP Down",
                    value: parseInt(value.current_vwap).toFixed(2),
                    type: "Down",
                  };
                } else if (key === "Uptrend") {
                  return {
                    name: "VWAP",
                    value: parseInt(value.current_vwap).toFixed(2),
                    type: "Up",
                  };
                }
              }
              if (alertKey === "AD_alert") {
                if (key === "Accumulation") {
                  return {
                    name: "A/D",
                    value: value["Current A/D"].toFixed(2),
                    type: "Up",
                  };
                } else if (key === "Distribution") {
                  return {
                    name: "A/D",
                    value: value["Current A/D"].toFixed(2),
                    type: "Down",
                  };
                }
              }
              if (alertKey === "dpo_alert") {
                if (key === "Upward_cycle") {
                  return {
                    name: "DPO",
                    value: parseInt(value.Current_dpo).toFixed(2),
                    type: "Up",
                  };
                } else if (key === "Downward_cycle") {
                  return {
                    name: "DPO",
                    value: parseInt(value.Current_dpo).toFixed(2),
                    type: "Down",
                  };
                }
              }
            });
          });
          // console.log("Alerts Modified: " + ticker, alertsModified);
          return {
            ticker,
            alerts: alertsModified
              // Remove undefined values
              .filter((ele: any) => ele),
          };
        });
      // console.log("Final Stocks: ", finalStocks);
      setFinalStocks(finalStocks);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAlertData();
  }, []);

  if (!finalStocks.length) {
    return (
      <>
        <div className="loading">Loading...</div>
      </>
    );
  }

  return (
    <section>
      <div className="landingv2-home-section">
        {/* Main title */}
        <h1 className="home-title">
          AlgoFabric: A Financial Insights Companion
        </h1>
        <div className="home-subtitle">
          With the power of NLP, Algofabric crafts precision stock reports, the
          perfect financial strategy for your financial journey.
        </div>
        {/* Search Ticker bar */}
        <StockSelectionDropdown stocks={optionsData} />
      </div>
      <div className="trending-card-section">
        <div
          className="rock-img"
          style={{ position: "absolute", marginTop: "-10rem" }}
        >
          <img src="assets/images/landingv2/Rock.png" alt="Rock img" />
        </div>
        <div className="confidence-section" style={{ marginTop: "-30px" }}>
          {/* <div className="image-section"> */}
          <div className="selection-image" style={{ zIndex: "1000" }}>
            {finalStocks.map((stock, index) => (
              <div
                className={`${
                  (index + 1) % 2 === 0 ? "row-double-card" : "row-single-card"
                }`}
                key={index}
              >
                <div className="stock-card">
                  <h3 className="stock-title">{stock.ticker}</h3>
                  <hr className="stock-divider" />
                  <div className="stock-data">
                    {stock.alerts.map((alert: any) => {
                      console.log("Each alert: ", alert);
                      return (
                        <div className="stock-info">
                          <div
                            className={`stock-value ${
                              alert.type === "Oversold" ||
                              alert.type === "Overbought"
                                ? "yellow-text"
                                : alert.type === "high" ||
                                  alert.type === "Up" ||
                                  alert.type === "strong"
                                ? "positive"
                                : "negative"
                            }`}
                          >
                            {alert.value}
                          </div>
                          <div className="stock-label">{alert.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="selection-section">
            <div className="selection-title">
              <h1>What's Trending!</h1>
            </div>
            <div className="selection-content">
              <div className="selection-content-item">
                <div className="icon">
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 15H30M21 21H30M22 27H30M14 25C14 24.4696 14.2107 23.9609 14.5858 23.5858C14.9609 23.2107 15.4696 23 16 23C16.5304 23 17.0391 23.2107 17.4142 23.5858C17.7893 23.9609 18 24.4696 18 25C18 25.591 17.5 26 17 26.5L14 29H18M16 19V13L14 15"
                      stroke="#FE7F77"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <a className="links" href="/trending">
                  <div>
                    <h3>Real-time Market Insights</h3>
                    <p>Track stock prices, changes, and volumes instantly</p>
                  </div>
                </a>
              </div>
              <div className="selection-content-item">
                <div className="icon">
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.125 13.625H15.875C15.2783 13.625 14.706 13.8621 14.284 14.284C13.8621 14.706 13.625 15.2783 13.625 15.875V29.375C13.625 29.9717 13.8621 30.544 14.284 30.966C14.706 31.3879 15.2783 31.625 15.875 31.625H27.125C27.7217 31.625 28.294 31.3879 28.716 30.966C29.1379 30.544 29.375 29.9717 29.375 29.375V15.875C29.375 15.2783 29.1379 14.706 28.716 14.284C28.294 13.8621 27.7217 13.625 27.125 13.625H24.875M18.125 13.625C18.125 13.0283 18.3621 12.456 18.784 12.034C19.206 11.6121 19.7783 11.375 20.375 11.375H22.625C23.2217 11.375 23.794 11.6121 24.216 12.034C24.6379 12.456 24.875 13.0283 24.875 13.625M18.125 13.625C18.125 14.2217 18.3621 14.794 18.784 15.216C19.206 15.6379 19.7783 15.875 20.375 15.875H22.625C23.2217 15.875 23.794 15.6379 24.216 15.216C24.6379 14.794 24.875 14.2217 24.875 13.625M18.125 27.125V22.625M21.5 27.125V26M24.875 27.125V24.875"
                      stroke="#FE7F77"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <a className="links" href="/trending">
                  <div>
                    <h3>Comprehensive Financial Data</h3>
                    <p>Access in-depth stock information</p>
                  </div>
                </a>
              </div>
              <div className="selection-content-item">
                <div className="icon" style={{ padding: "21px" }}>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4246 5.625H5.625V23.625H20.25V14.625M15.75 19.125H10.125M16.875 5.625V3.375M20.25 6.75L22.5 4.5M21.375 10.125H23.625M10.125 14.625H15.75V10.125H10.125V14.625Z"
                      stroke="#FE7F77"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <a className="links" href="/trending">
                  <div>
                    <h3>Curated Market Intelligence</h3>
                    <p>
                      Stay informed with top stories and relevant news updates.
                    </p>
                  </div>
                </a>
              </div>
            </div>
            {/* <Link to="/trending">
              <button className="insights-button">Get Insights</button>
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
