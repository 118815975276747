import React from 'react';
import { Select } from "antd";
import useWindowDimemsions from '../../../hooks/useWindowDimesnions';
import { SearchOutlined } from '@ant-design/icons';
import "./searchBar.scss";
/**
 * SearchBar Component
 * 
 * This component represents a custom search bar that utilizes the Ant Design Select component.
 * It provides a searchable input field with options for selecting values from a list.
 * 
 * This function is called in \src\Views\Common\CardSearch\index.jsx
 * This function is called in \src\Views\Dashboard\DashboardTableHeader\DashboardHeader.jsx
 * 
 * This function is called in \src\Views\NewsAggregator\SearchBar\NewsAggSearchBar.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Array} props.data - An array of options for the Select component.
 * @param {Function} props.onChange - A callback function to handle changes in the selected value.
 * @param {string} props.value - The currently selected value.
 * @param {string} props.placeholder - The placeholder text for the search input field.
 * @param {boolean} props.allowClearSearch - A boolean indicating whether to allow clearing the search input.
 * @param {Object} props.styled - Custom styles for the search bar container.
 * @param {boolean} props.showBorder - A boolean indicating whether to show a border around the search bar.
 * @param {Object} props.selectStyled - Custom styles for the Select component.
 * @returns {JSX.Element} - The rendered SearchBar component.
 */
const SearchBar = ({ data, onChange, value, placeholder, allowClearSearch, styled, showBorder, selectStyled }) => {

    const { width } = useWindowDimemsions();
    const lgWidth = 991;

    return (
        <div className='custom-search '
         style={styled}
         >
            <Select
                notFoundContent={<p>No Results Found</p>}
                suffixIcon={<SearchOutlined style={{color:'black', cursor:'text'}}/>}
                value={value}
                bordered={showBorder}
                allowClear={allowClearSearch}
                showSearch
                size={width && width > lgWidth ? "middle": "middle"}
                onChange={onChange}
                style={selectStyled}
                placeholder={placeholder}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input.toUpperCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={data}
            />
        </div>
    );
}

export default SearchBar;


SearchBar.defaultProps = {
    placeholder: "Please search...",
    allowClearSearch:false,
    styled:{background:'#FFFCFC', padding:'2px 0', borderRadius:'4px',  border: `1px solid black`}, //default value if not specified
    showBorder:false,
    selectStyled:{ minWidth: `250px`,}
};
