import ReactApexChart from "react-apexcharts";
import React from 'react';
import './AggTreeMapChart.scss';
/**
 * The `AggTreeMapChart` component renders a TreeMap chart using the ReactApexChart library.
 * It displays data for the most frequent entities in a treemap format.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @param {Object} props - The component's props, including data for the treemap chart.
 * @returns {JSX.Element} The rendered `AggTreeMapChart` component.
 */
class AggTreeMapChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [
                {
                    data: this.props.data?.length > 0 ?  this.props.data : [],
     
                }
            ],
            options: {
                //https://apexcharts.com/docs/options/datalabels/
                dataLabels: {
                    enabled: true,  //make false to hide
                    // enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                      //shorten long names
                      const str = val && opts.dataPointIndex  && val.length > 10 ? val.slice(0, 5) + "..." : val; 
                        // const str = val && opts.dataPointIndex && opts.dataPointIndex > 6 && val.length > 10 ? val.slice(0, 5) + "..." : val; 
                        return str;
                    },
      
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Open Sans", sans-serif',
                        fontWeight: 'bold',
                        colors: ['#28231D']
                    },
     
                },
                legend: {
                    show: false
                },

                chart: {
                    //height: 350,
                    type: 'treemap',
                    toolbar: {
                        show: false,
                    },
                },
 
                colors: [
                    '#3B93A5',
                    '#F7B844',
                    '#ADD8C7',
                    '#EC3C65',
                    '#CDD7B6',
                    '#C1F666',
                    '#D43F97',
                    '#1E5D8C',
                    '#421243',
                    '#7F94B0',
                    '#EF6537',
                    '#C0ADDB'
                ],
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false
                    }
                }
            },


        };
    }

    componentDidUpdate(prevProps, prevState) {
        // check whether this.props.data has changed 
        if (prevProps.data !== this.props.data) {
           const data  = this.props.data?.length > 0 ?  this.props.data : [];
            this.setState({ series: [{ data: data }] });
        }
    }



    render() {
        return (
            <div className={`col-md-6 AggTreeMapChart-col-margin `}>
                <div className="outer-wraper">
                    <h6 className="graph-text-heading" >Most&nbsp;Frequent&nbsp;Entities</h6>
                    <div className="graph-container">
                        <ReactApexChart
                            options={this.state.options}
                            series={this.state.series}
                            type="treemap"
                            height={'100%'}  //If you provide a percentage value '100%', make sure to have a fixed height parent.
                        />
                    </div>
                </div>
            </div>
        );
    }
}



export default AggTreeMapChart;