import React, { useState, useMemo, useEffect, useRef } from "react";
import "./MutualFunds.css";
import { processData } from "./MutualFundAlert";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { FaSort } from "react-icons/fa";

const MutualFundTable = ({ mutualFunds, mutualFundsAlerts }) => {
  const { chartData, alerts } = processData(mutualFundsAlerts);
  const latestDate = Object.keys(mutualFunds).sort().reverse()[0];
  const latestData = mutualFunds[latestDate];

  const [sortOrder, setSortOrder] = useState("highest"); // AUM sort order
  const [fundSizeSortOrder, setFundSizeSortOrder] = useState("highest"); // Fund size sort order
  const [percentageSortOrder, setPercentageSortOrder] = useState("highest"); // % Change sort order
  const [dateSortOrder, setDateSortOrder] = useState("latest"); // Date sort order
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for "Select All"
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [sortColumn, setSortColumn] = useState("AUM"); // Track active sorting column

  const graphRef = useRef(null); // Ref for the graph component

  // Function to find alerts for a specific fund
  const findAlertForFund = (fundName) => {
    const trimmedFundName = fundName.trim();
    const alert = alerts.find((alert) => alert.includes(trimmedFundName));

    if (alert) {
      const parts = alert.split(" ");
      const date = parts[1]; // Extract the date from the alert
      const previousValue = parseFloat(parts[parts.length - 3]); // Previous value
      const currentValue = parseFloat(parts[parts.length - 1]); // Current value

      const absoluteChange = (currentValue - previousValue).toFixed(2); // Calculate absolute change
      const changeColor = currentValue > previousValue ? "green" : "red"; // Determine color based on change

      const arrow =
        currentValue > previousValue ? (
          <span style={{ color: "green", fontSize: "21px" }}>↑</span>
        ) : (
          <span style={{ color: "red", fontSize: "21px" }}>↓</span>
        );

      const currentValueColor = currentValue > previousValue ? "green" : "red";
      const message = alert.replace(`${date} : ${fundName.trim()} `, "");

      return {
        date,
        absoluteChange, // Return absolute change
        movement: `${previousValue}% →  ${currentValue}%`,
        arrow,
        changeColor, // Return the color for styling % Change
        message: (
          <>
            {arrow}
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>{date}</span>:{" "}
            {message.split(`${currentValue}%`).map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < message.split(`${currentValue}%`).length - 1 && (
                  <span style={{ color: currentValueColor, fontWeight: "500" }}>
                    {currentValue}%
                  </span>
                )}
              </React.Fragment>
            ))}
          </>
        ),
      };
    }

    return { date: "", absoluteChange: "-", movement: "-", changeColor: "black", message: "No alerts" };
  };

  // Handle AUM sorting toggle
  const handleAumSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === "highest" ? "lowest" : "highest"));
    setSortColumn("AUM");
  };
  const handleFundSizeSortToggle = () => {
    setFundSizeSortOrder((prevOrder) =>
      prevOrder === "highest" ? "lowest" : "highest"
    );
    setSortColumn("Fund Size");
  };
  
  // Handle % Change sorting toggle
  const handlePercentageSortToggle = () => {
    setPercentageSortOrder((prevOrder) =>
      prevOrder === "highest" ? "lowest" : "highest"
    );
    setSortColumn("% Change");
  };

  // Handle Date sorting toggle
  const handleDateSortToggle = () => {
    setDateSortOrder((prevOrder) => (prevOrder === "latest" ? "oldest" : "latest"));
    setSortColumn("Date");
  };

  // Sorting logic based on the active sorting column (AUM, % Change, or Date)
  // Sorting logic based on the active sorting column (AUM, % Change, or Date)
const sortedData = useMemo(() => {
  if (latestData) {
    let sortedArray = [...latestData];

    if (sortColumn === "AUM") {
      // Sorting by AUM
      sortedArray = sortedArray.sort((a, b) => {
        const diff = parseFloat(a.percentage) - parseFloat(b.percentage);
        return sortOrder === "highest" ? -diff : diff;
      });
    } else if (sortColumn === "% Change") {
      // Sorting by % Change
      sortedArray = sortedArray.sort((a, b) => {
        const alertA = findAlertForFund(a.mfName);
        const alertB = findAlertForFund(b.mfName);

        const diff = parseFloat(alertA.absoluteChange) - parseFloat(alertB.absoluteChange);
        return percentageSortOrder === "highest" ? -diff : diff;
      });
    } else if (sortColumn === "Fund Size") {
      // Sorting by Fund Size
      sortedArray = sortedArray.sort((a, b) => {
        const sizeA = parseFloat(a.fund_size); // Ensure fund_size is parsed as a float
        const sizeB = parseFloat(b.fund_size);
        return fundSizeSortOrder === "highest" ? sizeB - sizeA : sizeA - sizeB;
      });
    }
    else if (sortColumn === "Date") {
      // Sorting by Date
      sortedArray = sortedArray.sort((a, b) => {
        const alertA = findAlertForFund(a.mfName);
        const alertB = findAlertForFund(b.mfName);
        
        const dateA = alertA.date ? new Date(alertA.date) : null;
        const dateB = alertB.date ? new Date(alertB.date) : null;

        // Sort logic for dates
        if (!dateA && !dateB) return 0; // Both are missing
        if (!dateA) return 1; // Date A is missing, put it last
        if (!dateB) return -1; // Date B is missing, put it last

        return dateSortOrder === "latest" ? dateB - dateA : dateA - dateB;
      });
    }

    // Apply search filter
    return sortedArray.filter((mf) =>
      mf.mfName && mf.mfName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  return [];
}, [latestData, sortOrder, percentageSortOrder, dateSortOrder,fundSizeSortOrder, sortColumn, searchTerm]);


  const handleCheckboxChange = (fundName) => {
    if (selectedFunds.includes(fundName)) {
      setSelectedFunds((prevSelectedFunds) =>
        prevSelectedFunds.filter((fund) => fund !== fundName)
      );
    } else {
      setSelectedFunds((prevSelectedFunds) => [...prevSelectedFunds, fundName]);

      setTimeout(() => {
        graphRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100); // Adjust the timeout value as needed
    }
  };

  const handleSelectAllChange = () => {
    const allFunds = latestData.map((mf) => mf.mfName);
    if (selectAll) {
      setSelectedFunds([]); // Deselect all funds
    } else {
      setSelectedFunds(allFunds); // Select all funds
    }
    setSelectAll(!selectAll); // Toggle the "Select All" checkbox

    setTimeout(() => {
      graphRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100); // Adjust the timeout value as needed
  };

  const filteredChartData = useMemo(() => {
    if (selectedFunds.length > 0) {
      const trimmedSelectedFunds = selectedFunds.map((fund) => fund.trim());
      const filteredData = {
        labels: chartData.labels,
        datasets: chartData.datasets.filter((dataset) =>
          trimmedSelectedFunds.includes(dataset.label.trim())
        ),
      };
      return filteredData;
    }
    return null; // If no fund is selected, return null
  }, [selectedFunds, chartData]);

  useEffect(() => {}, [filteredChartData, chartData, selectedFunds]);

  return (
    <>
      {latestData && latestData !== undefined ? (
        <>
        <input
                        className="search-mf-name-mobile"
                        type="text"
                        placeholder="Search Mutual Funds"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                      />
          <div className="mutual-funds-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ borderRadius: "10px 0 0 0 " }}>
                    <input
                      style={{ height: "18px", width: "18px" }}
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th>
                    <div className="search-mf-name-div">
                      <p>MF names</p>
                      <input
                        className="search-mf-name-desktop"
                        type="text"
                        placeholder="Search Mutual Funds"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                      />
                    </div>
                  </th>
                  {/* <th>Instrument</th> */}
                  <th  onClick={handleFundSizeSortToggle} style={{ cursor: "pointer" }}>
                    Total AUM (crs){" "}
                    {sortOrder === "highest" ? <FaSort /> : <FaSort />} {/* Icons for sorting */}
                  </th>
                  <th onClick={handleAumSortToggle} style={{ cursor: "pointer" }}>
                    AUM{" "}
                    {sortOrder === "highest" ? <FaSort /> : <FaSort />} {/* Icons for sorting */}
                  </th>
                  <th onClick={handleDateSortToggle} style={{ cursor: "pointer" }}>
                    Date{" "}
                    {dateSortOrder === "latest" ? <FaSort /> : <FaSort />} {/* Icons for sorting */}
                  </th>
                  <th onClick={handlePercentageSortToggle} style={{ cursor: "pointer" }}>
                    % Change{" "}
                    {percentageSortOrder === "highest" ? <FaSort /> : <FaSort />} {/* Icons for sorting */}
                  </th>
                  <th style={{ borderRadius: "0 10px 0 0 " }}>Movement</th> {/* Updated movement column */}
                </tr>
              </thead>
              <tbody>
                {sortedData.map((mf, index) => {
                  const alertInfo = findAlertForFund(mf.mfName);
                  return (
                    mf.instrument !== "-" && (
                      <tr key={index}>
                        <td>
                          <input
                            style={{ height: "18px", width: "18px" }}
                            type="checkbox"
                            checked={selectedFunds.includes(mf.mfName)}
                            onChange={() => handleCheckboxChange(mf.mfName)}
                          />
                        </td>
                        <td>{mf.mfName}</td>
                        {/* <td>{mf.instrument}</td> */}
                        <td>{mf.fund_size}</td>
                        <td>{mf.percentage}</td>
                        <td>{alertInfo.date}</td> {/* Display only date */}
                        <td style={{ color: alertInfo.changeColor, fontWeight: "bold" }}>
                          {alertInfo.arrow} {alertInfo.absoluteChange} {/* Arrow and bold text */}
                        </td>
                        <td>{alertInfo.movement}</td> {/* Display movement */}
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mutual-funds-graph" ref={graphRef}>
            {filteredChartData && filteredChartData.datasets.length > 0 ? (
              <Line
                data={filteredChartData}
                height={650}
                width={990}
                options={{
                  spanGaps: true,
                  plugins: {
                    legend: {
                      display: window.innerWidth <= 768 ? false : true,
                      position: "right",
                      labels: {
                        usePointStyle: true,
                        pointStyle: "line",
                        padding: 12,
                        font: {
                          size: 10,
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Date",
                      },
                      ticks: {
                        display: window.innerWidth > 768,
                        minRotation: 90,
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: "% Value",
                      },
                      ticks: {
                        callback: function (value) {
                          return value + "%";
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <div className="select-box-mutual">
                Select a mutual fund for graphical analysis.
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="no-data-alerts-graph">
          No Mutual Fund Data Available
        </div>
      )}
    </>
  );
};

export default MutualFundTable;
