// import AboutUsSection from "../Component/LandingV2/AboutUsSection";
import ConfidenceSection from "../Component/LandingV2/ConfidenceSection";
import FeatureSection from "../Component/LandingV2/FeatureSection";
import Footer from "../Component/LandingV2/Footer";
import HomeSection from "../Component/LandingV2/HomeSection";
import MoveDownFloatButtom from "../Component/LandingV2/MoveDownFloatButtom";
// import NLPSection from "../Component/LandingV2/NLPSection";
import Navbar from "../Component/LandingV2/Navbar";
// import NewsletterSection from "../Component/LandingV2/NewsletterSection";
// import SignupSection from "../Component/LandingV2/SignupSection";
import VideoSection from "../Component/LandingV2/VideoSection";
import NewsHubSection from "../Component/LandingV2/NewsHubSection";
import "./LandingV2.scss";
import MarketSubscribeSection from "../Component/LandingV2/MarketSubscribeSection";

const LandingV2 = () => {
  return (
    <div className="landing-v2">
      <Navbar />
      {/* Home Section */}
      <MoveDownFloatButtom />
      <HomeSection />
      <ConfidenceSection />
      <VideoSection />
      <FeatureSection />
      {/* <NLPSection /> */}
      {/* <NewsletterSection /> */}
      {/* <SignupSection /> */}
      {/* <AboutUsSection /> */}
      <NewsHubSection />
      <MarketSubscribeSection />
      <Footer />
    </div>
  );
};

export default LandingV2;
