import React, { useEffect, Fragment, useRef } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import Dots from '../Dots/Dots';
// import ChatBotSuggestions from '../ChatBotSuggestions/ChatBotSuggestions';

/**
 * ChatBody Component
 * 
 * This component renders the main chat body, displaying messages and user/chatbot information.
 * It handles scrolling to the bottom of the chat body when new messages are added.
 * 
 * This function is called in \src\Views\ChatBot\CustomPopover\CustomPopover.jsx
 * 
 * @component
 * @param {Object} props - The component's properties.
 * @param {Array} props.messages - An array of chat messages to display.
 * @param {string} props.scrollState - The scroll state to trigger scrolling behavior.
 * @param {Function} props.onResetMessages - A callback function to reset chat messages.
 * @returns {JSX.Element} - The rendered chat body component.
 */
const ChatBody = ({ messages, scrollState, onResetMessages }) => {

    const scrollbottom = useRef(null);

    // console.log('messages ChatBody', messages);


    //reruns on scrollState change, scroll to bottom on scrollState change
    useEffect(() => {
        if (scrollbottom.current) {
            scrollbottom.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }
        , [scrollState]);

    return (

        <div className="chat-body" >
            {messages?.length > 0 &&
                messages.map((message, index) => (

                    <Fragment key={index}>

                        {message.showUserInfo === true &&
                            message.author === 'chatbot' &&
                            <div className="chatBotInfo">
                                <div className="small-avatar"><MessageOutlined /></div>
                                <p>InfoBot</p>
                            </div>
                        }

                        {message.showUserInfo === true &&
                            message.author === 'user' &&
                            <div className="userInfo">
                                <p>You</p>
                            </div>
                        }

                        <div className={`chat-message ${message.author}`}>
                            {message?.showDots ? <Dots /> : <p>{message.text}</p>}
                        </div>

                        {/* {message.suggestions &&
                                    <ChatBotSuggestions 
                                    onResetMessages={onResetMessages}
                                    />
                        } */}
                        
                        <div ref={scrollbottom} />

                    </Fragment>
                ))
            }
        </div>

    );
}

export default React.memo(ChatBody);