import React from "react";
import { Line, Bar } from "react-chartjs-2";
import "./FinancialInsights.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BalanceSheetGraph = ({ graphData }) => {
  let balanceSheetGraphData = null;
  if (graphData) {
    const balanceSheetLineData = graphData.balance_sheet_line_coordinates;
    const balanceSheetBarData = graphData.balance_sheet_diverging_coordinates;
    // console.log(balanceSheetBarData);

    const lineLabels = Object.keys(balanceSheetLineData.TotalAssets);
    const barLabels = Object.keys(balanceSheetBarData[Object.keys(balanceSheetBarData)[0]]);
    // console.log(barLabels);

    const lineDataset = {
      label: "Total Assets",
      data: Object.values(balanceSheetLineData.TotalAssets),
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    };

    const barDataset = Object.keys(balanceSheetBarData).map((key, index) => {
        return {
           label: key, // Assuming each key is a label for the dataset
           data: Object.values(balanceSheetBarData[key]),
           backgroundColor: `rgba(${255 - index * 20}, ${index * 20}, 0, 0.2)`,
           borderColor: `rgba(${255 - index * 20}, ${index * 20}, 0, 1)`,
           borderWidth: 1,
        };
       });
    // console.log(barDataset);

    const barOptions = {
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right",
        },
        title: {
          display: true,
          text: "Balance Sheet Diverging Bar Graph",
        },
      },
    };

    const lineOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Balance Sheet Line Graph",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const BarGraphData = {
      labels: barLabels,
      datasets: barDataset,
    };
    // console.log(BarGraphData);

    const lineGraphData = {
      labels: lineLabels,
      datasets: [lineDataset],
    };

    balanceSheetGraphData = {
      lineGraphData,
      lineOptions,
      BarGraphData,
      barOptions,
    };
  }
  return balanceSheetGraphData ? (
    <>
      <div className="graph">
        <Line
          options={balanceSheetGraphData.lineOptions}
          data={balanceSheetGraphData.lineGraphData}
        />
      </div>
      <div className="graph">
        <Bar
          options={balanceSheetGraphData.barOptions}
          data={balanceSheetGraphData.BarGraphData}
        />
      </div>
    </>
  ) : null;
};

export default BalanceSheetGraph;
