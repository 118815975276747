import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';
import useWindowDimemsions from '../../../hooks/useWindowDimesnions';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);
/**
 * The `StockVolumeClose` component displays a responsive bar chart comparing stock volume 
 * with close prices over a period of time.
 *
 * @param {Object} data - The data for plotting the bar chart.
 * @returns {JSX.Element} The rendered `StockVolumeClose` component.
 */
const getMinimumElementInArr = (arr:any )=>{
 
    let  minimum:any  = 0;
    if( arr?.length > 0){
        const numberArr =  arr.map((val:any ) => Number(val.value))
       // console.log(`getMinimumElementInArr`, numberArr);
         minimum = Math.min.apply(Math, numberArr);
         minimum =  minimum && Number(minimum) > 100 ? Math.floor(minimum - 100) : minimum; //make not touch x-axis
    }
     //console.log(`getMinimumElementInArr`, minimum);
    return  minimum;
};


const getFormattedDateArr = (arr:any ) => {
    const data = arr
      .map((dated:any  )=> format(new Date(dated), 'dd LLL'))
      .map((dated:any  ) => dated ? String(dated).toUpperCase() : dated);
    return data;
  };
  

/**
 * The `StockVolumeClose` component displays a responsive bar chart comparing stock volume 
 * with close prices over a period of time.
 *
 * @param {Object} data - The data for plotting the bar chart.
 * @returns {JSX.Element} The rendered `StockVolumeClose` component.
 */
export default function StockVolumeClose({ data }:any) {


    const options:any  = {
        animation: {
          duration: 0
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: true,
                position: "bottom",
            }
    
        },
        maintainAspectRatio: false,
    
        scales: {
            x: {
                // type: 'category',
                // labels: ['January', 'February', 'March', 'April', 'May', 'June']
                ticks: {
                  //autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                },
            },
            B: {
                type: 'linear',
                display: true,
                position: 'left',
                suggestedMin: 0,
            },
            A: {
                type: 'linear',   //green, Close Price
                display: true,
                position: 'right',
                min: getMinimumElementInArr(data?.close),
                grid: {  // grid line settings
                    drawOnChartArea: false,  // only want the grid lines for one axis to show u
                },
            
            },
        },
    
    };
    


    //console.log('StockVolumeClose', data)
    const { width }:any = useWindowDimemsions();

    const dataaaa:any = {
        labels: data?.date?.length > 0 ? getFormattedDateArr(data?.date) : [],
        datasets: [
            {
                type: 'line',
                label: 'Close Price',
                borderColor: 'rgb(0, 227, 150)',  //green
                backgroundColor: 'rgb(0, 227, 150)',
                borderWidth: 3,
                //fill: false,
                data: data?.close?.length > 0 ? data.close.map((val:any) => Number(val.value)) : [],
                yAxisID: 'A',
            },
            {
                type: 'bar',
                label: 'Stock Volume',
                borderColor: 'rgb(37, 159, 251)', 
                backgroundColor: 'rgb(37, 159, 251)',  //blue
                data: data?.volume?.length > 0 ? data.volume.map((val:any) => Number(val.value)) : [],
                yAxisID: 'B',
            },

        ],
    };


    return (
        <>
            <div className={`stock-sentiment  col-lg-6   ${width && width > 768 ? "mb-3 " : "mb-4"} `}>
                <div className="p-3 bg-white pb-4" style={{ borderRadius: 6 }}>
                    <h6
                        className="text-center"
                        style={{ fontWeight: 500, fontFamily: "Open Sans", fontSize: width && width > 768 ? '14px' : '13px' }}
                    >Stock&nbsp;Volume vs Close&nbsp;Price</h6>
                    <div style={{ maxHeight: 250, minHeight: 250, width: '100%' }}
                        className='d-flex justify-content-center align-items-center'
                    >
                        <Chart type='bar' data={dataaaa} options={options} />
                    </div>
                </div>
            </div>

        </>

    )



}
