
import React from 'react';
import CustomPopover from '../../Views/ChatBot/CustomPopover/CustomPopover';
import axios from 'axios';



//not asked feature
//generate an ID and store msg in object 
//send that ID to databse
//take the ID from the response and modify object in array for that ID


//to be noted
//i was facing an issue that when chat 'start chat again" is clicked ( is clicked before server can respond) to start a new chat... the prevoius chat reply used to come in the new chat...cause server takes time  to respond
// so i resolved it buy cancelling all previous( cancelling old controller and creating new) chat replies when user clicks on 'start chat again'



const defaultState = [
    { author: 'chatbot', text: `Hi, it's great to see you!`, showUserInfo: true, showDots: false },  //showDots --- > needed in only chatbot to show dotanimation before it replies
    { author: 'chatbot', text: `What information are you looking for?`, showUserInfo: false, showDots: false, }  //suggestions--> needed in only chatbot object to show suggestions
];    //showUserInfo  ----> needed to show icon+Chatbot on top of only first message sent by chatbot


let controller = new AbortController();
/**
 * The ChatBot component that interacts with users.
 * 
 * This function is called in \src\Router\Router.tsx
 * 
 * @returns {JSX.Element} The JSX element representing the ChatBot component.
 */
const ChatBot = () => {

    const [showPopover, setShowPopover] = React.useState(false);
    const [userMessage, setUserMessage] = React.useState('');
    const [scrollState, setScrollState] = React.useState(false); //used to scroll down, used since message array length remains same on setMessages twice at a time
    const [messages, setMessages] = React.useState(defaultState);
    const [disableSubmitMsg, setDisableSubmitMsg] = React.useState(false); //disable submitted input handling till chatbot  msg reply from server


    const handlePopver = () => {
        setShowPopover(!showPopover); //hide or show popover, hides popover button
    }

    const handleMessageChange = (value) => {
        setUserMessage(value);  //set user message
    }

    const handleResetMessages = () => {
        controller.abort();  //stop reply of prev msgs
        controller = new AbortController();  //create a new controller as prev ones will always cancel request
        setUserMessage(''); //reset value
        setDisableSubmitMsg(false); //reset value
        //  setMessages((messages) =>[]); //neded for axios cancel prev request before start Chat again
        setMessages((messages) => [{ author: 'chatbot', text: '', showUserInfo: true, showDots: true }]); //clear in ui and  show dots
        setTimeout(function () {
            setMessages((messages) => defaultState); //show reset
        }, 1000);
    }

    const handleMessageSubmit = (event) => {
        event.preventDefault();

        if (disableSubmitMsg === false) { //disable submitted input handling till chatbot  msg reply from server
            const modifiedMessages = [...messages, { author: 'user', text: userMessage, showUserInfo: true, showDots: false }];
            const message = userMessage.trim(); //trim msg 
            handleChatbotResponse(message, modifiedMessages);
        } else if (disableSubmitMsg === true) {
            setUserMessage(''); //reset value
        }

    };


    const handleChatbotResponse = async (message, modifiedMessages) => {

        // let response = '';
        // if (message === 'hi') {
        //     response = 'Hola';
        // } 

        try {

            // add/show dots for chatbot
            setMessages((messages) => [...modifiedMessages,
            { author: 'chatbot', text: '', showUserInfo: true, showDots: true }]);

            setScrollState((scrollState) => !scrollState);

            setDisableSubmitMsg((disableSubmitMsg) => true);

            setUserMessage(''); //reset value

            const options = {
                signal: controller.signal,
                method: 'POST',
                //url: 'http://chatbot-alb-1754161297.ap-south-1.elb.amazonaws.com/get-result',
                url: `${process.env.REACT_APP_CHATBOT}`,
                timeout: 1000 * 50, // Wait for 3 seconds, if not added timeout on own
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "msg": message
                },
            };

           //  const { data: chatBotReply } = await axios.request(options);
           // // console.log('reply', chatBotReply);
           //  setMessages((messages) => [...modifiedMessages,
           //  { author: 'chatbot', text: chatBotReply, showUserInfo: true, showDots: false, suggestions: true }]);

            const { data: chatBotReply } = await axios.request(options);
              console.log("reply", chatBotReply);
              setMessages((messages) => [
                ...modifiedMessages,
                {
                  author: "chatbot",
                  text: JSON.parse(chatBotReply.body)?.message || "Msg",
                  showUserInfo: true,
                  showDots: false,
                  suggestions: true,
                },
              ]);

        } catch (error) {
            console.error(error); //incase of auto timeout or error
            // setMessages((messages) => [...modifiedMessages,
            // { author: 'chatbot', text: "Oops! Sorry, I didn't understand your question.", showUserInfo: true, showDots: false, suggestions: true }]);
        } finally {
            setScrollState((scrollState) => !scrollState); //move scroll  to bottom
            setDisableSubmitMsg((disableSubmitMsg) => false);
        }


    };



    return (
        <CustomPopover
            showPopover={showPopover} //hide show button
            togglePopover={handlePopver}
            textMessage={userMessage}
            onMessageChange={handleMessageChange}
            onMessageSubmit={handleMessageSubmit}
            messages={messages}
            scrollState={scrollState} //move scroll  to bottom
            onResetMessages={handleResetMessages} //start chat again
        />

    );
}

export default ChatBot;
