import "./MoveDownFloatButtom.scss";

const MoveDownFloatButtom = () => {
  return (
    <button
      className="floatdown-button"
      onClick={() => {
        // Scroll one screen height down
        window.scrollBy({
          top: window.innerHeight,
          behavior: "smooth",
        });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.21375 13.1304C5.83208 12.5121 6.83459 12.5121 7.45292 13.1304L19 24.6775L30.5471 13.1304C31.1654 12.5121 32.1679 12.5121 32.7863 13.1304C33.4046 13.7488 33.4046 14.7513 32.7863 15.3696L21.2392 26.9167C20.0025 28.1533 17.9975 28.1533 16.7608 26.9167L5.21375 15.3696C4.59542 14.7513 4.59542 13.7488 5.21375 13.1304Z"
          fill="#2D4890"
        />
      </svg>
    </button>
  );
};

export default MoveDownFloatButtom;
