import { ComparisonData } from "./AggregatorV2";
import Plot from "react-plotly.js";
import { ScatterData } from 'plotly.js';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TechnicalIndicator.scss';

interface TechnicalIndicatorsProps {
  comparisonData: ComparisonData;
}

// Helper function to filter daily data to include only weekly data
const filterWeeklyData = (dailyData: { [date: string]: number }) => {
  const filteredData: { [date: string]: number } = {};
  const dates = Object.keys(dailyData).sort();

  dates.forEach((date, index) => {
    // Include only every 7th entry
    if (index % 7 === 0) {
      filteredData[date] = dailyData[date];
    }
  });

  return filteredData;
};

// Popover content for each technical indicator
const indicatorInfo = {
  'RSI_line': 'The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and change of price movements.',
  'SMA_line': 'The Simple Moving Average (SMA) calculates the average of a selected range of prices, usually closing prices, by the number of periods in that range.',
  'Close * Volume__bar': 'Stock Volume Over Time shows the total number of shares traded over a specific time period.',
  'ADX_bar': 'The Average Directional Index (ADX) is a technical analysis indicator used to quantify trend strength.',
  'Williams %R_line': 'Williams %R is a momentum indicator that measures overbought and oversold levels.',
  'Standard Deviation_line': 'The Standard Deviation line shows the amount of variation or dispersion of a set of values.'
};

const TechnicalIndicators = ({ comparisonData }: TechnicalIndicatorsProps) => {
  const { techincal_indicators_data } = comparisonData;

  // Modify createPlotData to handle weekly filtering
  const createPlotData = (indicator: keyof typeof techincal_indicators_data, companies: string[]) => {
    return companies.map(company => {
      const dailyData = techincal_indicators_data[indicator][company];
      const weeklyData = filterWeeklyData(dailyData);

      return {
        x: Object.keys(weeklyData),
        y: Object.values(weeklyData),
        mode: 'lines',
        name: company
      } as ScatterData;
    });
  };

  const companies = Object.keys(techincal_indicators_data["RSI_line"]);

  const renderPlot = (indicator: keyof typeof techincal_indicators_data, title: string) => (
    <div className="plot-container-1">
      <div className="plot-header">
        <h4>{title}</h4>
        <OverlayTrigger
          placement="top"
          overlay={
            <Popover id={`${indicator}-popover`}>
              <Popover.Body>{indicatorInfo[indicator]}</Popover.Body>
            </Popover>
          }
        >
          <i className="info-icon">ℹ️</i>
        </OverlayTrigger>
      </div>
      <Plot
        data={createPlotData(indicator, companies)}
        layout={{
          xaxis: { title: 'Date' },
          yaxis: { title: `${title} Value` },
          legend: {
            orientation: 'h',
            y: -0.3
          }
        }}
      />
    </div>
  );

  return (
    <div className="technical-indicators-container">
      {renderPlot('RSI_line', 'Relative Strength Index (RSI)')}
      {renderPlot('SMA_line', 'SMA (Simple Moving Average)')}
      {renderPlot('Close * Volume__bar', 'Stock Volume Over Time')}
      {renderPlot('ADX_bar', 'ADX (Average Directional Index)')}
      {renderPlot('Williams %R_line', 'Williams %R Line Over Time')}
      {renderPlot('Standard Deviation_line', 'Standard Deviation Line')}
    </div>
  );
};

export default TechnicalIndicators;
