import "./Footer.scss";
import MiniFooter from "./MiniFooter";

const Footer = () => {
  return (
    <footer className="landingv2-footer">
      <div className="pane-split">
        <div className="left-pane">
          {/* Main logo */}
          <div className="main-logo">
            <img src="/assets/images/landingv2/main-logo.png" alt="logo" />
          </div>
        </div>
        {/* Links */}
        <ul className="links">
            <li>
              <a href="#">Product & Service</a>
            </li>
            <li>
              <a href="#">Contact Us</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>
          </ul>
          {/* Social media */}
          {/* <div className="social"> */}
            {/* logos */}

            {/* email */}
            {/* <div className="social-email">fabric@algoanalytics.com</div>
          </div> */}
        {/* <div className="right-pane">
          <h5 className="title">Join Our Newsletter</h5>
          <div className="subtitle">
            Be the first to know about our latest updates, exclusive offers, and
            more.
          </div>
          <a href="#newletter-section">
            <button>Newsletter</button>
          </a>
        </div> */}
      <MiniFooter />
      </div>
      <div className="bottom-footer">
        <div className="bottom-footer-content">
          <div className="left-pane">
           <li>English</li>
           <li>Privacy</li>
           <li>Legal</li>
          </div>
          <div className="right-pane">
            <span>© 2024 AlgoAnalytics. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
