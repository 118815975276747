/**
 * NewsItem reducer handles the state related to news items in the application.
 * It stores an array of news items retrieved from the server.
 *
 * @param {Array} state - The current state of news items.
 * @param {Object} action - The action object containing the type and payload.
 * @returns {Array} - The updated state of news items.
 */
export const NewsItem = (state = [], action: any) => {
  switch (action.type) {
    case "GET_ALL_NEWS_ITEM":
      return (state = action.payload);
    default:
      return state;
  }
};

export const TweetItem = (state = [], action: any) => {
  switch (action.type) {
    case "GET_ALL_TWEET_ITEM":
      return (state = action.payload);
    default:
      return state;
  }
};
