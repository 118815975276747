import React from "react";
import "./Table.scss";

const Table = ({ data = {} }) => {
  // Ensure data is not null or undefined
  if (!data || Object.keys(data).length === 0) {
    return <p>No data available</p>;
  }

  // Extract years from the keys of the first item in the data array
  const years = Object.keys(data);
  
  // Generate table headers
  const headers = ["Particulars", ...years];

  // Prepare the data for rendering
  const rowsData = years.length > 0
    ? Object.keys(data[years[0]] || {}).map((particular) => {
        return {
          particular,
          values: years.map((year) => data[year]?.[particular] ?? "-"),
        };
      })
    : [];

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row, index) => (
          <tr key={index}>
            <td className="row-title">{row.particular}</td>
            {row.values.map((value, index) => (
              <td key={index}>{value !== null && value !== undefined ? value : "-"}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
