/**
 * NewsListData reducer handles the state related to the index of a single news item.
 * It stores the index of the currently selected news item.
 *
 * @param {number} state - The current index of the selected news item.
 * @param {Object} action - The action object containing the type and payload.
 * @returns {number} - The updated index of the selected news item.
 */
const NewsListData = (state = 0, action: any) => {
  switch (action.type) {
    case "GET_SINGLE_NEWS_INDEX":
      return (state = action.payload);

    default:
      return state;
  }
};

export default NewsListData;
