import React from 'react';
// import "./styles/Marquee.scss";
import { FaVolumeUp } from 'react-icons/fa';

interface MarqueeProps {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;

}

const MenuMarquee: React.FC<MarqueeProps> = ({ text1, text2, text3, text4, text5 }) => (
  <div className="menu-marquee-container">
    <div className="menu-marquee-text">
    <FaVolumeUp style={{paddingBottom:"3px"}} /> {text1} &nbsp;<FaVolumeUp style={{paddingBottom:"3px"}} /> {text2} &nbsp;<FaVolumeUp style={{paddingBottom:"3px"}} />{text3} &nbsp; <FaVolumeUp style={{paddingBottom:"3px"}} /> {text4} &nbsp;<FaVolumeUp style={{paddingBottom:"3px"}} /> {text5}
    </div>
  </div>
);

export default MenuMarquee;