import { useState, useEffect } from "react";
import "./SearchContent.scss";
import rightArrow from "../../assets/images/icons/rightArrow.png";
import { newsCountProps } from "./newsContent.types";
import moment from "moment";
import Sort from "../Common/Sort/Sort";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
/**
 * The `SearchContent` component displays a list of news or tweets based on filter criteria.
 * It allows users to sort and filter news and tweets.
 *
 * This function is called in \src\Component\Search\Search.tsx
 *
 * @param {newsCountProps} props - The component's props, including functions to set single news, news data, and filter type.
 * @returns {JSX.Element} The rendered `SearchContent` component.
 */
const SearchContent = <T extends {}>({
  setSingleNews,
  allNews,
  filterType,
}: newsCountProps<T>) => {

  const [newsList, setNewsList] = useState<any[] | []>([]);
  const [newsList2, setNewsList2] = useState<any[] | []>([]);

  useEffect(() => {
    setNewsList2(allNews);
  }, [allNews]);

  const sortSentiment = [
    "Sort By: Date - Newest to Oldest",
    "Sort By: Date - Oldest to Newest",
    "Sort By: Sentiment - Positive to Negative",
    "Sort By: Sentiment - Negative to Positive",
    // "Filter By: News",
    // "Filter By: Tweets",
  ];

  useEffect(() => {
    setNewsList(allNews);
  }, [allNews]);

  useEffect(() => {
    if (filterType === "news") {
      let only_news = newsList2.filter((word: { key: string }) => {
        return word.key === "news";
      });
      setNewsList(() => {
        return [...only_news];
      });
    } else if (filterType === "tweets") {
      let only_tweets = newsList2.filter((word: { key: string }) => {
        return word.key === "tweets";
      });
      setNewsList(() => {
        return [...only_tweets];
      });
    } else if (filterType === "all") {
      setNewsList(() => {
        return [...newsList2];
      });
    }
  }, [filterType]);

  const sortByScore = (event: string) => {
    if (event === "Sort By: Date - Newest to Oldest") {
      let sortedList: any = newsList2.sort(function (a: any, b: any) {
        let date1: any = new Date(a.News_date || a.Tweets_date);
        let date2: any = new Date(b.News_date || b.Tweets_date);
        return date2 - date1;
      });
      setNewsList(() => {
        return [...sortedList];
      });
    } else if (event === "Sort By: Date - Oldest to Newest") {
      let sortedList: any = newsList2.sort(function (a: any, b: any) {
        let date1: any = new Date(a.News_date || a.Tweets_date);
        let date2: any = new Date(b.News_date || b.Tweets_date);
        return date1 - date2;
      });
      setNewsList(() => {
        return [...sortedList];
      });
    } else if (event === "Sort By: Sentiment - Positive to Negative") {
      let positive = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "positive" ||
          news.Tweets_Sentiment === "positive"
        );
      });
      let neutral = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "neutral" ||
          news.Tweets_Sentiment === "neutral"
        );
      });
      let negative = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "negative" ||
          news.Tweets_Sentiment === "negative"
        );
      });

      setNewsList(() => {
        return [...positive, ...neutral, ...negative];
      });
    } else if (event === "Sort By: Sentiment - Negative to Positive") {
      let positive = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "positive" ||
          news.Tweets_Sentiment === "positive"
        );
      });

      let neutral = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "neutral" ||
          news.Tweets_Sentiment === "neutral"
        );
      });

      let negative = newsList2.filter((news) => {
        return (
          news.News_Sentiment === "negative" ||
          news.Tweets_Sentiment === "negative"
        );
      });

      setNewsList(() => {
        return [...negative, ...neutral, ...positive];
      });
    }
    // else if (event === "Filter By: News") {
    //   let only_news = newsList2.filter((news) => {
    //     return news.key === "news";
    //   });
    //   only_news[0].active = true
    //   setSingleNews(only_news[0]);
    //   setNewsList(() => {return [...only_news]});

    // } else if (event === "Filter By: Tweets") {
    //   let only_tweets = newsList2.filter((news) => {
    //     return news.key === "tweets";
    //   });
    //   only_tweets[0].active = true
    //   setNewsList(() => {return [...only_tweets]});
    //   setSingleNews(only_tweets[0]);
    // }
  };

  const { width }: any = useWindowDimemsions();
  const xsWidth = 576;
  const heightStyle =
    width && width < xsWidth && newsList?.length === 0
      ? { minHeight: "100px", maxHeight: "100px" }
      : width && width < xsWidth && newsList?.length > 0
      ? { maxHeight: "75vh" }
      : { minHeight: "75vh", maxHeight: "75vh" };

  return (
    <div
      className={`search-content mb-2 col-md-3 ${
        width && width > 768 ? "mb-2" : "mb-4"
      } `}
    >
      <div className="inner-content p-4 pb-0" style={heightStyle}>
        <div className="header">
          <h6>Contents &nbsp;</h6>
          <span>
            {/* <img src={sortIcon} alt="sort by" style={{ cursor: "pointer" }} /> */}
            <Sort
              sortSentiment={sortSentiment}
              sortByScore={sortByScore}
              title="Sort By"
            />
          </span>
        </div>

        <div className="insider-child">
          {(newsList as []).map((news: any, index: any) =>
            news.key === "news" ? (
              <div
                key={index}
                className="background-white p-3 mb-3"
                style={{
                  background:
                    news.active === true ? "rgb(227, 235, 255)" : "white",
                }}
                onClick={() => {
                  setSingleNews(news);
                  newsList.forEach((element) => {
                    element.active = false;
                  });

                  newsList[index].active = true;
                  setNewsList(() => {
                    return [...newsList];
                  });
                }}
              >
                <div
                  className="d-flex 
                justify-content-between
                 align-items-center 
                 flex-row-reverse
                 "
                >
                  <span
                    className="statusDots"
                    style={{
                      background:
                        news.News_Sentiment === "negative"
                          ? "rgb(253, 24, 126)"
                          : news.News_Sentiment === "positive"
                          ? "#39E0A4"
                          : "#FEAF38",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span className="time">
                    {news.News_date &&
                      moment(news.News_date).format("DD-MMM-YYYY HH:mm ")}
                  </span>
                </div>
                <p className="mint-text mb-0">
                  <span>{news.News_Source}</span>: {news.News_Title}&nbsp;
                  <img src={rightArrow} alt="right arrow" />
                </p>
              </div>
            ) : (
              <div
                key={index}
                className="background-white p-3 mb-3"
                style={{
                  background:
                    news.active === true ? "rgb(227, 235, 255)" : "white",
                }}
                onClick={() => {
                  setSingleNews(news);
                  newsList.forEach((element) => {
                    element.active = false;
                  });

                  newsList[index].active = true;
                  setNewsList(() => {
                    return [...newsList];
                  });
                }}
              >
                <div
                  className="d-flex 
                justify-content-between
                 align-items-center 
                 flex-row-reverse
                 "
                >
                  <span
                    className="statusDots"
                    style={{
                      background:
                        news.Tweets_Sentiment === "negative"
                          ? "rgb(253, 24, 126)"
                          : news.Tweets_Sentiment === "positive"
                          ? "#39E0A4"
                          : "#FEAF38",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span className="time">
                    {news.Tweets_date &&
                      moment(news.Tweets_date).format("DD-MMM-YYYY HH:mm ")}
                  </span>
                </div>
                <p className="mint-text mb-0">
                  {/* <span>TWEET: </span> {news.Actual_Tweet}&nbsp; */}
                  <span>TWEET: </span>
                  <p
                    dangerouslySetInnerHTML={{ __html: news.Actual_Tweets }}
                  ></p>
                  <img src={rightArrow} alt="right arrow" />
                </p>
              </div>
            )
          )}

          {/* <div className="background-white p-3 mb-3">
            <div
              className="d-flex justify-content-between
    align-items-center "
            >
              <span
                className="statusDots"
                style={{
                  background: "#39E0A4",
                }}
              >
                &nbsp;
              </span>
              <span className="time">11 Dec 11:22AM</span>
            </div>
            <p className="mint-text mb-0">
              <span>Live Mint</span>: ICICI Bank focus on increased digital push
              gets investors excited.&nbsp;
              <img src={rightArrow} alt="right arrow" />
            </p>
          </div>

          <div className="background-white p-3 mb-3">
            <div
              className="d-flex justify-content-between
    align-items-center "
            >
              <span
                className="statusDots"
                style={{
                  background: "#FEAF38",
                }}
              >
                &nbsp;
              </span>
              <span className="time">11 Dec 11:22AM</span>
            </div>
            <p className="mint-text mb-0">
              <span>Live Mint</span>: ICICI Bank focus on increased digital push
              gets investors excited.&nbsp;
              <img src={rightArrow} alt="right arrow" />
            </p>
          </div>

          <div className="background-white p-3 mb-3">
            <div
              className="d-flex justify-content-between
    align-items-center "
            >
              <span
                className="statusDots"
                style={{
                  background: "#fd187e",
                }}
              >
                &nbsp;
              </span>
              <span className="time">11 Dec 11:22AM</span>
            </div>
            <p className="mint-text mb-0">
              <span>Live Mint</span>: ICICI Bank focus on increased digital push
              gets investors excited.&nbsp;
              <img src={rightArrow} alt="right arrow" />
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SearchContent;
