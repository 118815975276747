import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import Router from "./Router/Router";
import "nouislider/distribute/nouislider.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga";
import MaintenancePage from "./Component/Maintenance/maintenance";

import "./App.scss";
import PersistAuth from "./Component/Auth/PersistAuth";

const TRACKING_ID = "249691188"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
        }
      `,
    },
  },
});

// Set Maintenance as true when undergoing maintenance
const maintenance = false;
/**
 * Main Application Component.
 *
 * This component serves as the entry point for the application and controls the rendering of the Router component.
 * It also initializes Google Analytics for tracking and handles maintenance mode.
 *
 * This function is called in \src\index.tsx
 *
 * @returns {JSX.Element} - A React JSX element representing the main application component.
 */
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <PersistAuth>
          {!maintenance ? <Router /> : <MaintenancePage />}
        </PersistAuth>
      </ThemeProvider>
    </>
  );
}

export default App;
