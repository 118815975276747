import { Modal } from 'antd';
import './SubscribeListingModal.scss';
import useWindowDimemsions from '../../../hooks/useWindowDimesnions';

const SubscribeListingModal = ({ value, onHideModal, closeIcon, zIndex, centered }) => {


    const { width } = useWindowDimemsions();


    const modalWidth = width && width > 1200 ? '1000px' : '90vw';


    return (
        <>
            <Modal
                zIndex={zIndex} //increase z-index so that if tooltip and modal shown //modal always takes higher value
                closeIcon={closeIcon}
                open={value}
                onOk={onHideModal}
                onCancel={onHideModal}
                footer={null}
                width={modalWidth}
                centered={centered} //display modal on center always , also for mobile
            //bodyStyle={{margin:0}}
            >


                <div className='subscribe-price-listing' >


                    {[
                        {
                            color: '#FF7F50',
                            features: ['Overview', 'Alert Updates'],
                            priced: 0,
                            subType: 'Basic',
                            subUse: 'Daily free Stock Updates',
                        },

                        {
                            color: '#1E90FF',
                            features: ['Overview', 'Alert Updates', 'Overview', 'Overview', 'Alert Updates', 'Overview',],
                            priced: 2000,
                            subType: 'Standard',
                            subUse: 'For Stock updates on the go',
                        },

                        {
                            color: '#F4BB44',
                            features: ['Overview', 'Alert Updates', 'Overview', 'Overview', 'Alert Updates', 'Overview', 'Overview', 'Alert Updates Alert Updates Alert Updates'],
                            priced: 3000,
                            subType: 'Premium',
                            subUse: 'Perfect for predicting stocks',
                            
                        },

                    ].map((item, index) =>

                        <div className='pricing-card'
                        key={item.color} 
                        data-ribbon="Most Popular"
                         >

                            <div className='subscribe-type'><h3>{item.subType}</h3></div>

                            <div className='useful-for'>{item.subUse}.</div>

                            <div className='pricing-box' style={{ background: item.color }}>
                                <span className='price'>₹{item.priced}</span><span className="month">/mo</span>
                            </div>
                            <div className='available-features'>

                                <ul style={{listStyleType:'none'}}>
                                    {item.features.map((ele, ind) =>
                                        <li key={ind}>
                                            <div className='feauture' >
                                                <i className="fa fa-check" aria-hidden="true" style={{ color: item.color, marginTop: 4, marginRight:3 }}></i>
                                                <p>{ele}</p>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className='buy-button'>
                                <button onClick={() => onHideModal()} style={{ background: item.color }}> BUY NOW</button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal >
        </>
    );
}

export default SubscribeListingModal;

SubscribeListingModal.defaultProps = {
    closeIcon: null,
    // modalWidth: '90vw',   //default on antd docs
    zIndex: 1100,
    centered: true
};