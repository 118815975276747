import NoResultsBox from '../Common/NoResultsBox/NoResultsBox';
import './CandleStickPattern.scss';

const CandleStickPattern = ({ data }) => {

    // console.log('CandleStickPattern', data)

    return (
        <>
            <div className="Candle-Stick-Pattern col-xl-4">
                <div className="white-box col-xl-4">

                    {Object.keys(data || {})?.length > 0 ?

                        <>

                            <div className="candle-formed-on">
                                {/* <p>Candle formed on 10-8-2023</p> */}
                                <p>{`Candle formed on ${data?.lastDated}`}</p>
                            </div>

                            <div className="open-high-low-close">
                                <p>{`Open\u00A0:\u00A0${data?.Open} | High\u00A0:\u00A0${data?.High} | Low\u00A0:\u00A0${data?.Low} | Close\u00A0:\u00A0${data?.Close}`}</p>
                            </div>

                            <div className="pattern-formed">
                                <p><span>Pattern Formed: </span>{data?.['Detected Patterns']}</p>
                            </div>

                            <div className="pattern-info-bullet-points">
                                <ul>
                                    <li>{data?.['Pattern Descriptions']}</li>
                                    {/* <li>{data?.['Pattern Descriptions']}</li>
                                    <li>{data?.['Pattern Descriptions']}</li>
                                    <li>{data?.['Pattern Descriptions']}</li>
                                    <li>{data?.['Pattern Descriptions']}</li> */}

                                </ul>
                            </div>
                        </>

                        :
                        <>
                            <div className="heading-text">
                                <h6>CandleStick&nbsp;Pattern</h6>
                            </div>   
                                      
                               <NoResultsBox displayText={'NO DATA'} showEmptyImage={true} />                
                          
                        </>
                    }
                </div>
            </div>

        </>
    );
}

export default CandleStickPattern;